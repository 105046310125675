/* eslint-disable react/jsx-props-no-spreading */
import {
  FC, ReactElement, useMemo
} from 'react';
import {
  useForm
} from 'react-hook-form';

import {
  Box, Typography, Button, Grid, Paper
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';

import { useAppStore, useUserStore } from '../../state';

import ContactPersonForm from './ContactPersonForm';
import CompanyProfileForm from './CompanyProfileForm';

import { goToCompanyProfile } from '../../utils';

const CompanyProfileMain: FC = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const user = useUserStore((state:GenericObject) => state);

  const { company } : {company : Company} = user;

  const {
    register, handleSubmit, control,
  } = useForm<Company>({
    defaultValues: useMemo(() => company, [company])
  });

  if (!company) return <></>;

  // // Processing of data and images
  // const onSaveCompanyLogo = (imageData:string) => user.userEditCompanyLogo(imageData);
  // // we dont delete the image. instead of, we send the placeholder image as string
  // const onDeleteCompanyLogo = () => user.userEditCompanyLogo('');

  const onSubmitData = (data: GenericObject) => (user.userEditCompanyProfile({ ...data }));

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('company.info.header') },
      contentItems: [
        {
          text: t('company.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  return (
    <>
      <Grid>
        <Grid item xs={12}>
          <Paper sx={{
            display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'start', p: 2, alignContent: 'center', flexDirection: 'row'
          }}
          >
            <Box>
              <Button
                startIcon={<SaveIcon />}
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  m: 1, px: 1.5, py: 0.7
                }}
                onClick={handleSubmit(onSubmitData)}
              >
                {t('common.button.saveCompanyData')}
              </Button>
              <Button
                startIcon={<PreviewIcon />}
                sx={{
                  m: 1, px: 1.5, py: 0.7
                }}
                variant="contained"
                color="secondary"
                onClick={() => goToCompanyProfile(company.id, navigate)}
              >
                So sieht Ihr Profil für andere aus
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {/* // new Form */}
      <Paper sx={{ p: 2, my: 2 }}>
        <Typography variant="h4" fontWeight="bold" marginBottom={3} gutterBottom>
          { t('company.subheader1') }
        </Typography>
        <CompanyProfileForm
          id="contactForm"
          data={company}
          control={control}
          register={register}
        />
        <ContactPersonForm
          data={company?.contactPersons}
          control={control}
          register={register}
        />
      </Paper>
    </>
  );
};

export default CompanyProfileMain;
