import { ReactElement } from 'react';

import { Chip } from '@mui/material';

const Chips = (props:GenericObject):ReactElement => {
  const {
    item, type, count, handleToggleElement
  } = props;
  const { id, checked } = item;

  const onClick = () => {
    handleToggleElement(type, id);
  };

  return (
    <Chip
      sx={{
        px: 1,
        py: 1,
        mr: 2,
        mb: 1,
        height: 'auto',
        borderRadius: '8px',
      }}
      label={`${item.name} (${count || 0})`}
      variant={checked ? 'filled' : 'outlined'}
      onClick={onClick}
    />
  );
};

export default Chips;
