import {
  Box, Card, CardContent, Skeleton, Typography, Grid, Button, Paper
} from '@mui/material';
import React from 'react';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MapIcon from '@mui/icons-material/Map';
import SkeletonCard from '../bookmarks/SkeletonCard';

const CompanyDetailsSkeleton: React.FC = () => (
  <>
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} pb={3}>
              <Skeleton variant="circular" width={70} height={70} />
              <Box sx={{ ml: 2 }}>
                <Skeleton variant="text" sx={{ ml: 2, fontSize: '2rem' }} width={200} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton sx={{ fontSize: '1rem', ml: 1 }} width={100} />
                </Box>
                <Skeleton sx={{ fontSize: '1rem', ml: 1 }} width={100} />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pb: 3 }}>
              <Skeleton sx={{ fontSize: '1rem', ml: 1 }} width={100} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* Anschrift */}
              <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'start', pb: 1, pr: 5
              }}
              >
                <Typography variant="h6" fontWeight="bold">
                  Adresse
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Skeleton sx={{ fontSize: '1rem', ml: 1 }} width={100} />
                </Box>
              </Box>
              <Box sx={{ pb: 1, pr: 5 }}>
                <Typography variant="h6" fontWeight="bold">
                  weitere Informationen
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <Skeleton sx={{ fontSize: '1rem', ml: 1 }} width={100} />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} pb={5}>
              <Skeleton variant="circular" width={70} height={70} />
              <Box>
                <Skeleton variant="text" sx={{ ml: 2, fontSize: '2rem' }} width={200} />
                <Box sx={{ display: 'flex', ml: 2, alignItems: 'center' }}>
                  <Skeleton sx={{ fontSize: '1rem', ml: 1 }} width={100} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2 }}>
                  <Skeleton sx={{ fontSize: '1rem', ml: 1 }} width={100} />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box>
        <Skeleton sx={{ fontSize: '3rem' }} width="50%" />
        <Grid container spacing={{ xs: 3, sm: 4, md: 5 }}>
          { [...Array(6)].map((e, i) => <SkeletonCard key={`skel_${i * 2}`} />)}
        </Grid>
      </Box>
    </>
  </>
);

export default CompanyDetailsSkeleton;
