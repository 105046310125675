import React, {
  ReactElement, useState
} from 'react';
import {
  Button, MobileStepper, Popover, PopoverOrigin, Stack, SxProps, Typography
} from '@mui/material';
import { useAppStore } from '../../state';
import CloseX from '../ui/CloseX';

const Tour: React.FC = (): ReactElement => {
  const { tourData } = useAppStore((state: GenericObject) => state);
  const [currentStep, setCurrentStep] = useState(0);

  if (!tourData) return <></>;

  const currentStepInfo = tourData[currentStep];

  const targetElement = document.getElementById(currentStepInfo.targetElementId || '');
  const handleNext = () => {
    if (currentStep < tourData.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClose = () => {
    useAppStore.setState({ tourData: null });
    setCurrentStep(0);
  };

  const handleSkip = () => {
    const nextStep = tourData.slice(currentStep + 1).findIndex((step: TourStep) => !step.skip);

    if (nextStep !== -1) {
      setCurrentStep(currentStep + nextStep + 1);
    } else {
      handleClose();
    }
  };

  const getMarginforPosition = (position: 'top' | 'left' | 'bottom' | 'right') => {
    switch (position) {
      case 'top':
        return '10px 0 0 0';
      case 'left':
        return '0 0 0 10px';
      case 'bottom':
        return '0 0 10px 0';
      case 'right':
        return '0 10px 0 0';
      default:
        return {};
    }
  };

  const getAnchorOrigin = (position: 'top' | 'left' | 'bottom' | 'right'): PopoverOrigin => {
    switch (position) {
      case 'top':
        return { vertical: 'bottom', horizontal: 'center' };
      case 'left':
        return { vertical: 'center', horizontal: 'right' };
      case 'bottom':
        return { vertical: 'top', horizontal: 'center' };
      case 'right':
        return { vertical: 'center', horizontal: 'left' };
      default:
        return { vertical: 'bottom', horizontal: 'center' };
    }
  };

  const getTransformOrigin = (position: 'top' | 'left' | 'bottom' | 'right'): PopoverOrigin => {
    switch (position) {
      case 'top':
        return { vertical: 'top', horizontal: 'center' };
      case 'left':
        return { vertical: 'center', horizontal: 'left' };
      case 'bottom':
        return { vertical: 'bottom', horizontal: 'center' };
      case 'right':
        return { vertical: 'center', horizontal: 'right' };
      default:
        return { vertical: 'top', horizontal: 'center' };
    }
  };

  const getArrowStylesBefore = (position: 'top' | 'left' | 'bottom' | 'right') => {
    switch (position) {
      case 'top':
        return {
          content: '""',
          position: 'absolute',
          top: '-20px',
          left: '50%',
          marginLeft: '-10px',
          width: 0,
          height: 0,
          borderTop: '10px solid transparent',
          borderBottom: '10px solid',
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          zIndex: 1,
        } as SxProps;
      case 'right':
        return {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '100%',
          marginTop: '-10px',
          width: 0,
          height: 0,
          borderTop: '10px solid transparent',
          borderBottom: '10px solid transparent',
          borderLeft: '10px solid',
          borderRight: '10px solid transparent',
          zIndex: 1,
        } as SxProps;
      case 'bottom':
        return {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: '50%',
          marginLeft: '-10px',
          width: 0,
          height: 0,
          borderTop: '10px solid',
          borderBottom: '10px solid transparent',
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          zIndex: 1,
        } as SxProps;
      case 'left':
        return {
          content: '""',
          position: 'absolute',
          top: '50%',
          marginTop: '-10px',
          marginLeft: '-20px',
          width: 0,
          height: 0,
          borderTop: '10px solid transparent',
          borderBottom: '10px solid transparent',
          borderLeft: '10px solid transparent',
          borderRight: '10px solid',
          zIndex: 1,
        } as SxProps;
      default:
        return {
          content: '""',
          position: 'absolute',
          top: '-20px',
          left: '50%',
          marginLeft: '-10px',
          marginTop: '0px',
          width: 0,
          height: 0,
          borderTop: '10px solid transparent',
          borderBottom: '10px solid',
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          zIndex: 1,
        } as SxProps;
    }
  };

  const arrowStylesBefore = getArrowStylesBefore(currentStepInfo.arrowPosition);

  const marginStyle = getMarginforPosition(currentStepInfo.arrowPosition);

  return (
    <Popover
      open={Boolean(targetElement)}
      anchorEl={targetElement}
      anchorOrigin={getAnchorOrigin(currentStepInfo.arrowPosition)}
      transformOrigin={getTransformOrigin(currentStepInfo.arrowPosition)}
      PaperProps={{
        sx: {
          margin: marginStyle,
          maxWidth: '40%',
          overflow: 'visible',
          border: '1px solid',
          backgroundColor: 'background.default',
          '&::before': arrowStylesBefore,
        } as SxProps,
      }}
    >
      <CloseX onClose={handleClose} />
      <MobileStepper
        sx={{ ml: '16px', backgroundColor: 'transparent' }}
        variant="text"
        position="static"
        backButton={handlePrev}
        nextButton={handleNext}
        activeStep={currentStep}
        steps={tourData.length}
      />
      <Typography sx={{ ml: '16px', mb: '16px', mr: '16px' }}>
        {currentStepInfo.content}
      </Typography>
      <Stack direction="row" marginBottom={1}>
        <Button
          onClick={handlePrev}
          disabled={currentStep === 0}
          variant="contained"
          sx={{
            ml: 2, mr: 2, px: 2.5, py: 0.5, flexWrap: 'wrap'
          }}
        >
          Zurück
        </Button>
        <Button
          onClick={currentStep === tourData.length - 1 ? handleClose : handleNext}
          variant="contained"
          sx={{
            mr: 2, px: 2.5, py: 0.5, flexWrap: 'wrap'
          }}
        >
          {currentStep === tourData.length - 1 ? 'Beenden' : 'Weiter'}
        </Button>
        {currentStepInfo.skip && (
        <Button
          onClick={handleSkip}
          disabled={!currentStepInfo.skip}
          color="secondary"
          variant="contained"
          sx={{
            mr: 2, px: 2.5, py: 0.5, flexWrap: 'wrap'
          }}
        >
          Schritt überspringen
        </Button>
        )}

      </Stack>

    </Popover>
  );
};

export default Tour;
