import {
  Box, Button, Grid, Link, Typography
} from '@mui/material';
import {
  FC, ReactElement
} from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';

const ImprintMain: FC = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToPreviousPath = () => {
    navigate(-1);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <Button sx={{ ml: '-15px', mb: 2 }} onClick={goToPreviousPath} startIcon={<ChevronLeftIcon color="primary" />}>
          <Typography color="primary">{t('common.button.back')}</Typography>
        </Button>
        <Box>
          <Typography variant="h2" color="primary.main" gutterBottom>
            Impressum
          </Typography>
          <Typography variant="h4" color="primary.main" gutterBottom marginTop={5}>
            Herausgeber
          </Typography>
          <Typography variant="body1" paragraph>
            Angaben gemäß §5 TMG
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Bundesverband IT-Mittelstand e.V. (BITMi)</b>
            <br />
            Pascalstraße 6, 52076 Aachen
            <br />
            Telefon: +49 241 1890 558
            <br />
            eMail:
            {' '}
            <Link href="mailto:kontakt@bitmi.de">kontakt@bitmi.de</Link>
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Hauptstadtbüro Berlin:</b>
            <br />
            Haus der Bundespressekonferenz
            <br />
            Schiffbauerdamm 40, 10117 Berlin
            <br />
            Telefon: +49 30 22605 005
          </Typography>
          <Typography>
            Vereinsregister Köln
            <br />
            Vereinsregisternummer: 43 VR 10055
            <br />
            Umsatzsteuer-Identifikationsnummer:
            <br />
            VAT-ID: DE 122662582
          </Typography>
          <Typography variant="h4" color="primary.main" gutterBottom marginTop={5}>
            Vorstand
          </Typography>
          <Typography variant="body1" paragraph>
            Dr. Dipl.-Ing. Oliver Grün, (Präsident und Vorstandsvorsitzender)
            <br />
            Martin Hubschneider, (Vizepräsident, 2. Vorstandsvorsitzender)
            <br />
            Frank Karlitschek (Vizepräsident und Vorstandsmitglied)
            <br />
            Nele Kammlott (Vizepräsidentin und Vorstandsmitglied)
          </Typography>
          <Typography variant="h4" color="primary.main" gutterBottom marginTop={5}>
            Vertretungsberechtigte
          </Typography>
          <Typography variant="body1" paragraph>
            Gemäß § 7 Abs. 2 der Satzung des Bundesverband IT-Mittelstand (Stand 31.12.1996) wird der Verband
            <br />
            gerichtlich und außergerichtlich durch zwei Mitglieder des Vorstands vertreten.
          </Typography>
          <Typography variant="body1" paragraph>
            Redaktionelle Gesamtverantwortlichkeit im Sinne des § 55 des Rundfunkstaatsvertrages (RStV):
            <br />
            Dr. Oliver Grün, Vorstandsvorsitzender des Bundesverband IT-Mittelstand e.V.
            <br />
            Pascalstraße 6, 52076 Aachen
          </Typography>
          <Typography variant="h4" color="primary.main" gutterBottom marginTop={5}>
            Streitschlichtung
          </Typography>
          <Typography variant="body1" paragraph>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
          </Typography>
          <Typography variant="h4" color="primary.main" gutterBottom marginTop={5}>
            1. Haftung für Inhalte
          </Typography>
          <Typography variant="body1" paragraph>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
            <br />
            Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            <br />
            <br />
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
            Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
            Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </Typography>
          <Typography variant="h4" color="primary.main" gutterBottom marginTop={5}>
            2. Haftung für Links
          </Typography>
          <Typography variant="body1" paragraph>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
            Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
            <br />
            <br />
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
          </Typography>
          <Typography variant="h4" color="primary.main" gutterBottom marginTop={5}>
            3. Urheberrecht
          </Typography>
          <Typography variant="body1" paragraph>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
            Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            <br />
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
            Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis.
            Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </Typography>
          {/* Hier fortfahren mit den restlichen Informationen */}
        </Box>
      </Grid>

    </Grid>

  );
};
export default ImprintMain;
