import {
  FC, ReactElement
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Button, Grid, Skeleton, Card, CardContent, CardActions, CardHeader, Divider,
} from '@mui/material';

import {
  Check as CheckIcon,
  Bookmark as BookmarkIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

const SkeletonCard: FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <Grid item xs={18} lg={6} xl={6}>
      <Card>
        <CardHeader
          titleTypographyProps={{ variant: 'body1', color: 'black' }}
          title={<Skeleton variant="text" width={150} />}
        />
        <CardContent>
          <Box
            paddingY={{ xs: 2, lg: 2.5 }}
            marginY={{ md: 1 }}
            sx={{
              display: 'flex', alignItems: 'center', flexGrow: '1', cursor: 'pointer'
            }}
          >
            <Skeleton variant="circular" width="70px" height="70px" style={{ border: '1px solid #000' }} />
            <div style={{ flexGrow: 1, marginLeft: 20 }}>
              <Typography fontSize={8} color="black" fontWeight="600" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}><Skeleton variant="text" width={80} /></Typography>
              <Typography variant="h5" color="black" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}><Skeleton variant="text" width={200} /></Typography>
              <Typography fontSize={10} color="black" fontWeight="600" letterSpacing="0.5px"><Skeleton variant="text" width={80} /></Typography>
            </div>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ py: { xs: 1.2, sm: 2 }, px: { xs: 2, sm: 3 } }}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{
              mr: 2, px: 2.5, py: 0.5
            }}
            startIcon={<CheckIcon />}
            disabled
          >
            {t('common.button.linkup')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{
              mr: 2, px: 2.5, py: 0.5
            }}
            disabled
            startIcon={<CloseIcon />}
          >
            {t('common.button.blacklist')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{
              mr: 2, px: 2.5, py: 0.5
            }}
            disabled
            startIcon={<BookmarkIcon />}
          >
            {t('common.button.remove')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
export default SkeletonCard;
