/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, {
  ReactElement, forwardRef, useEffect, useState
} from 'react';
import {
  Avatar,
  Box,
  IconButton,
  Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';

import {
  Check as CheckIcon,
  Close as CloseIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';

import PlaceHolderImage from '../../assets/images/placeholder_company.png';
import { useCategories } from '../../hooks';

// const CompareTable = forwardRef((props:GenericObject, ref) => {
const CompareTable = (props:GenericObject):ReactElement => {
  const {
    productDataList
  } = props;

  const {
    categories,
    getCategoryChecked
  } = useCategories();

  const initalSettings = () => {
    const openStates: {id: number, open: boolean}[] = [];

    // push openState for Descripton
    openStates.push({ id: 99999, open: false });

    // push openState for Categories
    categories.map((category: GenericObject) => {
      openStates.push({ id: category.id, open: true });
      category.children.map((cat: GenericObject) => {
        openStates.push({ id: cat.id, open: true });
      });
    });
    return { openStates };
  };

  const [settings, setSettings] = useState(initalSettings);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderBottom: '2px solid',
      borderColor: theme.palette.secondary.main,
      fontSize: '1.1rem'
    },
    [`&.${tableCellClasses.body}`]: {
      borderRight: '2px solid',
      borderColor: theme.palette.secondary.main,
      fontSize: '1.1rem'
    },
    '&.last-td': {
      border: 0
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.dark,
    }
  }));

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const isOneChildChecked = (comparisonTree: GenericObject, childrenIndex: number, childIndex: number, parentId: number) => {
    let isChecked = false;
    for (let i = 0; i < comparisonTree.length; i += 1) {
      if (parentId === 1) {
        isChecked = comparisonTree[i].industries[childrenIndex].children[childIndex].checked;
        if (isChecked) {
          break;
        }
      }
      if (parentId === 120) {
        isChecked = comparisonTree[i].industries[childrenIndex].checked;
        if (isChecked) {
          break;
        }
      }
      if (parentId === 121) {
        isChecked = comparisonTree[i].functionalities[childrenIndex].children[childIndex].checked;
        if (isChecked) {
          break;
        }
      }
      if (parentId === 183 || parentId === 210) {
        isChecked = comparisonTree[i].moreFeatures[childrenIndex].children[childIndex].checked;
        if (isChecked) {
          break;
        }
      }
      if (parentId === 275) {
        isChecked = comparisonTree[i].characteristics[childrenIndex].children[childIndex].checked;
        if (isChecked) {
          break;
        }
      }
    }
    return isChecked;
  };

  const isOneChildrenChecked = (comparisonTree: GenericObject, childrenIndex: number, parentId: number) => {
    let isChecked = true;
    for (let i = 0; i < comparisonTree.length; i += 1) {
      if (parentId === 1) {
        isChecked = comparisonTree[i].industries[childrenIndex].checked;
        if (isChecked) {
          break;
        }
      }
      if (parentId === 120) {
        isChecked = comparisonTree[i].industries[childrenIndex].checked;
        if (isChecked) {
          break;
        }
      }
      if (parentId === 121) {
        isChecked = comparisonTree[i].functionalities[childrenIndex].checked;
        if (isChecked) {
          break;
        }
      }
      if (parentId === 183 || parentId === 210) {
        isChecked = comparisonTree[i].moreFeatures[childrenIndex].checked;
        if (isChecked) {
          break;
        }
      }
      if (parentId === 275) {
        isChecked = comparisonTree[i].characteristics[childrenIndex].checked;
        if (isChecked) {
          break;
        }
      }
    }
    return isChecked;
  };

  const handleOpen = (id: number) => {
    setSettings((state:any) => ({
      ...settings,
      openStates: state.openStates.map((item:any) => (item.id === id ? { ...item, open: !item.open } : item))
    }));
  };

  const generateHeader = (product:any) => (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell>Produkt</StyledTableCell>
        {product.map((item:any) => (
          <StyledTableCell align="left" key={`styledtablecell_${item.id}`}>
            <Box
              paddingY={{ xs: 2, lg: 2.5 }}
              marginY={{ md: 1 }}
              sx={{
                display: 'flex', alignItems: 'center', flexGrow: '1'
              }}
            >
              <Avatar
                src={item.company.logo ?? PlaceHolderImage}
                sx={{ width: 70, height: 70, border: '1px solid' }}
              />
              <div style={{ flexGrow: 1, marginLeft: 20 }}>
                <Typography variant="h5" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}>{item.name}</Typography>
              </div>
            </Box>
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );

  const generateSummaryRow = (product:any) => (
    // CompanyName
    <>
      <StyledTableRow key="companyname">
        <StyledTableCell>
          Unternehmen
        </StyledTableCell>
        {product.map((item: any) => (
          <StyledTableCell align="center">{item.company.name}</StyledTableCell>
        ))}
      </StyledTableRow>
      {/* Summary */}
      <StyledTableRow key="summary">
        <StyledTableCell colSpan={settings.openStates.find((item:any) => item.id === 99999)?.open ? 0 : product.length + 1}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleOpen(99999)}
          >
            {settings.openStates.find((item:any) => item.id === 99999)?.open ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" />}
          </IconButton>
          Beschreibung
        </StyledTableCell>
        {product.map((item: any) => (
          settings.openStates.find((el:any) => el.id === 99999)?.open
          && <StyledTableCell align="left">{item.summary !== '' ? item.summary : (<CloseIcon color="primary" />)}</StyledTableCell>
        ))}
      </StyledTableRow>
    </>
  );

  const getcategoryIds = (product: any) => {
    const currentCategories:Array<any> = [];
    product.categories.forEach((element:GenericObject) => {
      if (element.children) {
        element.children.forEach((subelement:GenericObject) => {
          if (element.children) {
            currentCategories.push(subelement.id);
            subelement.children.forEach((subsubelement:GenericObject) => {
              if (subsubelement.children) {
                currentCategories.push(subsubelement.id);
                subsubelement.children.forEach((subsubsubelement:GenericObject) => {
                  currentCategories.push(subsubsubelement.id);
                });
              }
            });
          }
        });
      }
    });
    return currentCategories;
  };

  const getProductCategoriesTree = (product:any) => {
    const currentCategories:Array<any> = getcategoryIds(product);
    let industries:any = [];
    let functionalities:any = [];
    let characteristics:any = [];
    let moreFeatures:any = [];
    categories.forEach((category: GenericObject) => {
      const parentId = category.id;
      let checkedParent: any;
      const filteredCategories = categories
        .find((item:GenericObject) => item.id === category.id).children
        .map((cat: GenericObject) => {
          checkedParent = getCategoryChecked(cat.id, currentCategories);

          const children = cat.children.map((child: GenericObject) => {
            const checked = getCategoryChecked(child.id, currentCategories);
            return ({
              id: child.id,
              parentId: cat.id,
              name: child.name,
              checked
            });
          });
          const obj = {
            id: cat.id,
            parentId,
            name: cat.name,
            children,
            checked: checkedParent
          };
          return obj;
        });

      if (category.id === 1) industries = filteredCategories;
      if (category.id === 121) functionalities = filteredCategories;
      if (category.id === 183 || category.id === 210) moreFeatures = filteredCategories;
      if (category.id === 275) {
        characteristics = filteredCategories;
      }
    });
    const productCatTree = {
      industries, functionalities, characteristics, moreFeatures
    };
    return productCatTree;
  };

  const generateCategories = (productList:any) => {
    const comparisonTree:Array<any> = [];
    productList.forEach((product: any) => comparisonTree.push(getProductCategoriesTree(product)));
    // comparisonTree = cleanComparisonTree(comparisonTree);
    return categories.map((category: GenericObject) => (
      // Hauptkategorien
      <>
        <StyledTableRow>
          <StyledTableCell colSpan={comparisonTree.length + 1}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleOpen(category.id)}
            >
              {settings.openStates.find((item:any) => item.id === category.id)?.open ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" />}
            </IconButton>
            {category.name}
          </StyledTableCell>
        </StyledTableRow>
        {/* // Unterkategorien */}
        <>
          {category.children.map((cat: GenericObject, childrenIndex: number) => (
            settings.openStates.find((item:any) => item.id === category.id)?.open
            && isOneChildrenChecked(comparisonTree, childrenIndex, category.id)
            && (
            <>
              <StyledTableRow>
                {cat.id !== 120 ? (
                  <StyledTableCell sx={{ paddingLeft: 5 }} colSpan={comparisonTree.length + 1}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleOpen(cat.id)}
                    >
                      {settings.openStates.find((item:any) => item.id === cat.id)?.open ? <KeyboardArrowUpIcon color="primary" /> : <KeyboardArrowDownIcon color="primary" />}
                    </IconButton>
                    {cat.name}
                  </StyledTableCell>
                ) : (
                  <>
                    <StyledTableCell sx={{ paddingLeft: 5 }}>
                      {cat.name}
                    </StyledTableCell>
                    {comparisonTree.map((product) => (
                      <StyledTableCell align="center">
                        {product.industries[childrenIndex].checked ? (<CheckIcon color="success" />) : (<CloseIcon color="primary" />)}
                      </StyledTableCell>
                    ))}
                  </>
                )}
              </StyledTableRow>
              <>
                {cat.children.map((child: GenericObject, childIndex: number) => (
                  settings.openStates.find((item:any) => item.id === cat.id)?.open
                  && isOneChildChecked(comparisonTree, childrenIndex, childIndex, category.id)
                  && (
                    <StyledTableRow>
                      <StyledTableCell sx={{ paddingLeft: 10 }}>{child.name}</StyledTableCell>
                      {comparisonTree.map((product) => {
                        if (category.id === 1) {
                          return (
                            <StyledTableCell align="center">
                              {product.industries[childrenIndex].children[childIndex].checked ? (<CheckIcon color="success" />) : (<CloseIcon color="primary" />)}
                            </StyledTableCell>
                          );
                        }
                        if (category.id === 121) {
                          return (
                            <StyledTableCell align="center">
                              {product.functionalities[childrenIndex].children[childIndex].checked ? (<CheckIcon color="success" />) : (<CloseIcon color="primary" />)}
                            </StyledTableCell>
                          );
                        }
                        if (category.id === 183 || category.id === 210) {
                          return (
                            <StyledTableCell align="center">
                              {product.moreFeatures[childrenIndex].children[childIndex].checked ? (<CheckIcon color="success" />) : (<CloseIcon color="primary" />)}
                            </StyledTableCell>
                          );
                        }
                        if (category.id === 275) {
                          return (
                            <StyledTableCell align="center">
                              {product.characteristics[childrenIndex].children[childIndex].checked ? (<CheckIcon color="success" />) : (<CloseIcon color="primary" />)}
                            </StyledTableCell>
                          );
                        }
                        return <StyledTableCell />;
                      })}
                    </StyledTableRow>
                  )
                ))}
              </>
            </>
            )
          ))}
        </>
      </>
    ));
  };

  return (
    productDataList?.length > 0 ? (
      <TableContainer component={Paper} sx={{ maxHeight: windowSize.innerHeight - 200 }}>
        <Table size="small" stickyHeader sx={{ minWidth: 650 }}>
          {generateHeader(productDataList)}
          <TableBody>
            {generateSummaryRow(productDataList)}
            {generateCategories(productDataList)}
          </TableBody>
        </Table>
      </TableContainer>
    ) : <Typography>Keine Daten zum anzeigen</Typography>
  );
};

export default CompareTable;
