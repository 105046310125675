import create from 'zustand';
import { nanoid } from 'nanoid';

import { logger } from './middleware';

import { getYearFromUnixTimestamp, processData } from '../utils';
import { useAppStore, useProductStore } from './index';

const initialState = {
  token: null,
  onboardingData: {
    name: '',
    contactPersons: [
      {
        firstname: '',
        lastname: '',
        email: ''
      }
    ]
  }
};

function appendContactPerson(formData: FormData, contactPersons: any) {
  contactPersons.forEach((contactPerson: ContactPerson, index: number) => {
    if (contactPerson.id !== undefined) {
      formData.append(`company[contactPersons][${index}][id]`, contactPerson.id);
    }
    formData.append(`company[contactPersons][${index}][title]`, contactPerson.title);
    formData.append(`company[contactPersons][${index}][firstname]`, contactPerson.firstname);
    formData.append(`company[contactPersons][${index}][lastname]`, contactPerson.lastname);
    formData.append(`company[contactPersons][${index}][jobTitle]`, contactPerson.jobTitle);
    formData.append(`company[contactPersons][${index}][phoneNumber]`, contactPerson.phoneNumber);
    formData.append(`company[contactPersons][${index}][email]`, contactPerson.email);
  });
}

const store = (set:any, get:any) => ({
  ...initialState,

  userResetAll: () => set({ ...initialState }),

  userLogin: async (email: string, password: string) => {
    const formData = new FormData();
    formData.append('user', email);
    formData.append('password', password);

    set({ loginEmailAddress: email });

    const { error, data } = await processData({ data: formData, url: '/login' });
    if (error) return { success: false };

    // Status des Accounts prüfen
    const formData2 = new FormData();
    formData2.append('email', email);
    const { data: checkData } = await processData({ data: formData2, url: '/get-account-status' });

    // status 0 = 0 no optin, status 10 = no confirmation
    if (checkData.status === 0 || checkData.status === 10) {
      return { success: true, optin: false };
    }

    // status 250 = user deleted
    if (checkData.status === 250) {
      useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Anmeldung ist fehlgeschlagen.' } });
      return { success: false };
    }

    // Code und Token prüfen
    const { token, code } = data;

    // Check code: if 1020 then invalid, no account!
    if (code === 1020) {
      useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Anmeldung ist fehlgeschlagen.' } });
      return { success: false };
    }

    if (code === 200 && token) {
      // // Status des Accounts prüfen
      // const formData2 = new FormData();
      // formData2.append('email', email);
      // const { data: checkData } = await processData({ data: formData2, url: '/get-account-status' });

      // // STATUS IST OK!
      // // TODO: Status 30 (INCOMPLETE) muss hinterfragt werden!!!!!!!
      // if (checkData.status === 20 || checkData.status === 30 || checkData.status === 40) {
      localStorage.setItem('token', token);
      set({ token });

      get().userGetAccountData({ checkShowOnboarding: true });

      useAppStore.setState({ alert: { severity: 'success', text: 'Sie haben sich erfolgreich angemeldet!' } });

      // are onboarding data existing? try to process it
      get().userProcessOnboardingData(email);

      return { success: true, optin: true };
    }

    useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Anmeldung ist fehlgeschlagen.' } });
    return { success: false };
  },

  userReLogin: async () => {
    const token = localStorage.getItem('token');

    // No token in localStorage!
    if (!token) {
      return false;
    }

    // Token in state matches the token in localStorage
    if (token === get().token) {
      return true;
    }

    // Warning: the token could be manipulated --> check the state!
    const success = await get().userGetAccountData();

    // Logout because we didn't get account data
    if (!success) {
      localStorage.removeItem('token');
      get().userLogout();
      // eslint-disable-next-line no-return-assign
      return window.location.href = '/';
    }

    // write token to state
    set({ token });

    // pull event notification, maybe its new
    useAppStore.getState().appGetEventNotification();

    return true;
  },

  userLogout: async (options : GenericObject = {}) => {
    const success = await processData({ ...options, url: '/private/logout' });
    if (!success) {
      useAppStore.setState({ showEventNotification: false, alert: { severity: 'error', text: 'Fehler beim Abmelden' } });
      return false;
    }
    useProductStore.getState().prodResetAll();
    useAppStore.getState().appResetAll();
    get().userResetAll();
    localStorage.removeItem('token');
    useAppStore.setState({ showEventNotification: false, alert: { severity: 'success', text: 'Sie haben sich erfolgreich abgemeldet!' } });
    window.location.href = '/';
    return true;
  },

  userRegister: async (options: GenericObject = {}) => {
    useAppStore.setState({ showSkeleton: true });
    const { error, data } = await processData({ ...options, url: '/register-account' });
    if (error) {
      useAppStore.setState({ showSkeleton: false });
      return false;
    }

    // Code prüfen, wenn nicht 200 dann Fehlermeldung bilden
    const { code, validation } = data;
    if (code === 200) {
      useAppStore.setState({
        showSkeleton: false,
        alert: { severity: 'success', text: 'Sie haben sich erfolgreich registriert! Bitte gehen Sie nun zu Ihrem Posteingang, um die Registrierung im E-Mail zu bestätigen.' }
      });
      return true;
    }

    let text = 'Fehler bei Registrierung';

    if (validation.length > 0) {
      validation.forEach((item: string) => {
        if (item === 'company') text += ', Firmenbezeichnung ist bereits vorhanden';
        if (item === 'email') text += ', E-Mail-Adresse ist bereits vorhanden';
      });
    }

    useAppStore.setState({ showSkeleton: false, alert: { error: true, severity: 'error', text } });
    return false;
  },

  userGetAccountData: (options: GenericObject = {}) => {
    const promise = processData({ ...options, url: '/private/get-account-data' })
      .then((response) => {
        const { error, data } = response;
        if (error) return false;

        const {
          email, id, status, company, loginCount
        } = data.account;

        const { firstname, lastname } = data.account.person;
        const {
          solutions: singleProducts, projects, inactiveProjects, deactivatedProjects, combiproducts: combi
        } = company;

        const newOwn = combi.own.map((item:GenericObject) => ({ ...item, owner: true, isCombiProduct: true }));
        const newForeign = combi.foreign.map((item:GenericObject) => ({ ...item, owner: false, isCombiProduct: true }));
        useProductStore.setState({
          singleProducts,
          combiProducts: [...newOwn, ...newForeign],
          portfolio: [...singleProducts, ...newOwn, ...newForeign],
          projects: [...projects, ...inactiveProjects, ...deactivatedProjects],
          offersCombiProducts: combi.requests || []
        });

        // calculate FoundingTime
        const { foundingTime } = company;
        const newfoundingTime = getYearFromUnixTimestamp(foundingTime);
        // delete company.contactPersons;
        const newCompany = { ...company, foundingTime: newfoundingTime };
        set({
          loginCount, firstname, lastname, email, id, status, company: newCompany
        });

        // we need loginCount to show onboarding sequence on start page or not
        // If it is requested, check wether to show the onboarding dialog or not
        if (options.checkShowOnboarding) {
          if (!loginCount || loginCount <= 2) {
            useAppStore.setState({ showOnboarding: false });
          }
        }

        return true;
      });

    return promise;
  },

  userResendOptIn: async (email: string) => {
    useAppStore.setState({ showSkeleton: true });
    const formData = new FormData();
    formData.append('email', email);

    const { error, data } = await processData({ data: formData, url: '/resend-optin' });
    if (error) {
      useAppStore.setState({ showSkeleton: false });
      return false;
    }

    // 200 STATUS_OK
    // 1020 ERROR_INVALID
    // 1030 ERROR_NOT_FOUND
    // 1040 ERROR_WRONG_STATUS
    // 1050 ERROR_EXCEPTION
    // TODO: Fehlermeldung aufgrund des Status mehr spezifizieren
    if (data.code === 200) {
      useAppStore.setState({ showSkeleton: false, alert: { severity: 'success', text: 'Opt-In wurde erfolgreich per E-Mail versendet.' } });
      return true;
    }

    useAppStore.setState({
      showSkeleton: false,
      alert: {
        error: true, severity: 'error', text: 'Opt-in ist nicht mehr nötig oder es ist ein Fehler aufgetreten.'
      }
    });
    return false;
  },

  userCheckStatus: async (email: string) => {
    const formData = new FormData();
    formData.append('email', email);

    const { error, data } = await processData({ data: formData, url: '/get-account-status' });
    if (error) return false;

    // STATUS NOT OK!
    // TODO: Status 30 (INCOMPLETE) muss hinterfragt werden!!!!!!!
    if (data.status !== 20 && data.status !== 30 && data.status !== 40) {
      get().userLogout();
      return false;
    }

    return true;
  },

  userRemoveAccount: async () => {
    const { error, data } = await processData({ url: '/private/remove-account' });
    if (error) return false;

    if (data.code === 200) {
      localStorage.removeItem('token');
      get().userResetAll();
      useAppStore.setState({ alert: { severity: 'success', text: 'Benutzerkonto wurde erfolgreich gelöscht.' } });
      return true;
    }

    return true;
  },

  userResetPassword: async (email: string) => {
    useAppStore.setState({ showResetPassword: false, showSkeleton: true });

    const formData = new FormData();
    formData.append('email', email);

    const { error, data } = await processData({ data: formData, url: '/handle-password-forgotten' });
    if (error) {
      useAppStore.setState({ showSkeleton: false });
      return false;
    }

    if (data.code === 200) {
      useAppStore.setState({
        showSkeleton: false, // close loading spinner
        alert: { severity: 'success', text: 'Ein E-Mail mit weiteren Anleitungen ist unterwegs.' }
      });
      return true;
    }

    useAppStore.setState({
      showSkeleton: false,
      alert: {
        error: true, severity: 'error', text: 'Beim Zurücksetzen Ihres Passwortes ist leider ein Fehler aufgetreten.'
      }
    });
    return false;
  },

  userEditAccountName: async (firstname: string, lastname: string) => {
    const formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);

    const { error, data } = await processData({ data: formData, url: '/private/edit-account-name' });
    if (error) return false;

    if (data.code === 200) {
      set({ firstname, lastname });
      useAppStore.setState({ alert: { severity: 'success', text: 'Ihre Benutzerdaten wurden erfolgreich geändert!' } });
      return true;
    }

    useAppStore.setState({
      alert: {
        error: true, severity: 'error', text: 'Das Ändern der Benutzerdaten ist leider fehlgeschlagen.'
      }
    });
    return false;
  },

  userEditEmailAddresse: async (email: string) => {
    const formData = new FormData();
    formData.append('email', email);

    const { error, data } = await processData({ data: formData, url: '/private/edit-account-email' });
    if (error) return false;

    if (data.code === 200) {
      set({ email });
      useAppStore.setState({ alert: { severity: 'success', text: 'Ihre E-Mail-Adresse wurde erfolgreich geändert, bitte vergessen Sie nicht, diese im zugesandeten Email zu bestätigen!' } });
      return true;
    }

    useAppStore.setState({
      alert: {
        error: true, severity: 'error', text: 'Das Ändern Ihrer Email-Adresse ist leider fehlgeschlagen.'
      }
    });
    return false;
  },

  userEditPassword: async (passwordCurrent: string, passwordNew: string) => {
    const formData = new FormData();
    formData.append('passwordCurrent', passwordCurrent);
    formData.append('passwordNew', passwordNew);

    const { error, data } = await processData({ data: formData, url: '/private/edit-account-password' });
    if (error) return false;

    if (data.code !== 200) {
      useAppStore.setState({
        alert: {
          error: true, severity: 'error', text: 'Das Ändern des Passwortes ist leider fehlgeschlagen.'
        }
      });
      return false;
    }

    const { token, code } = data;
    if (code === 200 && token) {
      set({ token });
      useAppStore.setState({ alert: { severity: 'success', text: 'Ihr Passwort wurde erfolgreich geändert!' } });
      return true;
    }

    return false;
  },

  userEditCompanyLogo: async (imageData:string) => {
    const formData = new FormData();
    formData.append('file', imageData);
    if (imageData !== '') {
      formData.append('filename', `${nanoid(20)}.jpg`);
    } else {
      formData.append('filename', '');
    }

    const { error, data } = await processData({ data: formData, url: '/private/edit-company-logo' });
    if (error) return false;

    if (data.code !== 200) {
      useAppStore.setState({
        alert: {
          error: true, severity: 'error', text: 'Das Ändern des Firmenlogos ist leider fehlgeschlagen.'
        }
      });
      return false;
    }

    const { code } = data;
    if (code === 200) {
      useAppStore.setState({ alert: { severity: 'success', text: 'Ihr Firmenlogo wurde erfolgreich geändert!' } });
      get().userGetAccountData();
      return true;
    }

    return false;
  },

  userEditPersonLogo: async (id:string, imageData:string) => {
    const formData = new FormData();
    formData.append('id', id);
    formData.append('file', imageData);
    formData.append('filename', `${nanoid(20)}.jpg`);

    const { error, data } = await processData({ data: formData, url: '/private/edit-contact-person-image' });
    if (error) return false;

    if (data.code !== 200) {
      useAppStore.setState({
        alert: {
          error: true, severity: 'error', text: 'Das Ändern des Ansprechpartnerlogos ist leider fehlgeschlagen.'
        }
      });
      return false;
    }

    const { code } = data;
    if (code === 200) {
      useAppStore.setState({ alert: { severity: 'success', text: 'Ihr Ansprechpartnerlogo wurde erfolgreich geändert!' } });
      get().userGetAccountData();
      return true;
    }

    return false;
  },

  appendAddresses(formData: HTMLFormElement, addresses: Address[]) {
    addresses.forEach((address: Address, index: number) => {
      formData.append(`company[addresses][${index}][id]`, address.id);
      formData.append(`company[addresses][${index}][street]`, address.street);
      formData.append(`company[addresses][${index}][housenumber]`, address.housenumber);
      formData.append(`company[addresses][${index}][zip]`, address.zip);
      formData.append(`company[addresses][${index}][city]`, address.city);
      formData.append(`company[addresses][${index}][state]`, address.state);
      formData.append(`company[addresses][${index}][country]`, address.country);
      formData.append(`company[addresses][${index}][contactInfo][phoneNumber1]`, address.contactInfo.phoneNumber1 || '');
      formData.append(`company[addresses][${index}][contactInfo][phoneNumber2]`, address.contactInfo.phoneNumber2 || '');
      formData.append(`company[addresses][${index}][contactInfo][email]`, address.contactInfo.email || '');
      formData.append(`company[addresses][${index}][contactInfo][homepage]`, address.contactInfo.homepage || '');
    });
  },

  userEditCompanyProfile: async (profile:Company) => {
    // convert founding year to Unix timestamp, blame it on the backend developer :-)
    const companyFoundingYear = +new Date(`${profile.foundingTime}-01-01`) / 1000;

    const formData = new FormData();
    // appendContactPerson(formData, profile.contactPersons);

    formData.append('company[name]', profile.name);
    formData.append('company[foundingTime]', `${companyFoundingYear}`);
    if (profile?.description) {
      formData.append('company[description]', profile.description);
    }
    if (profile?.companyType?.id !== '' && profile?.companyType?.id !== null) {
      formData.append('company[companyTypeId]', profile.companyType.id);
    }
    if (profile?.numberOfEmployees?.id !== '' && profile?.numberOfEmployees?.id !== null) {
      formData.append('company[numberOfEmployeesId]', profile.numberOfEmployees.id);
    }
    if (profile?.sales?.id !== '' && profile?.sales?.id !== null) {
      formData.append('company[salesId]', profile.sales.id);
    }
    if (!profile.addresses[0].id) {
      formData.append('company[addresses][0][id]', '');
    } else if (profile?.addresses[0]?.id !== '') {
      formData.append('company[addresses][0][id]', profile.addresses[0].id);
    }
    if (profile?.addresses[0]?.city) {
      formData.append('company[addresses][0][city]', profile.addresses[0].city);
    }
    if (profile?.addresses[0]?.country) {
      formData.append('company[addresses][0][country]', profile.addresses[0].country);
    }
    if (profile?.addresses[0]?.state) {
      formData.append('company[addresses][0][state]', profile.addresses[0].state);
    }
    if (profile?.addresses[0]?.street) {
      formData.append('company[addresses][0][street]', profile.addresses[0].street);
    }
    if (profile?.addresses[0]?.housenumber) {
      formData.append('company[addresses][0][housenumber]', profile.addresses[0].housenumber);
    }
    if (profile?.addresses[0]?.zip) {
      formData.append('company[addresses][0][zip]', profile.addresses[0].zip);
    }
    if (profile?.addresses[0]?.contactInfo?.phoneNumber1) {
      formData.append('company[addresses][0][contactInfo][phoneNumber1]', profile.addresses[0].contactInfo.phoneNumber1);
    }
    if (profile?.addresses[0]?.contactInfo?.phoneNumber2) {
      formData.append('company[addresses][0][contactInfo][phoneNumber2]', profile.addresses[0].contactInfo.phoneNumber2);
    }
    if (profile?.addresses[0]?.contactInfo?.homepage) {
      formData.append('company[addresses][0][contactInfo][homepage]', profile.addresses[0].contactInfo.homepage);
    }
    if (profile?.addresses[0]?.contactInfo?.email) {
      formData.append('company[addresses][0][contactInfo][email]', profile.addresses[0].contactInfo.email);
    }
    if (!profile.contactPersons[0].id) {
      formData.append('company[contactPersons][0][id]', '');
    } else if (profile?.addresses[0]?.id !== '') {
      formData.append('company[contactPersons][0][id]', profile.contactPersons[0].id);
    }
    if (profile?.contactPersons[0]?.email) {
      formData.append('company[contactPersons][0][email]', profile.contactPersons[0].email);
    }
    if (profile?.contactPersons[0]?.title) {
      formData.append('company[contactPersons][0][title]', profile.contactPersons[0].title);
    }
    if (profile?.contactPersons[0]?.firstname) {
      formData.append('company[contactPersons][0][firstname]', profile.contactPersons[0].firstname);
    }
    if (profile?.contactPersons[0]?.lastname) {
      formData.append('company[contactPersons][0][lastname]', profile.contactPersons[0].lastname);
    }
    if (profile?.contactPersons[0]?.jobTitle) {
      formData.append('company[contactPersons][0][jobTitle]', profile.contactPersons[0].jobTitle);
    }
    if (profile?.contactPersons[0]?.phoneNumber) {
      formData.append('company[contactPersons][0][phoneNumber]', profile.contactPersons[0].phoneNumber);
    }
    if (profile?.contactPersons[0]?.email) {
      formData.append('company[contactPersons][0][email]', profile.contactPersons[0].email);
    }
    const { error, data } = await processData({ data: formData, url: '/private/edit-company' });
    if (error) return false;

    if (data.code !== 200) {
      useAppStore.setState({
        alert: {
          error: true, severity: 'error', text: 'Das Ändern der Profildaten ist leider fehlgeschlagen.'
        }
      });
      return false;
    }

    const { code } = data;
    if (code === 200) {
      useAppStore.setState({ alert: { severity: 'success', text: 'Ihre Profildaten wurden erfolgreich geändert!' } });
      get().userGetAccountData();
      return true;
    }

    return false;
  },

  userOnboarding: async (onboardingToken: string) => {
    const { error, data } = await processData({ onboardingToken, url: '/get-onboarding-data' });
    if (error) return { success: false };

    if (data.code === 200) {
      // check status of email address. if already an active user then return with no further actions
      const { email } = data.data.company.contactPersons[0];
      const formData2 = new FormData();
      formData2.append('email', email);
      const { error: errorStatus, data: checkData } = await processData({ data: formData2, url: '/get-account-status' });

      if (errorStatus) return { success: false };

      if (checkData.status === 20 || checkData.status === 30 || checkData.status === 40) {
        return { success: false };
      }

      // write to store
      set({ onboardingData: data.data.company });

      // save to locale storage
      localStorage.setItem(`onboarding-${onboardingToken}`, JSON.stringify(data.data.company));

      return { success: true };
    }
    return true;
  },

  userProcessOnboardingData: (email: string) => {
    // loop through localStorage and search for record with submitted email address
    // if not found, exit method without further actions
    let data:any;
    Object.keys(localStorage).forEach((key) => {
      if (key.indexOf('onboarding-') > -1) {
        const strData:string = localStorage.getItem(key) || '';
        const temp = JSON.parse(strData);

        // search for email address
        // if (temp.contactPersons[0].email === email) data = temp;
        const dataDomain = temp.contactPersons[0].email.substring(temp.contactPersons[0].email.lastIndexOf('@') + 1);
        const mailDomain = email.substring(email.lastIndexOf('@') + 1);
        if (dataDomain === mailDomain) data = temp;
      }
    });

    if (!data) return false;

    // update company and contact person data
    const profile = {
      companyName: data.name || data.id,
      companyDescription: data.description,
      companyFoundingYear: new Date().getFullYear().toString(),
      companyTypeId: data.companyType.id,
      companyNumberOfEmployeesId: data.numberOfEmployees.id,
      companySalesId: '221',
      companyCity: data.addresses[0].city,
      companyWebsite: '',
      companyPhoneNumber: data.contactPersons[0].phoneNumber,
      personEmail: email,
      personFirstName: data.contactPersons[0].firstname,
      personLastName: data.contactPersons[0].lastname,
      personJobTitle: data.contactPersons[0].jobTitle,
      personPhoneNumber: data.contactPersons[0].phoneNumber,
      personTitle: data.contactPersons[0].title,
    };

    get().userEditCompanyProfile(profile)
      .then((response:any) => {
        // update company logo???

        // update contact person photo???
        // add single product(s)
        if (data.solutions.length > 0) {
          const promises:Array<any> = [];
          data.solutions.forEach((item:GenericObject) => {
            // make a list of categories
            const categoriesList = item.categories.map((element:GenericObject) => element.id).join();

            const form = new FormData();
            form.append('name', item.name);
            form.append('summary', item.summary);
            form.append('url', item.productURL);
            form.append('categories', categoriesList);

            const url = '/private/add-solution';

            promises.push(processData({ data: form, url }));
          });
          // return a promise collection of all add solution requests
          return Promise.all(promises);
        }
        return true;
      }).then((response:any) => {
        // remove onboarding data from localStorage
        localStorage.removeItem(`onboarding-${data.id}`);

        // load account data new
        return get().userGetAccountData();
      })
      .catch((error:any) => {
        console.log(error);
      });
    return true;
  },

});

const useUserStore = create(logger(store));
export default useUserStore;
