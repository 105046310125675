import {
  Chip, Box
} from '@mui/material';
import {
  ReactElement, useState
} from 'react';

const ExpandableChips = (props: GenericObject): ReactElement => {
  const [expanded, setExpanded] = useState(false);

  const { group } = props;

  return (
    <Box sx={{ mr: 0 }}>
      {group.children.slice(0, expanded ? group.children.length : 10).map((res: any) => (
        <Chip
          size="small"
          sx={{
            ml: 0,
            mr: 1,
            mb: 1,
            fontSize: 12,
            backgroundColor: 'secondary.main',
            color: 'secondary.contrastText'
          }}
          label={`${res.name}`}
          variant="filled"
          key={`cate_${res.id}`}
        />
      ))}
      {group.children.length > 10 && (
      <>
        {expanded ? (
          null
        ) : (
          <Chip
            size="small"
            sx={{
              ml: 0,
              mr: 1,
              mb: 1,
              fontSize: 12,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            label={`+ ${group.children.length - 10}`}
            variant="outlined"
            color="primary"
            onClick={() => setExpanded(!expanded)}
          />
        )}
      </>
      )}
    </Box>
  );
};

export default ExpandableChips;
