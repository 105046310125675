/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import {
  useState, useEffect
} from 'react';

// import { useTranslation } from 'react-i18next';
import { useAppStore, useProductStore } from '../state';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCategories = (params:any = {}) => {
  const initTree:any = {
    industries: [], functionalities: [], characteristics: [], moreFeatures: []
  };

  const [step, setStep] = useState(1);
  const [touched, setTouched] = useState(false);
  const [name, setName] = useState();
  const [summary, setSummary] = useState<string>('');
  const [productURL, setProductURL] = useState();
  const [tree, setTree] = useState(initTree);
  const [countCategories, setCountCategories] = useState<Record<string, number>>();
  const [isCrossIndustries, setIsCrossIndustries] = useState(false);

  const {
    prodGetCategories, categories, currentProductProject, searchCategories
  } = useProductStore.getState();

  const getCategoryChecked = (id:number, arrayIDs:any) => {
    // Meeting 2022-01-28: all elements should be not checked by default!
    let newChecked = false;
    // let newChecked = true;
    // if (parentId !== 1) newChecked = false;
    if (arrayIDs.length > 0) {
      if (arrayIDs.includes(id)) {
        newChecked = true;
      } else {
        newChecked = false;
      }
    }

    return newChecked;
  };

  const isAnythingChecked = (arrayIDs: any) => arrayIDs.some((item:any) => item === true);

  const getListCategories = ():string => {
    const allCategories:any = [];
    [...tree.industries].forEach((element:GenericObject) => {
      if (element.id === 120 && element.checked) {
        allCategories.push(element.id);
      } else if (element.children) {
        element.children.forEach((subelement:GenericObject) => {
          if (subelement.checked) allCategories.push(subelement.id);
        });
      }
    });

    [...tree.functionalities, ...tree.characteristics, ...tree.moreFeatures]
      .forEach((element:GenericObject) => {
        if (element.children) {
          element.children.forEach((subelement:GenericObject) => {
            if (subelement.checked) allCategories.push(subelement.id);
          });
        }
      });

    // if params.modeSearchDialog no error dialog should be shown
    if (params.modeSearchDialog) {
      return allCategories.join();
    }

    // last check: if array is empty cancel saving
    if (allCategories.length === 0) {
      const dialogData = {
        title: { text: 'Ein Problem ist aufgetreten.' },
        contentItems: [{ text: 'Es wurde keine einzige Kategorie gewählt, ein Speichern ist aus diesem Grund leider nicht möglich".' }],
        actionItems: [{ text: 'OK, verstanden', color: 'secondary' }],
      };
      useAppStore.setState({ dialogData });
    }

    return allCategories.join();
  };

  const countMainCategories = () => {
    const {
      industries, functionalities, characteristics, moreFeatures
    } = tree;

    const counts: Record<string, number> = [...industries, ...functionalities, ...characteristics, ...moreFeatures]
      .reduce((acc, element) => {
        if (element.children) {
          element.children.forEach((sub: GenericObject) => {
            if (sub.checked) {
              acc[sub.parentId] = (acc[sub.parentId] || 0) + 1;
            }
          });
        }
        return acc;
      }, {});

    setCountCategories(counts);
  };

  // call API to get a list of categories
  useEffect(() => {
    prodGetCategories();
  }, []);

  // convert categories to sub-categories, init categories tree
  useEffect(() => {
    const currentItemCategories = params?.currentItem?.categories;
    const projectCategories = currentProductProject?.categories;
    const currentSearchCategories = searchCategories || [];
    // if edit product mode than extract existing category ids
    const extractCategories = (allCategories: any, currentCategories: Array<any>) => {
      allCategories.forEach((category: GenericObject) => {
        currentCategories.push(category.id);
        if (category.children) {
          extractCategories(category.children, currentCategories);
        }
      });
    };

    const currentCategories:Array<any> = [];
    if (currentItemCategories) {
      extractCategories(currentItemCategories, currentCategories);
    } else if (projectCategories) {
      extractCategories(currentProductProject.categories, currentCategories);
    } else {
      currentCategories.push(...currentSearchCategories);
    }

    // prepare Arrays for the 4 sections of categories
    let industries:any = [];
    let functionalities:any = [];
    let characteristics:any = [];
    let moreFeatures:any = [];
    // loop through original categories from API
    categories.forEach((category:GenericObject) => {
      const parentId = category.id;
      let checkedParent:any;
      const newCategories = categories
        .find((item:GenericObject) => item.id === category.id).children
        // .filter((cat:GenericObject) => cat.id !== 120) // avoid cross-industries (branchenübergreifend)
        .map((cat:GenericObject) => {
          checkedParent = params.modeSearchDialog ? true : getCategoryChecked(cat.id, currentCategories);
          if (cat.id === 120) {
            setIsCrossIndustries(getCategoryChecked(cat.id, currentCategories));
            checkedParent = getCategoryChecked(cat.id, currentCategories);
          }

          const children = cat.children.map((child:GenericObject) => {
            // determine if this element is selected
            const checked = getCategoryChecked(child.id, currentCategories);
            // when SubSubCategory is Checked Parent needs to be Checked too
            if (checked) {
              checkedParent = true;
            }
            // Default is that element is  hidden
            let show = false;

            // if industries (Branchen) then show all elements
            if (parentId === 1) show = true;
            // if (parentId === 275) show = true;

            // in edit mode show depens on if parent element is checked
            if (currentCategories) {
              show = checkedParent;
            }

            // if it is the searchDialogMode then checkend is false and show is true
            if (params.modeSearchDialog) {
              show = true;
            }

            // we assume that the element fits the fulltext search
            const search = true;

            return ({
              id: child.id,
              parentId: cat.id,
              name: child.name,
              checked,
              show,
              search
            });
          });

          const obj = {
            id: cat.id,
            parentId,
            name: cat.name,
            icon: cat.feContent[0].value,
            children,
            checked: checkedParent
          };
          return obj;
        });

      if (category.id === 1) industries = newCategories;
      if (category.id === 121) functionalities = newCategories;
      if (category.id === 183 || category.id === 210) moreFeatures = newCategories;
      if (category.id === 275) {
        characteristics = newCategories;
      }
    });
    setTree(() => ({
      ...tree, industries, functionalities, characteristics, moreFeatures
    }));

    countMainCategories();
  }, [categories.length, searchCategories]);

  // handle clicks on indstrie icon on step 1
  const toggleIndustry = (id: number) => {
    // copy to trigger re-rendering on changes
    const copyOfArray:any = [...tree.industries];

    // find this industry
    let index = -1;
    index = copyOfArray.findIndex((e:GenericObject) => e.id === id);

    // when CrossIndustries then handle Checked Industries
    if (index === 7) {
      const newChecked = !copyOfArray[index].checked;
      copyOfArray[index].checked = newChecked;
      setIsCrossIndustries(newChecked);
      // when CrossIndustries true uncheck other Industries
      if (newChecked === true) {
        for (let i = 0; i < 7; i += 1) {
          copyOfArray[i].checked = false;
          // eslint-disable-next-line no-loop-func
          copyOfArray[i].children.forEach((_child:GenericObject, idx:number) => {
            copyOfArray[i].children[idx].checked = false;
            copyOfArray[i].children[idx].show = false;
            copyOfArray[i].children[idx].search = false;
          });
        }
      }
    } else {
      // when non CrossIndustries then uncheck CrossIndustries
      copyOfArray[7].checked = false;
      setIsCrossIndustries(false);

      // toggle checked
      const newChecked = !copyOfArray[index].checked;
      copyOfArray[index].checked = newChecked;

      // inherit newChecked to all children
      copyOfArray[index].children.forEach((child:GenericObject, idx:number) => {
        copyOfArray[index].children[idx].checked = newChecked;
        copyOfArray[index].children[idx].show = !copyOfArray[index].children[idx].show;
        copyOfArray[index].children[idx].search = true;
      });
    }

    // update industries in state
    setTree({ ...tree, industries: copyOfArray });
    countMainCategories();
  };

  const resetCategories = () => {
    const resetTree = { ...tree };

    const resetCategory = (category:GenericObject) => {
      category.checked = false;
      if (category.children) {
        category.children.forEach((child:GenericObject) => {
          resetCategory(child);
        });
      }
    };

    resetTree.industries.forEach(resetCategory);
    resetTree.functionalities.forEach(resetCategory);
    resetTree.characteristics.forEach(resetCategory);
    resetTree.moreFeatures.forEach(resetCategory);

    setTree(resetTree);
  };

  function getNewCategories(categoryIds: any[], currentCategories: any) {
    if (!Array.isArray(categoryIds)) {
      categoryIds = [categoryIds]; // Falls nur eine ID übergeben wird, in ein Array umwandeln
    }

    return categories
      .filter((category: { id: any; }) => categoryIds.includes(category.id))
      .map((category: { id: number; parentId: any; name: any; feContent: { value: any; }[]; children: any[]; }) => {
        const icon = category.feContent && category.feContent.length > 0 ? category.feContent[0].value : null;

        return {
          id: category.id,
          parentId: category.parentId,
          name: category.name,
          icon,
          children: category.children.map((child: { id: number; name: any; }) => {
            const checked = getCategoryChecked(child.id, currentCategories);
            const show = category.id === 1 || getCategoryChecked(category.id, currentCategories);
            return {
              id: child.id,
              parentId: category.id,
              name: child.name,
              checked,
              show,
              search: true
            };
          }),
          checked: getCategoryChecked(category.id, currentCategories)
        };
      });
  }

  const setCombiProductAsCurrentProduct = (combiproduct: any[]) => {
    const currentCategories: Array<any> = [];
    combiproduct.forEach((product: GenericObject) => {
      if (product.categories) {
        product.categories.forEach((element:GenericObject) => {
          if (element.children) {
            element.children.forEach((subelement:GenericObject) => {
              if (element.children) {
                subelement.children.forEach((subsubelement:GenericObject) => {
                  currentCategories.push(subsubelement.id);
                  if (subsubelement.children) {
                    subsubelement.children.forEach((subsubsubelement:GenericObject) => {
                      currentCategories.push(subsubsubelement.id);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });

    for (let i = 0; i < currentCategories.length; i += 1) {
      if (currentCategories[i] === 120) {
        currentCategories.splice(i, 1);
        i -= 1;
      }
    }

    let industries: any = [];
    let functionalities: any = [];
    let characteristics: any = [];
    let moreFeatures: any = [];

    // loop through original categories from API
    categories.forEach((category:GenericObject) => {
      const parentId = category.id;
      const newCategories = categories
        .find((item:GenericObject) => item.id === category.id).children
        // .filter((cat:GenericObject) => cat.id !== 120) // avoid cross-industries (branchenübergreifend)
        .map((cat:GenericObject) => {
          const checkedParent = getCategoryChecked(cat.id, currentCategories);

          const children = cat.children.map((child:GenericObject) => {
            // determine if this element is selected
            const checked = getCategoryChecked(child.id, currentCategories);

            // Default is that element is  hidden
            let show = false;

            // if industries (Branchen) then show all elements
            if (parentId === 1) show = true;

            // in edit mode show depens on if parent element is checked
            if (currentCategories) show = checkedParent;

            // we assume that the element fits the fulltext search
            const search = true;

            return ({
              id: child.id,
              parentId: cat.id,
              name: child.name,
              checked,
              show,
              search
            });
          });

          const obj = {
            id: cat.id,
            parentId,
            name: cat.name,
            icon: cat.feContent[0].value,
            children,
            checked: checkedParent
          };
          return obj;
        });

      if (category.id === 1) industries = newCategories;
      if (category.id === 121) functionalities = newCategories;
      if (category.id === 183 || category.id === 210) moreFeatures = newCategories;
      if (category.id === 275) characteristics = newCategories;
    });
    setTree(() => ({
      ...tree,
      industries,
      functionalities,
      characteristics,
      moreFeatures,
    }));

    countMainCategories();
  };

  const toggleAllVisibleCategories = (type:string, select: boolean) => {
    const copyOfArray = [...tree[type]];
    copyOfArray.forEach((elem) => {
      elem.children.forEach((elem2: GenericObject) => {
        if (elem2.show && elem2.search) {
          elem2.checked = select; // Set to false if you want to uncheck all
        }
      });
    });

    setTree((prevState:any) => ({
      ...prevState,
      [type]: copyOfArray,
    }));

    countMainCategories();
  };

  // handle clicks on main elements like Handel or Ertragsmodell to filter sub elements
  const toggleFilter = (type:string, id: number) => {
    // copy to trigger re-rendering on changes
    const copyOfArray:any = [...tree[type]];

    let index = -1;
    index = copyOfArray.findIndex((e:GenericObject) => e.id === id);

    copyOfArray[index].checked = !copyOfArray[index].checked;

    copyOfArray[index].children.forEach((child:GenericObject, idx:number) => copyOfArray[index].children[idx].show = !copyOfArray[index].children[idx].show);

    // update categories tree in state
    setTree({ ...tree, [type]: copyOfArray });
    countMainCategories();
  };

  const processSearchTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: fieldName, value } = event.target;

    // copy to trigger re-rendering on changes
    const copyOfArray = tree[fieldName].map((elem: { children: any[]; }) => ({
      ...elem,
      children: elem.children.map((elem2: { name: string; }) => ({
        ...elem2,
        search: elem2.name.toLowerCase().includes(value.toLowerCase())
      }))
    }));

    // update categories tree in state
    setTree((prevTree: any) => ({ ...prevTree, [fieldName]: copyOfArray }));
  };

  // handle clicks on sub elements like 5G or Optiker
  const toggleCheckbox = (type:string, id:number) => {
    const tempArray:any = [...tree[type]];

    tempArray.forEach((elem:GenericObject) => {
      elem.children.forEach((elem2:GenericObject) => {
        if (elem2.id === id) {
          elem2.checked = !elem2.checked;
        }
      });
    });

    // update categories tree in state
    setTree({ ...tree, [type]: tempArray });

    countMainCategories();
  };

  const validateCheckedIndustriesFunctionalities = () => {
    // validation of selected categories
    let countIndustries = 0;

    const acrossall = tree.industries.find((item: GenericObject) => item.id === 120);
    if (acrossall.checked) {
      countIndustries += 1;
    } else {
      tree.industries
      // .filter((item:GenericObject) => item.id !== 120)
        .forEach((item:GenericObject) => {
          item.children.forEach((child:GenericObject) => {
            if (child.checked) countIndustries += 1;
          });
        });
    }

    let countFunctionalities = 0;

    tree.functionalities.forEach((item:GenericObject) => {
      item.children.forEach((child:GenericObject) => {
        if (child.checked) countFunctionalities += 1;
      });
    });

    let countCharacteristics = 0;

    tree.characteristics.forEach((item:GenericObject) => {
      item.children.forEach((child:GenericObject) => {
        if (child.checked) countCharacteristics += 1;
      });
    });

    // wenn industries length 0 ist, dann kein Weiter möglich!!
    if (countIndustries === 0 || countFunctionalities === 0 || countCharacteristics === 0) {
      return false;
    }

    return true;
  };

  return {
    step,
    touched,
    name,
    productURL,
    summary,
    tree,
    countCategories,
    currentProductProject,
    categories,
    isCrossIndustries,
    toggleIndustry,
    toggleFilter,
    toggleCheckbox,
    processSearchTerm,
    validateCheckedIndustriesFunctionalities,
    countMainCategories,
    getListCategories,
    getCategoryChecked,
    setCombiProductAsCurrentProduct,
    setStep,
    setTouched,
    setName,
    setSummary,
    setProductURL,
    resetCategories,
    toggleAllVisibleCategories,
    isAnythingChecked,
  };
};

function useWindowSize(): number[] {
  const [size, setSize] = useState([0, 0]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export {
  useCategories,
  useWindowSize,
};
