/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import {
  FC, ReactElement, useEffect, useState
} from 'react';

import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  Paper,
  useTheme,
  Container
} from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';
import PlaceholderLogoCompany from '../assets/images/placeholder_company.png';
import FormInputText from '../components/ui/FormInputText';

import { useCategories } from '../hooks';

import Categories from '../components/edit-product/Categories';
import Logos from '../components/edit-product/Logos';

import {
  ruleProductName, ruleProductSummary, ruleCompanyWebsite
} from '../rules';

import { useAppStore, useProductStore, useUserStore } from '../state';
import UploadAndDisplayImage from '../components/ui/UploadAndDisplayImage';
import Card from '../components/ui/Card';

const EditSingleProduct:FC = ():ReactElement => {
  const { t } = useTranslation();

  // user-defined hook to manage the categories
  const {
    step,
    touched,
    name,
    summary,
    productURL,
    tree,
    countCategories,
    currentProductProject,
    isCrossIndustries,
    toggleIndustry,
    toggleFilter,
    toggleCheckbox,
    processSearchTerm,
    getListCategories,
    countMainCategories,
    validateCheckedIndustriesFunctionalities,
    setStep,
    setTouched,
    setName,
    setSummary,
    setProductURL,
    toggleAllVisibleCategories
  } = useCategories();
  const { company } = useUserStore.getState();

  const logoCompany = company?.logo;

  const [logoTouched, setLogoTouched] = useState(false);
  const [logoProductString, setLogoProductString] = useState('null');
  // eslint-disable-next-line no-nested-ternary
  const [currentLogo, setCurrentLogo] = useState(currentProductProject ? (currentProductProject.logo !== null ? currentProductProject.logo : logoCompany || PlaceholderLogoCompany) : logoCompany || PlaceholderLogoCompany);

  const {
    prodAddProduct, prodEditProduct, prodEditProductLogo
  } = useProductStore.getState();

  const navigate = useNavigate();

  const {
    handleSubmit, control, formState, register
  } = useForm();
  const { dirtyFields } = formState;

  const onSaveProductLogo = (imageData:string) => {
    setLogoTouched(true);
    setLogoProductString(imageData);
    setCurrentLogo(`data:image/jpeg;base64,${imageData}`);
  };

  const onChangeImage = (imageData : string) => {
    setLogoTouched(true);
    setLogoProductString(imageData);
  };

  const onDeleteImage = () => {
    setCurrentLogo(PlaceholderLogoCompany);
    setLogoTouched(true);
    setLogoProductString('');
  };

  const onSubmitNameSummary = (data: GenericObject) => {
    setTouched(true);
    setStep((currentStep) => currentStep + 1);
    setName(data.name);
    setSummary(data.summary);
    setProductURL(data.productURL);

    return true;
  };

  const [catArray, setCatArray] = useState<any>([]);

  const newCatArray:any = [];
  const children:any = [];

  const [previewItem, setPreviewItem] = useState({
    id: -1,
    name: '',
    productURL: '',
    type: 'solution',
    logo: '',
    summary: '',
    company,
    tree
  });

  // Saving the new product / the changes
  const handleSave = () => {
    const allCategories = getListCategories();
    if (allCategories.length === 0) return false;

    const dataItem = {
      id: currentProductProject?.id,
      name,
      productURL,
      type: 'singleProduct',
      summary,
      categories: allCategories,
    };

    // add or edit product
    const promise = currentProductProject
      ? prodEditProduct(dataItem)
      : prodAddProduct(dataItem);

    promise
      .then((data: GenericObject) => {
        const {
          success, id,
        } = data;
        if (success && logoTouched) {
          const result = prodEditProductLogo(id === undefined ? currentProductProject.id : id, logoProductString);

          result
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .then((success: boolean) => {
              if (success) {
                useProductStore.getState().prodResetAll();
                return navigate('/app/portfolio');
              }
              useProductStore.getState().prodResetAll();
              return navigate('/app/portfolio');
            });
          useProductStore.getState().prodResetAll();
          return navigate('/app/portfolio');
        }
        useProductStore.getState().prodResetAll();
        return navigate('/app/portfolio');
      });

    return true;
  };

  const handleGoToList = () => navigate('/app/portfolio');

  const handleBack = () => setStep(1);

  const handleCancel = () => {
    // if some fields are touched so ask for quitting
    if (Object.keys(dirtyFields).length === 0 && !touched) return handleGoToList();

    setTouched(true);

    const dialogData = {
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.confirmCancelEditProduct'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.noGoOn'),
          color: 'secondary',
        },
        {
          text: t('common.button.yesLeave'),
          color: 'error',
          onClick: handleGoToList
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleGoToLastPage = () => {
    ['industries', 'functionalities', 'characteristics', 'moreFeatures'].forEach((category:string) => {
      children.splice(0);
      tree[category].forEach((elem:GenericObject) => {
        if (elem.id === 120) {
          if (elem.checked) children.push({ id: elem.id, name: elem.name });
        }
        elem.children.forEach((subelem:GenericObject) => {
          if (subelem.checked) {
            children.push({ id: subelem.id, name: subelem.name, parentname: elem.name });
          }
        });
      });

      // eslint-disable-next-line no-nested-ternary
      const id = category === 'industries' ? 1 : (category === 'functionalities' ? 121 : (category === 'moreFeatures' ? 183 : 275));
      // eslint-disable-next-line no-nested-ternary
      const catName = category === 'industries' ? 'Branche' : (category === 'functionalities' ? 'Funktion' : (category === 'moreFeatures' ? 'Weitere' : 'Funktionsmerkmale'));
      const obj = {
        catName,
        id,
        children: [...children]
      };

      newCatArray.push(obj);
    });
    setCatArray(newCatArray);
    // validation of selected categories
    if (!validateCheckedIndustriesFunctionalities()) {
      const dialogData = {
        title: { text: t('common.error.problem') },
        contentItems: [{ text: t('common.error.selectIndustriesFunctions') }],
        actionItems: [{ text: t('common.button.okUnderstood'), color: 'secondary' }],
      };
      useAppStore.setState({ dialogData });

      return false;
    }

    setPreviewItem({
      id: -1,
      type: 'solution',
      name: '',
      productURL: '',
      logo: currentLogo,
      summary,
      company,
      tree
    });

    return setStep(3);
  };

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('editProducts.whichIndustries.info.header') },
      contentItems: [
        {
          text: t('editProducts.whichIndustries.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleInfoStep2 = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('editProducts.steps.singleProduct.step2.info.header') },
      contentItems: [
        {
          text: t('editProducts.steps.singleProduct.step2.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleInfoLogo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: 'Produktlogo' },
      contentItems: [
        {
          text: 'Laden Sie Ihr Produktlogo hoch. Sollten Sie kein Produktlogo haben wird Ihr Unternehmenslogo verwendet.',
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const customStep : GenericObject = {
    backgroundColor: 'primary.main',
    height: '5px',
    flexGrow: '1',
    opacity: '0.3',
    '&[data-filled=true]': {
      opacity: '1',
    }
  };

  return (
    <Box width="100%">
      <Box sx={{ backgroundColor: 'background.default', width: '100%' }}>
        <Typography variant="h1" fontWeight="bold" marginBottom={3}>
          {`${t('editProducts.singleProduct')} ${currentProductProject ? t('editProducts.edit') : t('editProducts.add')}`}
        </Typography>
        <Box sx={{ minWidth: '100%' }} />
        <Box
          sx={{
            display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridColumnGap: '15px', width: '60%'
          }}
          marginBottom={3}
        >
          <Box sx={customStep} data-filled={step === 1 || step > 1} />
          <Box sx={customStep} data-filled={step === 2 || step > 2} />
          <Box sx={customStep} data-filled={step === 3} />
        </Box>
        {step === 1 && (
          <Typography gutterBottom variant="h5" fontWeight="bold">{`${t('editProducts.step')} 1 ${t('editProducts.of')} 3: ${t('editProducts.steps.singleProduct.step1.header')}`}</Typography>
        )}
        {step === 2 && (
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
        }}
        >
          <Typography variant="h5" fontWeight="bold">{`${t('editProducts.step')} 2 ${t('editProducts.of')} 3: ${t('editProducts.steps.singleProduct.step2.header')}`}</Typography>
          <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfoStep2}><InfoOutlined /></IconButton>
        </div>
        )}
        {step === 3 && (
        <>
          <Typography gutterBottom variant="h5" fontWeight="bold">{`${t('editProducts.step')} 3 ${t('editProducts.of')} 3: ${t('editProducts.steps.singleProduct.step3.header')}`}</Typography>
          <Box sx={{ mt: 4 }}>
            <Typography gutterBottom variant="body1">
              {t('editProducts.steps.singleProduct.step3.paragraph1')}
            </Typography>
          </Box>
        </>
        )}
      </Box>
      {step === 1 && (
      <Paper sx={{
        p: 2, mb: 2, maxHeight: '70vh', overflow: 'auto', width: '100%'
      }}
      >
        <form onSubmit={handleSubmit(onSubmitNameSummary)} style={{ marginTop: '20px' }}>
          <FormInputText
            name="name"
            defaultValue={currentProductProject ? currentProductProject.name : ''}
            control={control}
            ref={register}
            label={t('editProducts.input.name')}
            rules={ruleProductName}
            sx={{ width: '100%', maxWidth: '600px', mb: 4 }}
          />
          <br />
          <FormInputText
            name="summary"
            defaultValue={currentProductProject ? currentProductProject.summary : ''}
            control={control}
            ref={register}
            label={t('editProducts.input.description')}
            multiline
            rows={5}
            maxRows={4}
            rules={ruleProductSummary}
            sx={{ width: '100%', maxWidth: '600px', mb: 4 }}
          />
          <br />
          <FormInputText
            name="productURL"
            defaultValue={currentProductProject ? currentProductProject.url : ''}
            control={control}
            ref={register}
            label={t('editProducts.input.url')}
            rules={ruleCompanyWebsite}
            sx={{ width: '100%', maxWidth: '600px', mb: 4 }}
          />
          <Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" fontWeight="bold">Produktlogo (optional)</Typography>
              <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfoLogo}><InfoOutlined /></IconButton>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
              <Box sx={{
                pr: {
                  lg: 1
                },
                width: {
                  xs: '100%',
                  lg: '40%',
                  xl: '30%'
                }
              }}
              >
                <UploadAndDisplayImage
                  onSaveImage={onSaveProductLogo}
                  onChangeImage={onChangeImage}
                  onDeleteImage={onDeleteImage}
                  currentImage={currentLogo}
                  defaultImage={logoCompany || PlaceholderLogoCompany}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </Paper>
      )}
      {step === 2 && (
      <Paper sx={{
        p: 2, mb: 2, maxHeight: '70vh', overflow: 'auto', width: '100%'
      }}
      >
        <>
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
          }}
          >
            <Typography variant="h6" fontWeight="bold">{t('editProducts.whichIndustries.header')}</Typography>
            <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfo}><InfoOutlined /></IconButton>
          </div>
          <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            {tree.industries.map((item:GenericObject) => (
              <Logos
                key={item.id}
                type="industries"
                item={item}
                handleToggleElement={toggleIndustry}
              />
            ))}
          </Box>
        </>
        <Categories
          tree={tree}
          countCategories={countCategories}
          countMainCategories={countMainCategories}
          isCrossIndustries={isCrossIndustries}
          toggleFilter={toggleFilter}
          toggleCheckbox={toggleCheckbox}
          processSearchTerm={processSearchTerm}
          toggleAllVisibleCategories={toggleAllVisibleCategories}
        />
      </Paper>
      )}
      {step === 3 && (
        <Box sx={{
          pt: 2, mb: 2, minHeight: '70vh', maxHeight: '70vh', overflow: 'auto', width: 'auto'
        }}
        >
          <Card
            key=""
            isDetailView={false}
            combiproductCompanies={undefined}
            status={0}
            owner={false}
            suggestionsCount={0}
            item={undefined}
            direction=""
            offerMessage=""
            offerCreated={0}
            isCombiProduct={false}
            name={previewItem.name}
            type={previewItem.type as CardType}
            logo={previewItem.logo}
            company={previewItem.company}
            summary={previewItem.summary}
            url={previewItem.productURL}
            categories={catArray}
          />
        </Box>
      )}
      {step === 1 && (
      <Box sx={{ position: 'sticky', bottom: 10 }}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          sx={{ mr: 2 }}
        >
          {t('common.button.cancel')}
        </Button>
        <Button type="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmitNameSummary)}>
          {t('common.button.next')}
        </Button>
      </Box>
      )}
      {step === 2 && (
      <Box sx={{ position: 'sticky', bottom: 10 }}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          sx={{ mr: 2 }}
        >
          {t('common.button.cancel')}
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleBack}
          sx={{ mr: 2 }}
        >
          {t('common.button.back')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleGoToLastPage}
        >
          {t('common.button.next')}
        </Button>
      </Box>
      )}
      {step === 3 && (
      <Box sx={{ position: 'sticky', bottom: 10 }}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          sx={{ mr: 2 }}
        >
          {t('common.button.cancel')}
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleBack}
          sx={{ mr: 2 }}
        >
          {t('common.button.edit')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSave}
        >
          {t('common.button.save')}
        </Button>
      </Box>
      )}
    </Box>
  );
};

export default EditSingleProduct;
