import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import dotenv from 'dotenv';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import path from 'path';
import App from './App';

import '@fontsource/source-sans-pro';

// dotenv.config();

const instance = createInstance({
  urlBase: String(process.env.REACT_APP_MATOMO_BASE_URL),
  siteId: Number(process.env.REACT_APP_MATOMO_SIDE_ID),
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
});

ReactDOM.render(
  (
    <MatomoProvider value={instance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MatomoProvider>
  ),
  document.getElementById('root')
);
