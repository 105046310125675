/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import i18n from '../utils/configI18n';

import { processData } from '../utils';

export const ruleFirstname = {
  required: 'Vorname ist erforderlich',
};

export const ruleLastname = {
  required: 'Nachname ist erforderlich',
};

export const rulePhoneNumber = {
  required: 'Eine Telefonnummmer ist erforderlich',
  pattern: {
    value: /([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})/gm,
    message: 'Die Telefonnummer hat ein ungültiges Format'
  }
};

export const ruleCompanyName = {
  required: 'Ein Name ist erforderlich',
};

export const ruleNumberOfEmployees = {
  // required: 'Ein Mitarbeiteranzahl ist erforderlich',
};

export const ruleSales = {
  // required: 'Eine Umsatzzahl ist erforderlich',
};

export const ruleCompanyType = {
  required: 'Ein Unternehmenstyp ist erforderlich',
};

export const ruleCompanyCity = {
  // required: 'Ein Standort ist erforderlich',
};

export const rulesCountry = {
  // required: 'Ein Land ist erforderlich'
};

export const ruleZip = {
  required: 'Eine Postleitzahl ist erforderlich',
  pattern: {
    value: /^[0-9]{4,5}$/,
    message: 'Die Postleitzahl hat ein falsches Format'
  }
};

export const ruleCompanyWebsite = {
  validate: {
    validURL: (value : string) => {
      if (!value) return true;
      const pattern = /\b(?:(?:https):\/\/|www\.)[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*\.[a-z]{2,6}[-a-zA-Z0-9+&@#/%=~_|]/;
      if (!pattern.test(value)) {
        if (!value.startsWith('https')) {
          return 'Die URL muss mit "https://" beginnen';
        }
        return 'Die URL hat ein ungültiges Format';
      }
      return true;
    }
  }
};

export const ruleCompanyDescription = {
  // required: 'Eine Beschreibung ist erforderlich',
};

export const ruleCompanyFoundingYear = {
  validate: async (year: number) => {
    if (year < 1800 || year > new Date().getFullYear()) {
      return 'Gründungsjahr kann nicht vor 1800 oder in der Zukunft liegen';
    }

    return true;
  }
};

export const ruleProductName = {
  required: 'Ein Produktname ist erforderlich',
  maxLength: {
    value: 63,
    message: 'Der Produktname darf maximal 63 Zeichen lang sein',
  },
};

export const ruleProductSummary = {
  required: 'Eine Produktbeschreibung ist erforderlich',
};

export const ruleProjectName = {
  required: 'Ein Gesuchname ist erforderlich',
};

export const ruleProjectSummary = {
  required: 'Eine Gesuchbeschreibung ist erforderlich',
};

export const ruleOfferMessage = {
  required: 'Ein Anfragetext ist erforderlich',
};

export const ruleEmailAddress = {
  required: i18n.t('common.error.emailRequired'),
  pattern: {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: i18n.t('common.error.emailWrongFormat')
  }
};

export const ruleEmailAddressNotExists = {
  ...ruleEmailAddress,
  validate: async (value: string) => {
    const formData = new FormData();
    formData.append('email', value);

    const { error, data } = await processData({ data: formData, url: '/exists-email' });
    if (error) return i18n.t('common.error.api');

    const { exists } = data;

    if (exists) {
      return i18n.t('common.error.emailExists');
    }

    return true;
  }
};

export const ruleCompanyNotExists = {
  required: 'Firmenname ist erforderlich',
  validate: async (value: string) => {
    const formData = new FormData();
    formData.append('companyName', value);

    const { error, data } = await processData({ data: formData, url: '/exists-company' });
    if (error) return i18n.t('common.error.api');

    const { exists } = data;

    if (exists) {
      return 'Firmenname existiert bereits';
    }

    return true;
  }
};

export const rulePassword = {
  validate: async (value: string) => {
    const formData = new FormData();
    formData.append('password', value);

    const { error, data } = await processData({ data: formData, url: '/check-password' });
    if (error) return i18n.t('common.error.api');

    const { code, validation } = data;

    if (code !== 200) {
      let message = '';
      validation.forEach((item: string) => {
        message += (message.length > 0) ? ', ' : '';
        if (item === 'length') message += 'zu kurz';
        if (item === 'upper') message += 'fehlender Großbuchstabe';
        if (item === 'lower') message += 'fehlender Kleinbuchstabe';
        if (item === 'digits') message += 'fehlende Ziffern';
        if (item === 'not_allowed') message += 'unerlaubtes Zeichen';
      });
      return message;
    }

    return true;
  }
};

export const rulePasswordConfirmation = (password: string, confirm: string): any => {
  if (password !== confirm) {
    return 'Passwort-Bestätigung weicht ab';
  }

  return true;
};
