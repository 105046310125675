import { FC, ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router';

import CompanyDetailsMain from '../components/companydetails/CompanyDetailsMain';
import { useProductStore } from '../state';

const CompanyDetails: FC = (): ReactElement => {
  const location = useLocation();
  const state = location.state as { companyId: string};
  // load all important data from api to display it
  useEffect(() => {
    useProductStore.getState().getCompanyData(state.companyId);
  }, []);

  return (
    <CompanyDetailsMain />
  );
};

export default CompanyDetails;
