import { Typography } from '@mui/material';

const NavigationTour: TourStep[] = [
  {
    id: 'step1',
    content:
  <Typography>
    Hier können Sie Ihr Unternehmensprofil erstellen und bearbeiten.
    Fügen Sie wichtige Informationen zu Ihrem Unternehmen hinzu, damit andere IT-Unternehmen Sie besser kennenlernen können.
  </Typography>,
    targetElementId: 'nav_profile',
    arrowPosition: 'left',
    skip: false
  },
  {
    id: 'step1',
    content:
  <Typography>
    Der Startpunkt führt Sie zur Übersichtsseite, wo Sie alle relevanten Informationen zu Ihrer Plattform finden.
    Hier können Sie sich orientieren und direkt zu anderen Menüpunkten navigieren.
  </Typography>,
    targetElementId: 'nav_start',
    arrowPosition: 'left',
    skip: false
  },
  {
    id: 'step2',
    content:
  <Typography>
    Entdecken Sie passende IT-Produkte anderer Unternehmen, die zu Ihren Bedürfnissen und Angeboten passen könnten.
    Das Matching-Feature erleichtert die Suche nach potenziellen Kooperationspartnern.
  </Typography>,
    targetElementId: 'nav_matching',
    arrowPosition: 'left',
    skip: false
  },
  {
    id: 'step3',
    content:
  <Typography>
    Verwenden Sie die Suchfunktion, um gezielt nach bestimmten IT-Produkten oder Unternehmen zu suchen.
    Dies erleichtert Ihnen die Navigation und ermöglicht eine effiziente Suche nach relevanten Partnern.
  </Typography>,
    targetElementId: 'nav_search',
    arrowPosition: 'left',
    skip: false
  },
  {
    id: 'step4',
    content:
  <Typography>
    Hier können Sie Ihre eigenen IT-Produkte detailliert präsentieren.
    Fügen Sie Beschreibungen und relevante Informationen hinzu, um andere Unternehmen von Ihren Angeboten zu überzeugen.
  </Typography>,
    targetElementId: 'nav_portfolio',
    arrowPosition: 'left',
    skip: false
  },
  {
    id: 'step4',
    content:
  <Typography>
    Wenn Sie nach spezifischen IT-Produkten suchen, können Sie hier entsprechende Anfragen erstellen.
    Andere Unternehmen können dann darauf reagieren und passende Produkte anbieten.
  </Typography>,
    targetElementId: 'nav_projects',
    arrowPosition: 'left',
    skip: false
  },
  {
    id: 'step4',
    content:
  <Typography>
    Verwalten Sie hier alle eingehenden und ausgehenden Anfragen von anderen Unternehmen.
    Dieser Bereich dient dazu, die Kommunikation und Koordination mit potenziellen Partnern zu erleichtern.
  </Typography>,
    targetElementId: 'nav_requests',
    arrowPosition: 'left',
    skip: false
  },
  {
    id: 'step4',
    content:
  <Typography>
    Fügen Sie interessante Unternehmen oder Produkte zu Ihrer Merkliste hinzu,
    um sie später leicht wiederzufinden oder näher zu untersuchen.
  </Typography>,
    targetElementId: 'nav_bookmarks',
    arrowPosition: 'left',
    skip: false
  },
  {
    id: 'step4',
    content:
  <Typography>
    In diesem Bereich finden Sie alle bestätigten Anfragen von anderen Unternehmen.
    Hier können Sie die Unternehmen und deren Produkte sehen, die zu Ihren Anforderungen passen und mit denen eine gegenseitige Interessenbekundung besteht.
    Diese Übersicht erleichtert Ihnen den Überblick über potenzielle Partnerschaften und ermöglicht eine effiziente Kommunikation mit bereits bestätigten Matching-Partnern.
  </Typography>,
    targetElementId: 'nav_matches',
    arrowPosition: 'left',
    skip: false
  },
  {
    id: 'step4',
    content: <Typography>Dieser Bereich dient zur Verwaltung abgelehnter Anfragen von anderen Unternehmen.</Typography>,
    targetElementId: 'nav_blacklist',
    arrowPosition: 'left',
    skip: false
  },
];

const PortfolioTour: TourStep[] = [
  {
    id: 'step1',
    content:
  <Typography>
    Hier haben Sie die Möglichkeit, ein neues Einzelprodukt für Ihr Unternehmen zu erstellen.
    Fügen Sie detaillierte Informationen zu diesem Produkt hinzu, wie Beschreibung und Eigenschaften, um es optimal zu präsentieren.
    Dies ermöglicht anderen Unternehmen, Ihr Produkt besser zu verstehen und erleichtert das Matching.
  </Typography>,
    targetElementId: 'btn_singleProduct',
    arrowPosition: 'top',
    skip: false
  },
  {
    id: 'step2',
    content:
  <Typography>
    Erstellen Sie hier ein neues Kombiprodukt, indem Sie es mit bereits vorhandenen Einzelprodukten, die unter &quot;Matches&quot; zu finden sind, kombinieren.
    Dieser Schritt ermöglicht es Ihnen, maßgeschneiderte Lösungen für andere Unternehmen zu schaffen, indem Sie Ihre Einzelprodukte geschickt kombinieren.
    Stellen Sie sicher, dass alle relevanten Informationen eingetragen sind, um eine effektive Präsentation zu gewährleisten.
  </Typography>,
    targetElementId: 'btn_combiProduct',
    arrowPosition: 'top',
    skip: false
  },
  {
    id: 'step3', content: <Typography>bottom</Typography>, targetElementId: 'btn_singleProduct', arrowPosition: 'bottom', skip: false
  },
  {
    id: 'step4', content: <Typography>right</Typography>, targetElementId: 'btn_singleProduct', arrowPosition: 'right', skip: false
  },
];

export {
  NavigationTour,
  PortfolioTour
};
