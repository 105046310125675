import { ReactElement } from 'react';
import { Box, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const CloseX = ({
  onClose
}: GenericObject):ReactElement => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Box position="absolute" top={0} right={10}>
      <IconButton sx={{ color: 'text.primary' }} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>

  );
};

export default CloseX;
