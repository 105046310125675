import { FC, ReactElement, useEffect } from 'react';

import { useProductStore, useUserStore } from '../state';

import Overview from '../components/layout/Overview';

const LayoutOverview: FC = (): ReactElement => {
  // load all important data from api to display it on the startpage
  useEffect(() => {
    useProductStore.getState().prodGetCategories();
    useUserStore.getState().userGetAccountData()
      .then(() => useProductStore.getState().prodGetNetworks());
  }, []);

  return (
    <Overview />
  );
};

export default LayoutOverview;
