import { FC, ReactElement, useEffect } from 'react';

import { useProductStore, useUserStore } from '../state';

import RequestsMain from '../components/requests/RequestsMain';

const Requests: FC = (): ReactElement => {
  // load all important data from api to display it on the startpage
  useEffect(() => {
    // for Reuests page we need all solutions, combiProducts and projects from this user. After fetching,
    // we need to loop through this array to get the corresponding offers
    // sadly we have to call the API again for every bookmark to get the company logo and contact person data
    // first we have to wait until the Promise from userGetAccountData() is resolve since we need data from there
    // then all the magic happens in prodGetOffers() :-)
    useUserStore.getState().userGetAccountData()
      .then(() => useProductStore.getState().prodGetOffers());
  }, []);

  return (
    <RequestsMain />
  );
};

export default Requests;
