/* eslint-disable react/jsx-props-no-spreading */
import {
  FC, ReactElement, useState, useEffect
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Button, Grid, Tab, Pagination, Tabs, IconButton, ButtonGroup, Paper
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import RequestCard from '../ui/Card';

import { useAppStore, usePaginationStore, useProductStore } from '../../state';
import SkeletonCard from '../ui/SkeletonCard';

interface TabPanelProps {
  value: number,
  index: number
}

const RequestsTabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <Box
    role="tabpanel"
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    /* eslint-disable react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && (
      <Box sx={{ pt: 3 }}>
        {children}
      </Box>
    )}
  </Box>
);

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

const RequestsMain: FC = (): ReactElement => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('all');
  const [incomingOffers, setIncomingOffers] = useState([]);
  const [outgoingOffers, setOutgoingOffers] = useState([]);
  const [filteredLists, setFilteredLists] = useState<FilteredLists>({
    solutions: [],
    projects: [],
    combiProducts: [],
  });
  const routes = ['app/requests/incoming', 'app/requests/outgoing'];
  const routeMatch = useRouteMatch(routes);
  const currentTab = routeMatch?.pattern?.path;
  const [value, setValue] = useState<number>(0);
  const { showSkeleton } = useAppStore((state:GenericObject) => state);

  const {
    offers,
    combiProducts,
    offersCombiProducts,
    prodConfirmOffer,
    prodDeclineOffer,
    prodWithdrawnOffer,
    prodDeclineCombiProduct,
    prodConfirmCombiProduct,
    prodSetOfferStatus
  } = useProductStore((state: GenericObject) => state);

  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
  } = usePaginationStore();

  // calculate data new if value of the tab or filter is changing
  useEffect(() => {
    const offersCombiOutgoing = combiProducts
      .filter((item: any) => item.status === 10)
      .map((item: any) => {
        const { company } = item.combiproductCompanies[0];
        const contactPerson = company.contactPersons[0];
        const newCompany = { ...company, contactPerson };

        const obj = {
          ...item,
          item,
          company: newCompany,
          contactPerson,
          direction: 'outgoing'
        };
        return obj;
      });

    const offersCombiIncoming = offersCombiProducts
      // .filter((item: any) => item.status === 10)
      .map((item: any) => {
        const { company } = item.combiproductCompanies[0];
        const contactPerson = company.contactPersons[0];
        const newCompany = { ...company, contactPerson };

        const obj = {
          ...item,
          item,
          company: newCompany,
          contactPerson,
          direction: 'incoming'
        };
        return obj;
      });

    const outOffers:any = [...offers.filter((item: any) => item.direction === 'outgoing'), ...offersCombiOutgoing];
    const inOffers:any = [...offers.filter((item: any) => item.direction === 'incoming'), ...offersCombiIncoming];

    setIncomingOffers(inOffers);
    setOutgoingOffers(outOffers);

    // eslint-disable-next-line no-nested-ternary
    const copyData = currentTab === undefined ? [...inOffers] : currentTab === routes[0] ? [...inOffers] : [...outOffers];

    const solutionData: any = copyData.filter((item : any) => item.type === 'solution');
    const combiProductData: any = copyData.filter((item : any) => item.type === 'combiProduct');
    const projectData: any = copyData.filter((item : any) => item.type === 'project');
    setFilteredLists(({
      solutions: solutionData,
      projects: projectData,
      combiProducts: combiProductData,
    }));
    console.log(currentTab);

    if (filter === 'all') init(copyData, 6, 1);
    if (filter === 'solution') init(solutionData, 6, 1);
    if (filter === 'combiProduct') init(combiProductData, 6, 1);
    if (filter === 'project') init(projectData, 6, 1);
  }, [currentTab, filter, offers.length, combiProducts.length]);

  useEffect(() => {
    console.log('reload');
  }, [offers]);

  const handleRead = async (id: number, prevStatus: number, direction: string) => {
    if (prevStatus === 10 && direction === 'incoming') {
      const success : boolean = await prodSetOfferStatus({ id, status: 20 });

      if (success) {
        await useProductStore.setState((prevState: any) => ({
          ...prevState,
          offers: prevState.offers.map((offer : any) => (offer.id === id ? { ...offer, status: 20 } : offer))
        }));
      }
    }
  };

  const handleUnRead = async (id: number, prevStatus: number) => {
    if (prevStatus === 20) {
      const success : boolean = await prodSetOfferStatus({ id, status: 10 });

      if (success) {
        await useProductStore.setState((prevState: any) => ({
          ...prevState,
          offers: prevState.offers.map((offer : any) => (offer.id === id ? { ...offer, status: 10 } : offer))
        }));
      }
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setFilter('all');
  };
  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('requests.info.header') },
      contentItems: [
        {
          text: t('requests.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  return (
    <Box>
      <Box style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
      }}
      >
        <Typography
          variant="h1"
          fontWeight="bold"
        >
          {t('requests.header')}
        </Typography>
        <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfo}><InfoOutlined /></IconButton>
      </Box>
      <Tabs
        textColor="inherit"
        indicatorColor="secondary"
        value={value}
        onChange={handleChangeTab}
        sx={{ mb: 3 }}
      >
        <Tab component={Link} to="incoming" value={0} sx={{ px: 0, mr: 2 }} label={`${t('requests.incoming')} (${incomingOffers.length})`} {...a11yProps} />
        <Tab component={Link} to="outgoing" value={1} sx={{ px: 0, mr: 2 }} label={`${t('requests.outgoing')} (${outgoingOffers.length})`} {...a11yProps} />
      </Tabs>
      <RequestsTabPanel value={value} index={0}>
        <Paper sx={{
          display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'start', py: 1, pl: 1, alignContent: 'center', flexDirection: 'row'
        }}
        >
          <Box>
            <Typography>Kategorie</Typography>
            <ButtonGroup>
              <Button
                variant={filter === 'all' ? 'contained' : 'contained'}
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === 'all' ? '1' : '0.6' }}
                onClick={() => setFilter('all')}
              >
                { `${t('matching.filter.all')} ` }
                (
                {currentTab === routes[0] ? incomingOffers.length : outgoingOffers.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === 'solution' ? '1' : '0.6' }}
                onClick={() => setFilter('solution')}
              >
                { `${t('matching.filter.singleProducts')} ` }
                (
                {filteredLists.solutions?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === 'combiProduct' ? '1' : '0.6' }}
                onClick={() => setFilter('combiProduct')}
              >
                { `${t('matching.filter.combiProducts')} ` }
                (
                {filteredLists.combiProducts?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, mr: 2, px: 2, py: 0.7
                }}
                style={{ opacity: filter === 'project' ? '1' : '0.6' }}
                onClick={() => setFilter('project')}
              >
                { `${t('matching.filter.requests')} ` }
                (
                {filteredLists.projects?.length}
                )
              </Button>
            </ButtonGroup>
          </Box>
        </Paper>
        {showSkeleton ? (
          <Grid container sx={{ pt: 2 }} spacing={{ xs: 3, sm: 4, md: 5 }}>
            { [...Array(currentTab === routes[0] ? incomingOffers.length : outgoingOffers.length)].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            sx={{ pt: 2 }}
            spacing={{
              xs: 3, sm: 4, md: 5
            }}
          >
            {paginatedData && paginatedData.map((data: any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <RequestCard
                  key={`card_${data.type}_${data.id}`}
                  handleConfirmOffer={() => prodConfirmOffer(data.id)}
                  handleDeclineOffer={() => prodDeclineOffer(data.id)}
                  handleWithdrawOffer={() => prodWithdrawnOffer(data.id)}
                  handleDeclineCombiProduct={() => prodDeclineCombiProduct(data.id)}
                  handleConfirmCombiProduct={() => prodConfirmCombiProduct(data.id)}
                  handleUnread={async () => handleUnRead(data.id, data.status)}
                  handleRead={() => handleRead(data.id, data.status, data.direction)}
                  company={data?.item?.company}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <Box sx={{ pt: 7, clear: 'both' }}>
          {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
        </Box>
      </RequestsTabPanel>
      <RequestsTabPanel value={value} index={1}>
        <Paper sx={{
          display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'start', py: 1, pl: 1, alignContent: 'center', flexDirection: 'row'
        }}
        >
          <Box>
            <Typography>Kategorie</Typography>
            <ButtonGroup>
              <Button
                variant={filter === 'all' ? 'contained' : 'contained'}
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === 'all' ? '1' : '0.6' }}
                onClick={() => setFilter('all')}
              >
                { `${t('matching.filter.all')} ` }
                (
                {currentTab === routes[0] ? incomingOffers.length : outgoingOffers.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === 'solution' ? '1' : '0.6' }}
                onClick={() => setFilter('solution')}
              >
                { `${t('matching.filter.singleProducts')} ` }
                (
                {filteredLists.solutions?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
                style={{ opacity: filter === 'combiProduct' ? '1' : '0.6' }}
                onClick={() => setFilter('combiProduct')}
              >
                { `${t('matching.filter.combiProducts')} ` }
                (
                {filteredLists.combiProducts?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, mr: 2, px: 2, py: 0.7
                }}
                style={{ opacity: filter === 'project' ? '1' : '0.6' }}
                onClick={() => setFilter('project')}
              >
                { `${t('matching.filter.requests')} ` }
                (
                {filteredLists.projects?.length}
                )
              </Button>
            </ButtonGroup>
          </Box>
        </Paper>
        {showSkeleton ? (
          <Grid container sx={{ pt: 2 }} spacing={{ xs: 3, sm: 4, md: 5 }}>
            { [...Array(currentTab === routes[0] ? incomingOffers.length : outgoingOffers.length)].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            sx={{ pt: 2 }}
            spacing={{
              xs: 3, sm: 4, md: 5
            }}
          >
            {paginatedData && paginatedData.map((data: any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <RequestCard
                  key={`card_${data.type}_${data.id}`}
                  handleConfirmOffer={() => prodConfirmOffer(data.id)}
                  handleDeclineOffer={() => prodDeclineOffer(data.id)}
                  handleWithdrawOffer={() => prodWithdrawnOffer(data.id)}
                  handleDeclineCombiProduct={() => prodDeclineCombiProduct(data.id)}
                  handleConfirmCombiProduct={() => prodConfirmCombiProduct(data.id)}
                  handleUnread={async () => handleUnRead(data.id, data.status)}
                  handleRead={() => handleRead(data.id, data.status, data.direction)}
                  company={data?.item?.company}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <Box sx={{ pt: 7, clear: 'both' }}>
          {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
        </Box>
      </RequestsTabPanel>
    </Box>
  );
};
export default RequestsMain;
