/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Controller } from 'react-hook-form';
import {
  FormHelperText, InputLabel, MenuItem, FormControl, Select
} from '@mui/material';

const FormSelect = ({
  name,
  control,
  label,
  defaultValue,
  data,
  rules = {},
  multiple = false,
  fullWidth = false,
  required = false
}: FormInputProps) => (
  <>
    {data && (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth={fullWidth}>
          <InputLabel error={!!error} id={name}>{label}</InputLabel>
          <Select
            id={name}
            label={label}
            defaultValue={defaultValue}
            multiple={multiple}
            value={value ?? ''}
            error={!!error}
            onChange={onChange}
            required={required}
          >
            <MenuItem value="">
              Keine Auswahl
            </MenuItem>
            {data.map((item:GenericObject) => (
              <MenuItem
                value={item.id}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText id={name} error>{error ? error.message : null}</FormHelperText>
        </FormControl>
      )}
      rules={rules}
    />
    )}
  </>

);
export default FormSelect;
