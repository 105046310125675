/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-nested-ternary
import {
  ReactElement
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControlLabel,
  Checkbox
} from '@mui/material';

const SearchCheckboxes = (props:GenericObject):ReactElement => {
  const { t } = useTranslation();

  const { data, handleCheckbox } = props;

  return (
    <>
      {
        data.map((item:GenericObject) => (
          <FormControlLabel
            key={item.id}
            sx={{
              width: {
                xs: '100%',
                sm: '48%',
                md: '32%'
              },
              mb: 0.2
            }}
            control={<Checkbox id={item.id} checked={item.checked} onChange={() => handleCheckbox(item.id)} />}
            label={(
              <>
                <Box component="div" fontSize={15}>
                  {item.name}
                </Box>
                <Box component="div" fontSize={12}>
                  {item.type === 'solution' ? t('card.type.singleproduct') : (item.type === 'combiProduct' ? t('card.type.combiproduct') : t('card.type.request'))}
                </Box>
              </>
            )}
          />
        ))
      }
    </>
  );
};

export default SearchCheckboxes;
