import {
  FC, ReactElement, useState, useEffect
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Button, Grid, Pagination, IconButton, ButtonGroup, Paper
} from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';
import BlacklistCard from '../ui/Card';
import { useAppStore, usePaginationStore, useProductStore } from '../../state';
import SkeletonCard from '../ui/SkeletonCard';
import BlacklistLinkupDialog from './BlacklistLinkupDialog';

const BlacklistMain: FC = (): ReactElement => {
  const { t } = useTranslation();
  const { blacklists, prodBookmarkBlacklisted } = useProductStore((state: GenericObject) => state);
  const { showSkeleton } = useAppStore((state:GenericObject) => state);
  /* eslint-disable react/jsx-props-no-spreading */

  const filterAll = 'all';
  const filterSolution = 'solution';
  const filterProject = 'project';
  const filterCombiProduct = 'combiProduct';

  const [filter, setFilter] = useState('all');
  const [solution, setSolution] = useState([]);
  const [project, setProject] = useState([]);
  const [combiProduct, setCombiProduct] = useState([]);
  const [linkupItem, setLinkupItem] = useState({});
  const [productName, setProductName] = useState('');
  const [openDialogLinkup, setOpenDialogLinkup] = useState(false);
  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
  } = usePaginationStore();

  const getFilterValue = () => {
    if (blacklists?.length > 0) {
      const solutionData: any = blacklists.filter((item : any) => item.type === 'solution');
      const projectData: any = blacklists.filter((item : any) => item.type === 'project');
      const combiProductData: any = blacklists.filter((item : any) => item.type === 'combiProduct');
      setSolution(solutionData);
      setProject(projectData);
      setCombiProduct(combiProductData);
    } else {
      setSolution([]);
      setProject([]);
      setCombiProduct([]);
    }
  };

  useEffect(() => {
    init(blacklists, 6, 1);
    getFilterValue();
  }, [blacklists.length]);

  const filterChange = (newValue: any) => {
    setFilter(newValue);

    if (newValue !== filterAll) {
      const filtered: any = blacklists.filter((item : any) => item.type === newValue);
      init(filtered, 6, 1);
    } else {
      init(blacklists, 6, 1);
    }
  };

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('blacklist.info.header') },
      contentItems: [
        {
          text: t('blacklist.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleLinkup = (data: any, name: string) => {
    setLinkupItem(data);
    setProductName(name);
    setOpenDialogLinkup(!openDialogLinkup);
  };

  return (
    <>
      <BlacklistLinkupDialog
        open={openDialogLinkup}
        onClose={() => setOpenDialogLinkup(false)}
        item={linkupItem}
        productname={productName}
      />
      <Box>
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12
        }}
        >
          <Typography variant="h1" fontWeight="bold">
            {t('blacklist.header')}
          </Typography>
          <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfo}><InfoOutlined /></IconButton>
        </div>
        <Paper sx={{
          display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'start', py: 1, pl: 1, alignContent: 'center', flexDirection: 'row'
        }}
        >
          <Box>
            <Typography>Kategorie</Typography>
            <ButtonGroup>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 2, py: 0.7
                }}
                style={{ opacity: filter === filterAll ? '1' : '0.6' }}
                onClick={() => filterChange(filterAll)}
              >
                {`${t('matching.filter.all')} `}
                (
                {blacklists?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 2, py: 0.7
                }}
                style={{ opacity: filter === filterSolution ? '1' : '0.6' }}
                onClick={() => filterChange(filterSolution)}
              >
                {`${t('matching.filter.singleProducts')} `}
                (
                {solution?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 2, py: 0.7
                }}
                style={{ opacity: filter === filterCombiProduct ? '1' : '0.6' }}
                onClick={() => filterChange(filterCombiProduct)}
              >
                {`${t('matching.filter.combiProducts')} `}
                (
                {project?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 2, py: 0.7, mr: 1
                }}
                style={{ opacity: filter === filterProject ? '1' : '0.6' }}
                onClick={() => filterChange(filterProject)}
              >
                {`${t('matching.filter.requests')} `}
                (
                {combiProduct?.length}
                )
              </Button>
            </ButtonGroup>
          </Box>
        </Paper>
        {showSkeleton ? (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            { [...Array(Math.min(6, blacklists.length))].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            {paginatedData && paginatedData.map((data: any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <BlacklistCard
                  key={`card_${data.type}_${data.id}`}
                  handleLinkupBlacklisted={() => handleLinkup(data, data.item.name)}
                  handleBookmarkBlacklisted={() => prodBookmarkBlacklisted(data.id)}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <Box sx={{ pt: 3, clear: 'both' }}>
          {countItems === 0 && <Typography variant="h6">{t('common.text.noData')}</Typography>}
          {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
        </Box>
      </Box>
    </>
  );
};
export default BlacklistMain;
