/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Avatar,
  Box, Button, Grid, Pagination, Paper, Typography
} from '@mui/material';
import {
  FC, ReactElement, useState, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router';
import {
  useAppStore, usePaginationStore, useProductStore
} from '../../state';
import PlaceholderLogoPerson from '../../assets/images/placeholder_person.png';
import { getYearFromUnixTimestamp } from '../../utils';
import PlaceholderImage from '../../assets/images/placeholder_company.png';
import CompanyDetailsSkeleton from './CompanyDetailsSkeleton';
import Card from '../ui/Card';

const CompanyDetailsMain: FC = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { showSkeleton } = useAppStore((state: GenericObject) => state);

  const {
    company
  } = useProductStore((state: GenericObject) => state);

  const [showFullDescription, setShowFullDescription] = useState(false);
  if (!company) return <></>;

  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
  } = usePaginationStore((state:GenericObject) => state);

  useEffect(() => {
    if (company) {
      init([...company?.solutions || [], ...company?.combiproducts?.own || [], ...company?.combiproducts?.foreign || [], ...company?.projects || []], 6, 1);
    }
  }, [company]);

  const handleToggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        sx={{
          mb: 1, px: 1.5, py: 0.7
        }}
        startIcon={<ChevronLeftIcon />}
      >
        {t('common.button.back')}
      </Button>
      {showSkeleton && (
        <CompanyDetailsSkeleton />
      )}
      {!showSkeleton && (
      <>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Paper sx={{ p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} pb={3}>
                <Avatar src={company?.logo ?? PlaceholderImage} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
                <Box sx={{ ml: 2 }}>
                  <Typography variant="h4" fontWeight="bold">
                    {company?.name}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ color: 'text.secondary' }}>{company?.companyType?.label}</Typography>
                  </Box>
                  {company?.addresses && (
                  <Typography sx={{ color: 'text.secondary' }}>
                    {company?.addresses[0]?.contactInfo?.homepage && (
                      <Typography sx={{ color: 'text.secondary' }} component="a" href={company.addresses[0]?.contactInfo?.homepage} target="_blank" rel="noreferrer">
                        {company?.addresses && company.addresses[0]?.contactInfo?.homepage}
                      </Typography>
                    )}
                    {(company?.addresses[0]?.contactInfo?.homepage && company?.addresses[0]?.contactInfo?.email) && (' | ')}
                    <Typography sx={{ color: 'text.secondary' }} component="a" href={`mailto:${company?.addresses && company?.addresses[0]?.contactInfo?.email}`}>
                      {company?.addresses && company?.addresses[0]?.contactInfo?.email}
                    </Typography>
                    {(company?.addresses[0]?.contactInfo?.homepage && company?.addresses[0]?.contactInfo?.phoneNumber1) && (' | ')}
                    {company?.addresses[0]?.contactInfo?.phoneNumber1}
                  </Typography>
                  )}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', pb: 3 }}>
                <Typography>
                  {showFullDescription ? company?.description : `${company?.description?.slice(0, 300)}...`}
                  {company?.description?.length > 300 && (
                    <Button variant="text" size="small" onClick={handleToggleDescription}>
                      {showFullDescription ? 'Weniger anzeigen' : 'Mehr anzeigen'}
                    </Button>
                  )}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* Anschrift */}
                <Box sx={{
                  display: 'flex', flexDirection: 'column', alignItems: 'start', pb: 1, pr: 5
                }}
                >
                  <Typography variant="h6" fontWeight="bold">
                    Adresse
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {company?.addresses && (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography>
                          {company?.addresses[0]?.street}
                          {' '}
                          {company?.addresses[0]?.housenumber}
                        </Typography>
                        <Typography>
                          {company?.addresses[0]?.zip}
                          {' '}
                          {company?.addresses[0]?.city}
                        </Typography>
                        <Typography>
                          {company?.addresses && company?.addresses[0]?.country}
                          ,
                          {' '}
                          {company?.addresses && company?.addresses[0]?.state}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
                {/* Company Information */}
                <Box sx={{ pb: 1, pr: 5 }}>
                  <Typography variant="h6" fontWeight="bold">
                    weitere Informationen
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    {company?.numberOfEmployees?.label && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>
                        {company?.numberOfEmployees?.label}
                        {' '}
                        Mitarbeitende
                      </Typography>
                    </Box>
                    )}
                    {company?.sales?.label && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>
                        {company?.sales?.label}
                        {' '}
                        € Umsatz
                      </Typography>
                    </Box>
                    )}
                    {company?.foundingTime && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>
                        Gegründet in
                        {' '}
                        {getYearFromUnixTimestamp(company?.foundingTime)}
                      </Typography>
                    </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            {company?.contactPersons && (
              <Paper sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} pb={5}>
                  <Avatar src={(company?.contactPersons[0]?.contactPersonImage) ?? PlaceholderLogoPerson} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
                  <Box>
                    <Typography variant="h4" fontWeight="bold" marginLeft={2}>
                      {company?.contactPersons[0]?.firstname.concat(' ', company?.contactPersons[0]?.lastname)}
                    </Typography>
                    <Box sx={{ display: 'flex', ml: 2, alignItems: 'center' }}>
                      <Typography sx={{ color: 'text.secondary' }}>{company?.contactPersons[0]?.jobTitle}</Typography>
                    </Box>
                    {company?.contactPersons && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', ml: 2 }}>
                      <Typography sx={{ color: 'text.secondary' }} component="a" href={`mailto:${company?.contactPersons && company?.contactPersons[0]?.email}`}>
                        {company?.contactPersons && company?.contactPersons[0]?.email}
                      </Typography>
                      <Typography style={{ whiteSpace: 'pre-wrap' }} sx={{ color: 'text.secondary' }}>
                        {(company?.contactPersons[0]?.email && company?.contactPersons[0].phoneNumber) && (' | ')}
                        {company?.contactPersons[0]?.phoneNumber}
                      </Typography>
                    </Box>
                    )}
                  </Box>
                </Box>
              </Paper>
            )}
          </Grid>
        </Grid>
        <Box sx={{ mt: 5 }}>
          {/* <h2>
            {`Alle Produkte von ${company?.name}`}
          </h2> */}
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }}>
            {paginatedData && paginatedData.map((data: any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <Card
                  key={`card_${data.type}_${data.id}`}
                  item={data}
                  logo={company?.logo}
                  company={company}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ pt: 3, clear: 'both' }}>
            {countItems === 0 && <Typography variant="h6">{t('common.text.noData')}</Typography>}
            {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
          </Box>
        </Box>
      </>
      )}
    </>

  );
};

export default CompanyDetailsMain;
