import create from 'zustand';
// import { devtools } from 'zustand/middleware';

import { logger } from './middleware';

import { processData } from '../utils';

const initialState: any = {
  alert: null,
  dialogData: null,
  tourData: null,
  showEventNotification: false,
  discardEventNotification: false,
  showOnboarding: false,
  showCompareDialog: false,
  showResetPassword: false,
  showResendOptIn: false,
  showLoading: false,
  showSkeleton: false,
  dataEventNotification: null,
  drawerIsOpened: true,
  darkMode: false,
  selections: [],
  posts: null,
  categoriesLoaded: false,
  accountDataLoaded: false,
};

const CTA_EVENT_URL = process.env.REACT_APP_CTA_EVENT_URL;

const BITMI_POSTS_URL = process.env.REACT_APP_BITMI_POSTS_URL;

const store = (set:any, get:any) => ({
  ...initialState,

  appGetEventNotification: () => {
    processData({ method: 'get', url: CTA_EVENT_URL })
      .then((response) => {
        const { error, data } = response;
        if (error) return false;

        const dataEventNotification = JSON.parse(data);

        // check if event notification id is discarded in localeStorage
        const idLocked = localStorage.getItem('hiddenEventNotificationId');
        const { id } = dataEventNotification;
        const showEventNotification = idLocked !== id;
        const discardEventNotification = idLocked === id;

        set({ showEventNotification, discardEventNotification, dataEventNotification });
        return true;
      });
  },

  appToggleEventNotification: () => {
    const showEventNotification = !get().showEventNotification;
    let { discardEventNotification } = get();

    if (showEventNotification) {
      localStorage.removeItem('hiddenEventNotificationId');
      discardEventNotification = false;
    }

    set({ showEventNotification, discardEventNotification });
  },

  appDiscardEventNotification: () => {
    localStorage.setItem('hiddenEventNotificationId', get().dataEventNotification.id);
    set({ showEventNotification: false, discardEventNotification: true });
  },

  appResetAlert: () => set({ alert: null }),

  appSetAlert: (alert:GenericObject) => set({ alert }),

  appResetAll: () => set({ ...initialState }),

  appSetShowOnboarding: (showOnboarding:boolean) => set({ showOnboarding }),

  appSetShowCompareDialog: (showCompareDialog: boolean) => set({ showCompareDialog }),

  appSetShowResetPassword: (showResetPassword:boolean) => set({ showResetPassword }),

  appSetShowResendOptIn: (showResendOptIn:boolean) => set({ showResendOptIn }),

  appSetShowLoading: (showLoading:boolean) => set({ showLoading }),

  appToggleDrawerIsOpened: (opened:boolean) => set({ drawerIsOpened: opened }),

  appGetSelections: () => {
    set({ showLoading: true });
    processData({ url: '/get-selections' })
      .then((response) => {
        const { error, data } = response;
        if (error) return false && set({ showLoading: false });

        if (data.code === 200) {
          return set({ selections: data, showLoading: false });
        }

        set({
          alert: {
            error: true, severity: 'error', text: 'Fehler beim Laden der Selektionen.'
          }
        });
        return false && set({ showLoading: false });
      });
  },

  appSwitchTheme: (isDarkMode:boolean) => {
    set({ darkMode: isDarkMode });
    localStorage.setItem('darkMode', isDarkMode.toString());
  },

  appGetPosts: () => {
    processData({ method: 'get', url: BITMI_POSTS_URL })
      .then((response) => {
        const { error, data } = response;
        if (error) {
          console.log('Fehler beim holen der Post Informationen: ', error);
          return false;
        }

        set({ posts: data });
        return true;
      });
  }

});

// const useAppStore = process.env.NODE_ENV !== 'production' ? create(devtools(store)) : create(store);
// export default useAppStore;

const useAppStore = create(logger(store));
export default useAppStore;
