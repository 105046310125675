/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Box, Grid, Paper, Typography
} from '@mui/material';
import { useFieldArray, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInputText from '../ui/FormInputText';
import FormSelect from '../ui/FormSelect';
import UploadAndDisplayImage from '../ui/UploadAndDisplayImage';
import { useUserStore, useAppStore } from '../../state';
import PlaceholderLogoCompany from '../../assets/images/placeholder_company.png';
import FormAutocompleteSelect from '../ui/FormAutocompleteSelect';
import {
  ruleCompanyName,
  ruleCompanyCity,
  ruleCompanyWebsite,
  ruleCompanyDescription,
  ruleCompanyFoundingYear,
  ruleCompanyType,
  ruleNumberOfEmployees,
  ruleSales,
  rulesCountry,
  ruleZip,
  ruleEmailAddress
} from '../../rules';

type CompanyForm = {
    data: Company;
    register: UseFormRegister<any>;
    control: any;
    id: string;
}

const states = [
  'Baden-Württemberg',
  'Bayern',
  'Berlin',
  'Brandenburg',
  'Bremen',
  'Hamburg',
  'Hessen',
  'Mecklenburg-Vorpommern',
  'Niedersachsen',
  'Nordrhein-Westfalen',
  'Rheinland-Pfalz',
  'Saarland',
  'Sachsen',
  'Sachsen-Anhalt',
  'Schleswig-Holstein',
  'Thüringen',
];

const countrys = [
  'Deutschland',
  'Österreich',
  'Schweiz',
];

const CompanyProfileForm = ({
  data, control, register
}: CompanyForm) => {
  const { t } = useTranslation();
  const user = useUserStore((state:GenericObject) => state);
  const selections = useAppStore((state:GenericObject) => state.selections);
  // Processing of data and images
  const onSaveCompanyLogo = (imageData:string) => user.userEditCompanyLogo(imageData);
  // we dont delete the image. instead of, we send the placeholder image as string
  const onDeleteCompanyLogo = () => user.userEditCompanyLogo('');

  return (
    <>
      <Box
        sx={{
          display: 'flex', flexWrap: 'wrap', pt: 2, pb: 1
        }}
      >
        <form style={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 6 }}>
            <Box
              sx={{
                pl: {
                  lg: 2
                },
                width: {
                  xs: '100%',
                  lg: '60%',
                  xl: '70%'
                }
              }}
            >
              <Grid
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 1,
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)'
                  },
                  gridTemplateRows: {
                    xs: 'repeat(1, 1fr)',
                    md: 'repeat(1, 1fr)'
                  },
                }}
              >
                <FormInputText
                  name="name"
                  defaultValue={data?.name}
                  control={control}
                  ref={register('name')}
                  label={t('common.input.companyName')}
                  rules={ruleCompanyName}
                  required
                />
                <FormSelect
                  name="companyType.id"
                  data={selections.companyType}
                  defaultValue={data?.companyType?.id}
                  control={control}
                  label={`${t('company.input.companyType')}*`}
                  rules={ruleCompanyType}
                  required
                />
                <FormInputText
                  name="addresses.0.contactInfo.homepage"
                  defaultValue={data?.addresses[0]?.contactInfo?.homepage}
                  control={control}
                  ref={register}
                  label={t('company.input.website')}
                  // rules={ruleCompanyWebsite}
                  // required
                />

                <FormInputText
                  name="addresses.0.contactInfo.email"
                  defaultValue={data?.addresses[0]?.contactInfo?.email || ''}
                  control={control}
                  label={t('common.input.email')}
                  // rules={ruleEmailAddress}
                  // required
                />

                <FormInputText
                  name="addresses.0.contactInfo.phoneNumber1"
                  defaultValue={data?.addresses[0]?.contactInfo?.phoneNumber1 || ''}
                  control={control}
                  label={t('common.input.phoneNumber')}
                />
                <FormSelect
                  name="numberOfEmployees.id"
                  data={selections.numberOfEmployees}
                  defaultValue={data?.numberOfEmployees?.id}
                  control={control}
                  label={`${t('company.input.employee')}`}
                  rules={ruleNumberOfEmployees}
                />

                <FormSelect
                  name="sales.id"
                  data={selections.sales}
                  defaultValue={data?.sales?.id}
                  control={control}
                  label="Umsatz"
                  rules={ruleSales}
                />

                <FormInputText
                  name="foundingTime"
                  defaultValue={data?.foundingTime}
                  control={control}
                  label={t('company.input.yearFounded')}
                  // rules={ruleCompanyFoundingYear}
                />

              </Grid>
              <Box
                sx={{
                  width: {
                    xs: '100%',
                  },
                  mb: 3
                }}
              >
                <FormInputText
                  name="description"
                  defaultValue={data?.description}
                  control={control}
                  ref={register}
                  label={t('company.input.description')}
                  rules={ruleCompanyDescription}
                  multiline
                  rows={5}
                  maxRows={4}
                />
              </Box>
              <Typography variant="h5" fontWeight="bold" marginBottom={4}>
                Adresse
              </Typography>
              <Grid
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 1,
                  gridTemplateColumns: {
                    xs: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)'
                  },
                }}
              >

                <FormInputText
                  name="addresses.0.street"
                  defaultValue={data?.addresses[0]?.street}
                  control={control}
                  ref={register}
                  label="Straße"
                />

                <FormInputText
                  name="addresses.0.housenumber"
                  defaultValue={data?.addresses[0]?.housenumber}
                  control={control}
                  ref={register}
                  label="Hausnummer"
                />

                <FormInputText
                  name="addresses.0.zip"
                  defaultValue={data?.addresses[0]?.zip}
                  control={control}
                  ref={register}
                  label="Postleitzahl"
                  rules={ruleZip}
                  required
                />

                <FormInputText
                  name="addresses.0.city"
                  defaultValue={data?.addresses[0]?.city}
                  control={control}
                  ref={register}
                  label="Ort"
                  rules={ruleCompanyCity}
                  required
                />
                <FormAutocompleteSelect
                  name="addresses.0.country"
                  data={countrys}
                  control={control}
                  label="Land"
                  rules={rulesCountry}
                  required
                />

                <FormAutocompleteSelect
                  name="addresses.0.state"
                  defaultValue={data?.addresses[0]?.state}
                  data={states}
                  control={control}
                  ref={register}
                  label="Bundesland"
                  // disabled={watchCompany !== 'Deutschland'}
                />
                {/* <FormInputText
                  name="addresses.0.contactInfo.phoneNumber2"
                  defaultValue={data?.addresses[0]?.contactInfo?.phoneNumber2}
                  control={control}
                  ref={register}
                  label="Telefonnummer"
                /> */}

              </Grid>
            </Box>
            <Box sx={{
              pr: {
                lg: 1
              },
              pt: {
                lg: 7
              },
              width: {
                xs: '100%',
                lg: '40%',
                xl: '30%'
              },
              mb: 'auto'
            }}
            >
              <UploadAndDisplayImage
                onSaveImage={(imgData: string) => onSaveCompanyLogo(imgData)}
                onDeleteImage={() => onDeleteCompanyLogo}
                currentImage={data?.logo}
                defaultImage={PlaceholderLogoCompany}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CompanyProfileForm;
