/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Chip, Divider, Typography, CardHeader as MuiCardHeader, LinearProgress, LinearProgressProps,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ExpandMoreOutlined as ExpandMoreIcon,
  ExtensionOutlined as NetworkIcon,
  CheckCircleOutlineRounded as MatchIcon,
  Inventory2Outlined as BlacklistIcon,
  BookmarkBorderOutlined as BookmarkIcon,
  EmailOutlined as EmailIcon,
} from '@mui/icons-material';
import {
  colorType, getContrastText, statusColor, statusText
} from '../../utils';

export type ICardHeaderProps = {
    page: string
    itemName: string;
    score: number;
    isCombiProduct: boolean;
    type: string;
    owner: boolean;
    status: number;
    newRefModels: [];
    handleOpenDetailCard?: (tabValue: string) => void;
}

const CardHeader: React.FC<ICardHeaderProps> = (props: ICardHeaderProps) => {
  const {
    page,
    itemName,
    score,
    isCombiProduct,
    type,
    owner,
    status,
    newRefModels,
    handleOpenDetailCard
  } = props;

  const { t } = useTranslation();

  function LinearProgressWithLabel(lpprops: LinearProgressProps & { value: number }) {
    const { value } = lpprops;
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '60%', mr: 1 }}>
          <LinearProgress variant="determinate" {...lpprops} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.primary">
            Erfüllt Ihre Anforderungen zu:
            {` ${Math.round(
              value,
            )}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  function InfoChipWithIcon(infochipProps: { icon: ReactElement, label: string }) {
    const { icon, label } = infochipProps;
    return (
      <Chip
        sx={{
          '& .MuiChip-label': {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            fontSize: 12,
            // color: getContrastText(colorType(type, isCombiProduct))
          },
          // backgroundColor: colorType(type, isCombiProduct),
        }}
        icon={icon}
        size="small"
        color="secondary"
        label={label}
      />
    );
  }

  return (
    <>
      <MuiCardHeader
        titleTypographyProps={{ variant: 'body1' }}
        title={
        (page === 'matching' && (
          <InfoChipWithIcon
            icon={<NetworkIcon />}
            label={itemName}
          />
        ))
        || (page === 'matches' && (
          <InfoChipWithIcon
            icon={<MatchIcon />}
            label={itemName}
          />
        )
        )
        || (page === 'blacklist' && (
          <InfoChipWithIcon
            icon={<BlacklistIcon />}
            label={itemName}
          />
        ))
        || (page === 'bookmarks' && (
          <InfoChipWithIcon
            icon={<BookmarkIcon />}
            label={itemName}
          />
        ))
        || (page === 'outgoing' && (
          <InfoChipWithIcon
            icon={<EmailIcon />}
            label={itemName}
          />
        ))
        || (page === 'incoming' && (
          <InfoChipWithIcon
            icon={<EmailIcon />}
            label={itemName}
          />
        ))
        || ((page === 'searchforproject' || page === 'externalsearch') && (
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel variant="determinate" color="primary" value={score} sx={{ mr: 2 }} />
        </Box>
        ))
        || ((page === 'portfolio' || page === 'projects') && (
          <Box sx={{
            display: 'flex', flexWrap: 'wrap'
          }}
          >
            {(isCombiProduct === true) && (
            <Chip
              size="small"
              label={owner ? 'Organisator' : 'Partner'}
              color="info"
              sx={{
                fontSize: 12,
                mr: 1,
              }}
            />
            )}
            <Chip
              size="small"
              label={statusText(status)}
              sx={{
                fontSize: 12,
              }}
              color={statusColor(status)}
            />
          </Box>
        ))
      }
        subheader={page === 'matching' && (newRefModels.length > 0 && (
          <Box onClick={handleOpenDetailCard ? () => handleOpenDetailCard('Refferenzmodelle') : undefined}>
            <Chip
              size="small"
              label={`${t('card.informationBar.referenceModellInfo')} (${newRefModels.length})`}
              color="secondary"
              sx={{
                mt: 1, fontSize: 12,
              }}
            />
          </Box>
        // <Accordion sx={{ boxShadow: 'none', flexShrink: 0, position: 'static' }}>
        //   <AccordionSummary
        //     expandIcon={<ExpandMoreIcon />}
        //     aria-controls="panel1a-content"
        //     id="panel1a-header"
        //     sx={{ px: 0, marginY: 0, minHeight: 'auto !important' }}
        //   >
        //     <Chip
        //       size="small"
        //       label={`${t('card.informationBar.referenceModellInfo')} (${newRefModels.length})`}
        //       color="secondary"
        //       sx={{
        //         fontSize: 12,
        //       }}
        //     />
        //   </AccordionSummary>
        //   <AccordionDetails>
        //     {newRefModels && newRefModels.map((data: any) => (
        //       <Chip
        //         size="small"
        //         sx={{
        //           ml: 0,
        //           mr: 1,
        //           mb: 1,
        //           fontSize: 12,
        //         }}
        //         label={data.name}
        //         key={`reference_${data.id}`}
        //       />
        //     ))}
        //   </AccordionDetails>
        // </Accordion>
        ))}
      />
      <Divider sx={{ bgcolor: 'secondary', height: '1px' }} />
    </>
  );
};

export { CardHeader };
