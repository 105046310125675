/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Controller } from 'react-hook-form';

import {
  TextField, InputAdornment, IconButton, InputProps
} from '@mui/material';

import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const FormInputSearch = ({
  name,
  control,
  defaultValue,
  label,
  type = 'text',
  variant = 'outlined',
  rules = {},
  sx = {},
  multiline = false,
  fullWidth = false,
  rows = 2,
  maxRows = 4,
  onIconClick
}: FormInputProps) => {
  // define the search-icon at the beginning of the input field
  const startAdornment = (
    <InputAdornment position="start">
      <IconButton
        aria-label="toggle password visibility"
        onClick={onIconClick}
      >
        <Search sx={{ color: 'text.secondary' }} />
      </IconButton>
    </InputAdornment>
  );

  const { t } = useTranslation();

  const inputProps: InputProps = { startAdornment };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          placeholder={t('search.searchTerm')}
          multiline={multiline}
          rows={rows}
          maxRows={maxRows}
          fullWidth={fullWidth}
          variant={variant}
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : null}
          type={type}
          sx={sx}
          InputProps={inputProps}
        />
      )}
      rules={rules}
    />

  );
};

export default FormInputSearch;
