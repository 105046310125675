/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  FC, ReactElement, useEffect, useState
} from 'react';

import {
  Alert, AlertTitle, Box, LinearProgress, Snackbar
} from '@mui/material';

import { useAppStore } from '../../state';

const SystemMessage:FC = ():ReactElement => {
  const alert = useAppStore((state:GenericObject) => state.alert);
  const resetAlert = useAppStore((state:GenericObject) => state.appResetAlert);
  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (alert) {
      setTimer(setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
      }, 50));
    }
    return () => {
      clearInterval(timer!);
    };
  }, [alert]);

  if (!alert) return <></>;
  const { text, severity, duration } = alert;

  const handleOnClose = () => {
    clearInterval(timer!);
    setProgress(0);
    resetAlert();
  };

  const handleMouseOver = () => {
    clearInterval(timer!);
    setProgress(0);
  };

  const handleMouseLeave = () => {
    setTimer(setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
    }, 5000 / 100));
  };

  const titles: GenericObject = {
    info: 'Info',
    error: 'Fehler',
    success: 'Erfolg',
    alert: 'Warnung'
  };

  return (
    <Snackbar
      open={!!alert}
      autoHideDuration={duration || 6000}
      resumeHideDuration={duration || 6000}
      onClose={handleOnClose}
      sx={{ zIndex: '1550' }}
    >
      <Box>
        <Alert severity={severity} variant="filled" onClose={handleOnClose} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} sx={{ width: '100%' }}>
          <AlertTitle sx={{ color: 'white' }}>{titles[severity]}</AlertTitle>
          {text}
          {duration}

        </Alert>
        <LinearProgress color="inherit" variant="determinate" value={progress} />
      </Box>
    </Snackbar>
  );
};

export default SystemMessage;
