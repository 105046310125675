import { FC, ReactElement, useEffect } from 'react';

import { useUserStore, useProductStore } from '../state';

import SearchMain from '../components/privatsearch/SearchMain';

const Search: FC = (): ReactElement => {
  // load all important data from api to display it on the search main page
  useEffect(() => {
    useUserStore.getState().userGetAccountData();
    useProductStore.setState({ currentProductProject: null });
    useProductStore.getState().prodGetCategories();
  }, []);

  return (
    <SearchMain />
  );
};

export default Search;
