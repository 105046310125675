import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { useAppStore } from '../../../state';

import Header from './Header';
import Drawer from './Drawer';
import { useWindowSize } from '../../../hooks';

const Layout = ():ReactElement => {
  const isOpened = useAppStore((state:GenericObject) => state.drawerIsOpened);
  const [screenWidth] = useWindowSize();
  const isMobile = screenWidth < 768;

  let drawerWidth = 280;
  if (!isOpened) drawerWidth = 70;

  const largeScreenPadding = {
    paddingLeft: '70px', md: `${drawerWidth}px`
  };

  const smallScreenPadding = {
    paddingLeft: '0px', md: '0px'
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Box sx={{ display: 'flex', height: 'calc(100vh - 60px)' }}>
        <Box>
          <Drawer />
        </Box>
        <Box
          sx={{
            width: '100%',
            pt: '60px',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
          }}
          paddingLeft={isMobile ? smallScreenPadding : largeScreenPadding}
        >
          <Box sx={{ p: 4, flexGrow: '1' }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
