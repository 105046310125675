import {
  Box, Button, Grid, Link, Typography
} from '@mui/material';
import {
  FC, ReactElement
} from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';

const DataProtectionMain: FC = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToPreviousPath = () => {
    navigate(-1);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={10}>
        <Button sx={{ ml: '-15px', mb: 2 }} onClick={goToPreviousPath} startIcon={<ChevronLeftIcon sx={{ color: '#B50900' }} />}>
          <Typography sx={{ color: '#B50900' }}>{t('common.button.back')}</Typography>
        </Button>
        <Box>
          <Typography variant="h2" color="primary.main" gutterBottom>
            Datenschutz auf einen Blick
          </Typography>
          <Typography variant="h3" color="primary.main" gutterBottom>
            Allgemeine Hinweise
          </Typography>
          <Typography variant="body1" paragraph>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Plattform besuchen.
            Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
          </Typography>
          <Typography variant="h3" color="primary.main" gutterBottom>
            Datenerfassung auf unserer Plattform
          </Typography>
          <Typography variant="body1" fontWeight="bold" paragraph>
            Wer ist verantwortlich für die Datenerfassung auf dieser Plattform?
          </Typography>
          <Typography variant="body1" paragraph>
            Die Datenverarbeitung auf dieser Plattform erfolgt durch Bundesverband IT-Mittelstand e.V. (BITMi), Pascalstraße 6, 52076 Aachen.
          </Typography>
          <Typography variant="body1" fontWeight="bold" paragraph>
            Wie erfassen wir Ihre Daten?
          </Typography>
          <Typography variant="body1" paragraph>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Formular eingeben.
            Andere Daten werden automatisch beim Besuch der Plattform durch unsere IT-Systeme erfasst.
            Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Plattform betreten.
          </Typography>
          <Typography variant="body1" fontWeight="bold" paragraph>
            Wofür nutzen wir Ihre Daten?
          </Typography>
          <Typography variant="body1" paragraph>
            IT2match bietet seinen Nutzern die Möglichkeit, ein Nutzer-Profil zu erstellen, sowie Lösungs- und Projektprofile anzulegen.
            Mit der Nutzung der Plattform willigt der Nutzer ein, dass die in den jeweiligen Lösungs- und Projektprofilen enthaltenen Daten für andere registrierte Nutzer der Plattform zum Zweck des Findens eines passenden Matches angezeigt werden.
            <br />
            <br />
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Plattform zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
          </Typography>
          <Typography variant="body1" fontWeight="bold" paragraph>
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </Typography>
          <Typography variant="body1" paragraph>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck der Verarbeitung in Bezug auf Ihre gespeicherten personenbezogenen Daten zu erhalten.
            Sie haben außerdem das Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen.
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns unter den unten angegebenen Kontaktdaten wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          </Typography>
          <Typography variant="h2" color="primary.main" gutterBottom>
            Datenschutzerklärung
          </Typography>
          <Typography variant="h3" color="primary.main" gutterBottom>
            Datenschutz
          </Typography>
          <Typography variant="body1" paragraph>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und gesetzeskonform.
          </Typography>
          <Typography variant="body1" paragraph>
            Wenn Sie diese Plattform benutzen, werden verschiedene personenbezogene Daten erhoben.
            Personenbezogene Daten sind gemäß Art. 4 Nr. 1 Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten,
            zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG (Datenschutz-Grundverordnung oder DSGVO) alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.
            Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
          </Typography>
          <Typography variant="body1" paragraph>
            Wir weisen darauf hin, dass die Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
          </Typography>
          <Typography variant="h6" color="primary.main" gutterBottom>
            Gegenstand dieser Datenschutzerklärung
          </Typography>
          <Typography variant="body1" paragraph>
            Diese Datenschutzerklärung gilt für alle Dienste, die unter der Domain „it2match.de“ und „it2match.com“ und der App mit dem Namen „IT2match“ angeboten werden.
            Gegenstand des Datenschutzes sind personenbezogene Daten. Dies sind nach Art. 4 Nr. 1 DSGVO alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.
            Hierunter fallen z.B. Angaben wie Name, E-Mail-Adresse und Nutzungsdaten.
            Nutzungsdaten sind solche Daten, die erforderlich sind, um unsere Dienste zu nutzen, wie z.B. Angaben über Beginn, Ende und Umfang der Nutzung der Dienste und Anmeldedaten.
            Näheres schildern wir Ihnen im Zusammenhang mit dem Besuch unserer Webseite und der Nutzung unserer mobilen Anwendung.
          </Typography>
          <Typography variant="h6" color="primary.main" gutterBottom>
            Hinweis zur verantwortlichen Stelle
          </Typography>
          <Typography variant="body1" paragraph>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Plattform ist:
          </Typography>
          <Typography variant="body1" paragraph>
            Bundesverband IT-Mittelstand e.V. (BITMi)
            <br />
            Pascalstraße 6
            <br />
            52076 Aachen
          </Typography>
          <Typography variant="body1" paragraph>
            Telefon: +49 241 1890 558
            <br />
            E-Mail: kontakt@bitmi.de
          </Typography>
          <Typography variant="body1" paragraph>
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
          </Typography>
          <Typography variant="h6" color="primary.main" paragraph>
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </Typography>
          <Typography variant="body1" paragraph>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
            Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </Typography>
          <Typography variant="h6" color="primary.main" paragraph>
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </Typography>
          <Typography variant="body1" paragraph>
            Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat.
            Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden:
            <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>
            .
          </Typography>
          <Typography variant="h6" color="primary.main" paragraph>
            SSL- bzw. TLS-Verschlüsselung
          </Typography>
          <Typography variant="body1" paragraph>
            Diese Plattform nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung.
            Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt, sowie an dem Schloss-Symbol in Ihrer Browserzeile.

            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
          </Typography>
          <Typography variant="h6" color="primary.main" paragraph>
            Auskunft, Sperrung, Löschung
          </Typography>
          <Typography variant="body1" paragraph>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten,
            deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
            Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
          </Typography>
          <Typography variant="h6" color="primary.main" paragraph>
            Widerspruch gegen Werbe-Mails
          </Typography>
          <Typography variant="body1" paragraph>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen.
            Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
          </Typography>
          <Typography variant="h3" color="primary.main" paragraph>
            Datenerfassung auf unserer Website
          </Typography>
          <Typography variant="h6" color="primary.main" paragraph>
            Server-Log-Dateien
          </Typography>
          <Typography variant="body1" paragraph>
            Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:

            Browsertyp und Browserversion
            verwendetes Betriebssystem
            Referrer URL
            Hostname des zugreifenden Rechners
            Uhrzeit der Serveranfrage
            IP-Adresse
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.

            Grundlage für die Datenverarbeitung ist die Wahrung unserer berechtigten Interessen im Sinne von Art. 6 Abs. 1 lit. f DSGVO, denn wir müssen gegebenenfalls einen feindlichen Angriff auf die Webseite verfolgen und ahnden können.
          </Typography>
        </Box>
      </Grid>
    </Grid>

  );
};
export default DataProtectionMain;
