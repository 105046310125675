import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, Typography
} from '@mui/material';

import {
  Close as CloseIcon,
} from '@mui/icons-material';

import { useAppStore } from '../../state';
import IntroImageOne from '../../assets/images/intro-img-1.png';
import IntroImageTwo from '../../assets/images/intro-img-2.png';
import IntroImageThree from '../../assets/images/intro-img-3.png';
import IntroImageFour from '../../assets/images/intro-img-4.png';
// import { useTranslation } from 'react-i18next';

const OnboardingDialog = ():ReactElement => {
  const app = useAppStore((state:GenericObject) => state);
  const { appSetShowOnboarding, showOnboarding } = app;

  const { t } = useTranslation();

  const handleClose = () => {
    appSetShowOnboarding(false);
  };
  const steps = [
    {
      label: `${t('onboarding.header')} 1/5`,
      title: t('onboarding.section1.header'),
      description:
              `${t('onboarding.section1.text.paragraph1')}
               ${t('onboarding.section1.text.paragraph2')}
               ${t('onboarding.section1.text.paragraph3')}
               ${t('onboarding.section1.text.paragraph4')}`,
      image: IntroImageOne,
    },
    {
      label: `${t('onboarding.header')} 2/5`,
      title: t('onboarding.section2.header'),
      description:
              `${t('onboarding.section2.text.paragraph1')}
               ${t('onboarding.section2.text.paragraph2')}
               ${t('onboarding.section2.text.paragraph3')}`,
      image: IntroImageTwo,
    },
    {
      label: `${t('onboarding.header')} 3/5`,
      title: t('onboarding.section3.header'),
      description:
              `${t('onboarding.section3.text.paragraph1')}
               ${t('onboarding.section3.text.paragraph2')}
               ${t('onboarding.section3.text.paragraph3')}
               ${t('onboarding.section3.text.paragraph4')}
               ${t('onboarding.section3.text.paragraph5')}`,
      image: IntroImageThree,
    },
    {
      label: `${t('onboarding.header')} 4/5`,
      title: t('onboarding.section4.header'),
      description:
              `${t('onboarding.section4.text.paragraph1')}`,
      image: IntroImageFour,
    },
    {
      label: `${t('onboarding.header')} 5/5`,
      title: t('onboarding.section5.header'),
      description:
              `${t('onboarding.section5.text.paragraph1')}
              ${t('onboarding.section5.text.paragraph2')}`,
      image: IntroImageFour,
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Dialog
        open={showOnboarding}
        onClose={handleClose}
        maxWidth="lg"
      >
        <Box position="absolute" top={20} right={20}>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ fontSize: 40 }} />
          </IconButton>
        </Box>
        <DialogContent>
          {/* onboarding screen 1 */}
          <Box paddingLeft={{ xs: 3, md: 5 }} paddingRight={{ xs: 3, md: 5 }}>
            <Typography variant="h6" paddingTop={{ xs: 2 }} paddingBottom={{ xs: 6 }} color="black" fontWeight="bold">
              {steps[activeStep].label}
            </Typography>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 4 }} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography variant="h4" fontWeight="bold" color="primary" marginBottom={{ xs: 3 }}>
                  {steps[activeStep].title}
                </Typography>
                <DialogContentText sx={{ whiteSpace: 'pre-line' }} paddingBottom={{ xs: 2 }} color="black">
                  {steps[activeStep].description}
                </DialogContentText>
              </Grid>
              <Grid item xs={12} md={6} textAlign="center">
                <Box component="img" src={steps[activeStep].image} alt="intro image" sx={{ maxWidth: '100%', maxHeight: '370px' }} maxWidth={500} maxHeight={370} marginLeft="auto" marginRight="auto" />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 5, pb: 2 }}>
          <Button type="submit" onClick={handleBack} style={{ marginBottom: '10px' }} variant="contained" color="primary" disabled={activeStep === 0}>
            { t('common.button.back') }
          </Button>
          {!(activeStep === maxSteps - 1)
          && (
          <Button type="submit" onClick={handleNext} style={{ marginBottom: '10px' }} variant="contained" color="primary" disabled={activeStep === maxSteps - 1}>
            { t('common.button.next') }
          </Button>
          )}
          {(activeStep === maxSteps - 1)
          && (
          <Button type="submit" onClick={handleClose} style={{ marginBottom: '10px' }} variant="contained" color="primary">
            { t('common.button.close') }
          </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OnboardingDialog;
