import { FC, ReactElement, useEffect } from 'react';

import { useProductStore, useUserStore } from '../state';

import SearchMain from '../components/internalsearch/SearchMain';

const SearchForProject: FC = (): ReactElement => {
  useEffect(() => {
    useUserStore.getState().userGetAccountData();
    useProductStore.getState().prodGetCategories();
  }, []);

  return (
    <SearchMain />
  );
};

export default SearchForProject;
