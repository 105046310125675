/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import { FC, ReactElement, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  Button, Container, FormControl, Grid, Typography
} from '@mui/material';

import { useAppStore, useUserStore } from '../state';

import { ruleEmailAddress, rulePassword } from '../rules';

import BackToStart from '../components/ui/BackToStart';
import FormInputText from '../components/ui/FormInputText';
import LandingImage from '../components/partials/LandingImage';

const Login: FC = (): ReactElement => {
  const matomoUrl = process.env.REACT_APP_MATOMO_TARGE_BASE_URL;

  const app = useAppStore((state:GenericObject) => state);
  const { appSetShowResetPassword, appSetShowResendOptIn, appSetShowLoading } = app;

  const { t } = useTranslation();
  const login = useUserStore((state:GenericObject) => state.userLogin);
  const navigate = useNavigate();
  const { handleSubmit, control, register } = useForm();

  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: 'Login',
      href: `${matomoUrl}login/`,
    });
  }, []);

  const token = localStorage.getItem('token');
  if (token) {
    setTimeout(() => {
      navigate('/app/startscreen');
    }, 50);
    return <></>;
  }
  const onSubmit = (data: GenericObject) => {
    appSetShowLoading(true);
    const { email, password } = data;

    const promise = login(email, password);
    promise
      .then((response: GenericObject) => {
        appSetShowLoading(false);
        const { success, optin } = response;
        if (success && optin) {
          return navigate('/app/startscreen');
        }

        if (success && !optin) {
          return appSetShowResendOptIn(true);
        }
        return false;
      });
  };

  const handleResetPassword = () => appSetShowResetPassword(true);

  return (
    <Container style={{ height: '100%' }}>
      <Grid
        container
        height="100%"
        columnSpacing={{ xs: 1, sm: 2, md: 10 }}
        rowSpacing={{ xs: 4, sm: 5, md: 3 }}
        paddingTop={2}
        // alignItems="center"
      >
        <Grid item xs={12} md={6} order={{ sm: 1, xs: 2 }}>
          <LandingImage />
        </Grid>
        <Grid item xs={12} md={6} mt={2}>
          <BackToStart />
          <Typography variant="h2" gutterBottom>{ t('login.header') }</Typography>
          <Typography sx={{ fontWeight: 'bold', whiteSpace: 'pre-line' }} gutterBottom marginBottom={5}>{ t('login.text.paragraph1') }</Typography>
          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px' }}>
            <FormControl>
              <FormInputText
                name="email"
                control={control}
                ref={register}
                label={t('common.input.email')}
                rules={ruleEmailAddress}
                autoComplete="email"
              />
            </FormControl>
            <FormControl>
              <FormInputText
                name="password"
                control={control}
                ref={register}
                label={t('common.input.password')}
                type="password"
                rules={rulePassword}
                autoComplete="password"
              />
            </FormControl>
            <div style={{ marginBottom: '40px' }}>
              <Button
                type="submit"
                sx={{
                  mb: 1, px: 1.5, py: 0.7, mr: 2
                }}
                variant="contained"
                color="primary"
              >
                { t('login.button.login') }
              </Button>
              <Button
                type="button"
                color="secondary"
                variant="contained"
                onClick={handleResetPassword}
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
              >
                { t('login.button.forgotPassword') }
              </Button>
            </div>
            <Typography style={{ marginTop: 20 }}>
              {`${t('login.button.notYetRegistered')} `}
              <Typography component={RouterLink} to="/register" style={{ color: 'primary', fontWeight: 'bold' }}>
                {t('common.button.registerHere')}
              </Typography>
            </Typography>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
