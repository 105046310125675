import {
  FC, ReactElement, useState
} from 'react';

import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import {
  Box, Typography, Button, Grid, Modal, Card, CardActionArea, CardActions, CardContent, CardHeader, Divider, Avatar,
} from '@mui/material';

import {
  DeleteOutlineOutlined as DeleteIcon,
} from '@mui/icons-material';

import { useProductStore } from '../../state';

import PlaceholderImage from '../../assets/images/placeholder_company.png';
import DetailCard from '../matching/Card';

const MatchesCard: FC = (props: any): ReactElement => {
  const { t } = useTranslation();
  /* eslint-disable react/jsx-props-no-spreading */
  const {
    id, name, company, item, type, logo, screenType, onAdd, onWithdrawnOffer
  } = props;

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);

  const handleAddCombiProduct = () => {
    useProductStore.setState({ newCombiProduct: props });
    navigate('/app/edit-combiproduct');
  };

  // eslint-disable-next-line no-nested-ternary
  const typeName = type === 'solution'
    ? t('card.type.singleproduct')
    : (type === 'combiProduct' ? t('card.type.combiproduct') : t('card.type.request'));

  return (
    <Grid item xs={12} lg={6} xl={4}>
      <Card>
        <CardActionArea onClick={handleToggle}>
          <CardHeader
            titleTypographyProps={{ variant: 'body1', color: 'black' }}
            title={t('card.informationBar.networkInfo', { variable: item?.name })}
          />
          <CardContent>
            <Box
              paddingY={{ xs: 2, lg: 2.5 }}
              marginY={{ md: 1 }}
              sx={{
                display: 'flex', alignItems: 'center', flexGrow: '1', cursor: 'pointer'
              }}
            >
              <Avatar src={logo ?? company?.logo ?? PlaceholderImage} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
              <div style={{ flexGrow: 1, marginLeft: 20 }}>
                <Typography fontSize={8} color="black" fontWeight="600" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}>{typeName}</Typography>
                <Typography onClick={handleToggle} variant="h5" color="black" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}>{name}</Typography>
                {(type === 'solution' || type === 'combiProduct') && <Typography fontSize={10} color="black" fontWeight="600" letterSpacing="0.5px">{company?.name}</Typography>}
              </div>
            </Box>
          </CardContent>
        </CardActionArea>
        <Divider />
        <CardActions sx={{ py: { xs: 1.2, sm: 2 }, px: { xs: 2, sm: 3 } }}>
          {(screenType !== 'addCombiProduct' && type !== 'project') && (
          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleAddCombiProduct}
              sx={{
                mr: 2, px: 2.5, py: 0.5
              }}
            >
              {t('common.button.cooperate')}
            </Button>
          </div>
          )}
          {screenType === 'addCombiProduct' && (
          <div>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => onAdd(props)}
              sx={{
                mr: 2, px: 2.5, py: 0.5
              }}
            >
              {t('common.button.add')}
            </Button>
          </div>
          )}
        </CardActions>
      </Card>
      <Modal
        open={open}
        onClose={handleToggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="modal-dialog"
        >
          <Box className="modal-content">
            <DetailCard
              screenType="modal"
              onClose={handleToggle}
              {...props}
            />
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};
export default MatchesCard;
