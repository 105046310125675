import {
  FC, ReactElement
} from 'react';

import { Box } from '@mui/material';
import LandingPageArt from '../../assets/images/landing-page-art.png';

const LandingImage:FC = ():ReactElement => (
  <Box
    component="img"
    sx={{
      height: 'auto',
      width: '100%',
      maxWidth: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block'
    }}
    alt="The house from the offer."
    src={LandingPageArt}
  />
);
export default LandingImage;
