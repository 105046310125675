import { FC, ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button, Typography, Grid, Paper
} from '@mui/material';

import { useUserStore, useAppStore } from '../../state';

import {
  ruleFirstname, ruleLastname, ruleEmailAddress, rulePassword, rulePasswordConfirmation
} from '../../rules';

import FormInputText from '../ui/FormInputText';

const UserProfile: FC = (): ReactElement => {
  const { t } = useTranslation();

  // load all important data from API
  useEffect(() => {
    useUserStore.getState().userGetAccountData();
  }, []);

  const user = useUserStore((state: GenericObject) => state);

  if (!user) {
    return <></>;
  }

  const editAccountName = user.userEditAccountName;
  const editEmailAddress = user.userEditEmailAddresse;
  const editPassword = user.userEditPassword;
  const removeAccount = user.userRemoveAccount;

  const { firstname, lastname, email } = user;

  const { handleSubmit: handleSubmitName, control: controlName, register: registerName } = useForm();
  const { handleSubmit: handleSubmitEmail, control: controlEmail, register: registerEmail } = useForm();
  const {
    handleSubmit: handleSubmitPWD, control: controlPWD, getValues: getValuesPWD, register: registerPWD
  } = useForm();

  const onSubmitAccount = (data: GenericObject) => {
    const { firstname: fname, lastname: lname } = data;
    editAccountName(fname, lname);
  };

  const onSubmitEmail = (data: GenericObject) => {
    const { email: formEmail } = data;
    editEmailAddress(formEmail);
  };

  const onSubmitPassword = (data: GenericObject) => {
    const { passwordCurrent, passwordNew } = data;
    editPassword(passwordCurrent, passwordNew);
  };

  const handleConfirm = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.confirmDeleteAccount'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.confirmDelete'),
          color: 'error',
          onClick: removeAccount
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  // https://stackoverflow.com/questions/60276510/how-to-make-react-hook-form-work-with-multiple-forms-in-one-page

  return (
    <>
      <Grid
        container
        rowSpacing={{ xs: 4, sm: 6, md: 8 }}
        columnSpacing={{ xs: 0, md: 6 }}
      >
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h4" fontWeight="bold" marginBottom={3} gutterBottom>
              { t('profile.subheader1') }
            </Typography>
            {/* <Typography marginBottom={3} color="black" gutterBottom>Der alte Name gefällt ihnen nicht? Kein Problem! Hier können Sie ihn ändern.</Typography> */}
            <form onSubmit={handleSubmitName(onSubmitAccount)}>
              <FormInputText
                name="firstname"
                control={controlName}
                ref={registerName}
                defaultValue={firstname}
                label={t('common.input.firstName')}
                rules={ruleFirstname}
                sx={{ mb: 3 }}
              />
              <FormInputText
                name="lastname"
                control={controlName}
                ref={registerName}
                defaultValue={lastname}
                label={t('common.input.lastName')}
                rules={ruleLastname}
                sx={{ mb: 3 }}
              />

              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mb: 1, px: 1.5, py: 0.7
                  }}
                >
                  { t('profile.button.changeUsername') }
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h4" fontWeight="bold" marginBottom={3} gutterBottom>
              { t('profile.subheader2') }
            </Typography>
            {/* <Typography marginBottom={3} color="black" gutterBottom>Schon zu viele Spam-Mails? Legen Sie sich doch eine neue Email-Adresse zu und ändern Sie dann diese dann hier.</Typography> */}
            <form onSubmit={handleSubmitEmail(onSubmitEmail)}>
              <FormInputText
                name="email"
                control={controlEmail}
                ref={registerEmail}
                defaultValue={email}
                label={t('common.input.email')}
                rules={ruleEmailAddress}
                sx={{ mb: 3 }}
              />

              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mb: 1, px: 1.5, py: 0.7
                  }}
                >
                  { t('profile.button.changeMail') }
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h4" fontWeight="bold" marginBottom={3} gutterBottom>
              { t('profile.subheader3') }
            </Typography>
            {/* <Typography marginBottom={3} color="black" gutterBottom>Sie achten auf die Sicherheit, vielen Dank. Hier können Sie jederzeit ihr Passwort ändern.</Typography> */}
            <form onSubmit={handleSubmitPWD(onSubmitPassword)}>
              <FormInputText
                name="passwordCurrent"
                control={controlPWD}
                ref={registerPWD}
                label={t('profile.input.currentPassword')}
                type="password"
                rules={rulePassword}
                sx={{ mb: 3 }}
                autoComplete="current-password"
              />

              <FormInputText
                name="passwordNew"
                control={controlPWD}
                ref={registerPWD}
                label={t('profile.input.newPassword')}
                type="password"
                rules={rulePassword}
                sx={{ mb: 3 }}
                autoComplete="new-password"
                helperText={`
                erlaubte Zeichen: a-zA-Z0-9(!@#$%^&*)
                muss mindestens ein Großbuchstaben (A-Z) enthalten,
                muss mindestens ein Kleinbuchstaben (a-z) enthalten,
                muss mindestens eine Ziffer (0-9) enthalten,
                muss mindestens 8 Zeichen lang sein.`}
              />

              <FormInputText
                name="passworConfirm"
                control={controlPWD}
                ref={registerPWD}
                label={t('profile.input.repeatNewPassword')}
                type="password"
                rules={{ validate: (value: string) => rulePasswordConfirmation(value, getValuesPWD('password')) }}
                sx={{ mb: 3 }}
                autoComplete="new-password"
              />

              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mb: 1, px: 1.5, py: 0.7
                  }}
                >
                  { t('profile.button.changePassword') }
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h4" fontWeight="bold" marginBottom={3} gutterBottom>
              { t('profile.subheader4') }
            </Typography>

            <div>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                sx={{
                  mb: 1, px: 1.5, py: 0.7
                }}
              >
                { t('profile.button.deleteAccount') }
              </Button>
            </div>
          </Paper>
        </Grid>

      </Grid>
    </>
  );
};

export default UserProfile;
