import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Typography, Button, Dialog, DialogActions, DialogContent
} from '@mui/material';

import { useAppStore, useUserStore } from '../../state';

import CloseX from '../ui/CloseX';

const ResendOptInDialog = ():ReactElement => {
  const appStore = useAppStore((state:GenericObject) => state);
  const { appSetShowResendOptIn, showResendOptIn } = appStore;

  const { t } = useTranslation();

  const userStore = useUserStore((state:GenericObject) => state);
  const { userResendOptIn, loginEmailAddress } = userStore;

  const handleClose = () => {
    appSetShowResendOptIn(false);
  };

  const handleResendOptIn = () => {
    userResendOptIn(loginEmailAddress);
    appSetShowResendOptIn(false);
  };

  return (
    <>
      <Dialog
        open={showResendOptIn}
        onClose={handleClose}
      >
        <DialogContent sx={{ p: 4 }}>
          <CloseX onClose={handleClose} />
          <Typography variant="h5" gutterBottom>{ t('common.error.problem') }</Typography>
          <Typography gutterBottom marginBottom={3}>
            { t('login.text.confirmRegistration') }
          </Typography>
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button color="secondary" variant="contained" onClick={handleClose}>{ t('common.button.close') }</Button>
          <Button variant="contained" onClick={handleResendOptIn} color="primary">
            { t('common.button.resendOptIn') }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResendOptInDialog;
