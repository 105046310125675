import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid
} from '@mui/material';

import { ruleOfferMessage } from '../../rules';

import FormInputText from '../ui/FormInputText';
import CloseX from '../ui/CloseX';
import placeholderPerson from '../../assets/images/placeholder_person.png';
import { useProductStore } from '../../state';

const BookmarkLinkupDialog = (props:GenericObject):ReactElement => {
  const { t } = useTranslation();

  const {
    open,
    onClose,
    item,
    productname,
  } = props;

  const { handleSubmit, control, register } = useForm();

  const {
    prodLinkupBookmark
  } = useProductStore((state: GenericObject) => state);

  const onSubmit = (data:GenericObject) => {
    prodLinkupBookmark(item.id, data.message);
    onClose();
  };

  let namePerson = `${item?.company?.contactPerson?.title || ''}`;
  namePerson += `${item?.company?.contactPerson?.title ? ' ' : ''}`;
  namePerson += `${item?.company?.contactPerson?.firstname || ''} `;
  namePerson += `${item?.company?.contactPerson?.lastname || ''}`;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ mb: 3, pb: 0 }}>
          <Box
            sx={{
              pt: 1, pb: 2.5, pl: 3, pr: 5, mb: 3, mx: -3, position: 'relative', borderBottom: 2, borderColor: 'secondary.main'
            }}
          >
            <Typography variant="h6" fontSize={16} color="black" lineHeight={1}>
              Vernetzen mit:
              {' '}
              {item?.name}
            </Typography>
            <CloseX onClose={onClose} />
          </Box>
          <Grid container spacing={{ sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <Box component="img" src={item?.company?.contactPerson?.contactPersonImage ?? placeholderPerson} alt="logo" height="70px" width="70px" borderRadius="100px" style={{ flexShrink: 0, objectFit: 'cover' }} />
              <div style={{ flexGrow: 1, marginLeft: 20 }}>
                <Typography fontSize={14} color="black" fontWeight="400" letterSpacing="0.5px" marginBottom={0.5}>{namePerson}</Typography>
                <Typography fontSize={14} color="black" fontWeight="400" letterSpacing="0.5px" marginBottom={0.5}>{item?.company?.contactPerson?.jobTitle || ''}</Typography>
                <Typography fontSize={14} color="black" fontWeight="400" letterSpacing="0.5px" marginBottom={0.5}>{item.company?.contactPerson?.phoneNumber || ''}</Typography>
                <Typography fontSize={14} color="black" fontWeight="400" letterSpacing="0.5px" marginBottom={0.5}>{item.company?.contactPerson?.email || ''}</Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              <FormInputText
                name="message"
                defaultValue={t('matching.offerMessage', { product: productname })}
                control={control}
                ref={register}
                label={`Anfrage an ${namePerson}`}
                multiline
                rows={4}
                maxRows={4}
                rules={ruleOfferMessage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pt: 0, pb: 2.5, mt: -2.5 }}>
          <Button
            color="secondary"
            variant="contained"
            onClick={onClose}
            sx={{
              mr: 2, px: 2.5, py: 0.5, flexWrap: 'wrap'
            }}
          >
            { t('common.button.close') }
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              mr: 2, px: 2.5, py: 0.5, flexWrap: 'wrap'
            }}
          >
            {t('common.button.linkup')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BookmarkLinkupDialog;
