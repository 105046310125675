import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import {
  Typography, Button, Dialog, DialogActions, DialogContent
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useAppStore, useUserStore } from '../../state';

import { ruleEmailAddress } from '../../rules';

import FormInputText from '../ui/FormInputText';
import CloseX from '../ui/CloseX';

const ResetPasswordDialog = ():ReactElement => {
  const { handleSubmit, control, register } = useForm();

  const app = useAppStore((state:GenericObject) => state);
  const { appSetShowResetPassword, showResetPassword } = app;

  const { t } = useTranslation();

  const handleClose = () => {
    appSetShowResetPassword(false);
  };

  const onSubmit = (data: GenericObject) => {
    const { email } = data;
    useUserStore.getState().userResetPassword(email);
  };

  return (
    <Dialog
      open={showResetPassword}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ p: 4 }}>
          <CloseX onClose={handleClose} />
          <Typography variant="h5" gutterBottom>{ t('resetPassword.header') }</Typography>
          <Typography gutterBottom marginBottom={3}>{ t('resetPassword.text.paragraph1') }</Typography>
          <FormInputText
            name="email"
            ref={register}
            control={control}
            label={t('common.input.email')}
            rules={ruleEmailAddress}
          />
          <DialogActions>
            <Button color="secondary" variant="contained" onClick={handleClose}>{t('common.button.close')}</Button>
            <Button type="submit" variant="contained" color="primary">
              {t('common.button.resetPassword')}
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default ResetPasswordDialog;
