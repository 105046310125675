import { NavigateFunction } from 'react-router';
import processData from './processData';

const truncate = (text: string, count: number):string => (text.length > count ? `${text.substring(0, count - 3)}...` : text);

const goToCompanyProfile = (id: string, navigate: NavigateFunction) => {
  navigate('/app/companydetails', { state: { companyId: id } });
};

const statusText = (status: number):string => {
  switch (status) {
    case 10:
      return 'warten auf Bestätigung';
    case 40:
      return 'Inaktiv';
    case 50:
      return 'Aktiv';
    case 60:
      return 'Deaktiviert';
    case 240:
      return 'Abgelehnt';
    case 250:
      return 'gelöscht';
    default:
      return 'Typ nicht implementiert';
  }
};

const statusColor = (status: number) => {
  switch (status) {
    case 10:
      return 'warning';
    case 40:
      return 'warning';
    case 50:
      return 'success';
    case 60:
      return 'error';
    case 240:
      return 'warning';
    case 250:
      return 'primary';
    default:
      return 'success';
  }
};

const getContrastText = (colorString : string) => {
  const firstPart = colorString.split('.')[0];

  // Add 'contrasText' after the dot
  return `${firstPart}.contrastText`;
};

const colorType = (type : string, isCombiProduct: boolean):string => {
  switch (type) {
    case 'combiProduct':
      return 'accentTwo.main';
    case 'project':
      return 'warning.dark';
    case 'solution':
      if (isCombiProduct === true) {
        return 'accentTwo.main';
      }
      return 'info.main';
    default:
      return 'primary';
  }
};

const getYearFromUnixTimestamp = (unixTimestamp:number):string => {
  if (unixTimestamp === undefined || unixTimestamp === null) {
    return '';
  }
  const milliseconds = unixTimestamp * 1000;
  const dateObject = new Date(milliseconds);
  const year = dateObject.getFullYear();

  // Year as string
  return `${year}`;
};

const getDateFromUnixTimestamp = (unixTimestamp:number):string => {
  if (unixTimestamp === undefined) {
    return '';
  }
  const milliseconds = unixTimestamp * 1000;
  const dateObject = new Date(milliseconds);
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getDate().toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  const hours = dateObject.getHours().toString().padStart(2, '0');
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');

  // Date as string
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

function getStringOfPositivesFromCategories(categories: GenericObject):string {
  let res = '';
  if (categories) {
    categories.forEach((element: GenericObject) => {
      if (element.children) {
        element.children.forEach((subelement: GenericObject) => {
          if (element.children) {
            res = `${res + subelement.id},`;
            subelement.children.forEach((subsubelement: GenericObject) => {
              if (subsubelement.children) {
                res = `${res + subsubelement.id},`;
                subsubelement.children.forEach((subsubsubelement: GenericObject) => {
                  res = `${res + subsubsubelement.id},`;
                });
              }
            });
          }
        });
      }
    });
  }
  return res.substring(0, res.length - 1);
}

/**
 * Function to retrieve from a given JSON object the name of all parent elements that have no other child elements.
 *
 * @param obj Json Object to get ParentsWithEmptyChilds from
 * @param parent Parents within the object for reference
 * @param result Array of Results
 * @returns Array of Results
 */
function extractParentsNameWithEmptyChilds(
  obj: any,
  parent?: string,
  result?: GenericObject,
) {
  // eslint-disable-next-line consistent-return
  Object.keys(obj).forEach((key) => {
    const objKey = obj[key];
    if (objKey.constructor === Array) {
      if (objKey.length === 0) {
        // eslint-disable-next-line no-param-reassign
        result = { id: obj.id, name: obj.name };
      } else {
        return extractParentsNameWithEmptyChilds(objKey, key, result);
      }
    }
    if (typeof objKey === 'object') {
      return extractParentsNameWithEmptyChilds(objKey, key, result);
    }
  });
  return result;
}

function getCompletionValueForCompany(company: any) {
  let result = 0;
  let address = company?.addresses[0];

  if (!address) {
    address = {
      city: '',
      contactInfo: {
        phoneNumber1: '',
        homepage: ''
      }
    };
  }

  if (address.contactInfo.phoneNumber1 !== '') {
    result += 10;
  }
  if (address.city !== '') {
    result += 10;
  }
  if (address.contactInfo.homepage !== '') {
    result += 10;
  }
  return result;
}

function getCategoriesFromParams(params:any) {
  const res = params.split(',').map(Number);
  return res;
}

function MapCategories(categories: any) {
  const branches = categories?.find((el: any) => el.id === 1);
  const functions = categories?.find((el: any) => el.id === 121);
  const more = categories?.find((el: any) => el.id === 183 || el.id === 210);

  let resBranches;
  let resFunctions;
  let resMore;

  if (branches !== undefined) {
    resBranches = extractParentsNameWithEmptyChilds(branches);
  }
  if (functions !== undefined) {
    resFunctions = extractParentsNameWithEmptyChilds(functions);
  }
  if (more !== undefined) {
    resMore = extractParentsNameWithEmptyChilds(more);
  }

  return [resBranches, resFunctions, resMore];
}

function getCurrentDate(separator = '.'): string {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();

  return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year} ${hours}:${minutes}`;
}

function MapCategoriesWithouthChildren(categories: any) {
  const branches = categories?.filter((el: any) => el.id < 121);
  const functions = categories?.filter((el: any) => el.id < 182 && el.id > 121);
  const more = categories?.filter((el: any) => el.id >= 182);
  return [branches, functions, more];
}

function getUniqueListBy(arr: any[], key: string) {
  return [...new Map(arr.map((item: { [x: string]: any; }) => [item[key], item])).values()];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function buildCombiproductSolutionList(combiproductCompanies: any[], company: Company) {
  const products: { companyID: any; companyName: any; productName: any; logo: string; }[] = [];
  products.push({
    companyID: company.id,
    companyName: company.name,
    productName: combiproductCompanies[0].networks[0].mySolutionName,
    logo: company.logo
  });
  combiproductCompanies.forEach((cc) => {
    cc.networks.forEach((n: { mySolutionName: any; foreignCompanyId: any; foreignCompanyName: any; foreignSolutionName: any; }) => {
      products.push({
        companyID: n.foreignCompanyId,
        companyName: n.foreignCompanyName,
        productName: n.foreignSolutionName,
        logo: cc.company.logo,
      });
    });
  });

  return getUniqueListBy(products, 'productName');
}

export {
  processData,
  truncate,
  getYearFromUnixTimestamp,
  getDateFromUnixTimestamp,
  getCategoriesFromParams,
  getCurrentDate,
  getCompletionValueForCompany,
  getStringOfPositivesFromCategories,
  MapCategories,
  MapCategoriesWithouthChildren,
  buildCombiproductSolutionList,
  goToCompanyProfile,
  colorType,
  getContrastText,
  statusText,
  statusColor,
};
