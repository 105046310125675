import { FC, ReactElement } from 'react';
import { useUserStore } from '../../state';

const ReLogin: FC = (): ReactElement => {
  useUserStore.getState().userReLogin();

  return (<></>);
};

export default ReLogin;
