import { FC, ReactElement, useEffect } from 'react';

import { useAppStore, useUserStore } from '../state';

import ProfileMain from '../components/profile/ProfileMain';

const CompanyProfile: FC = (): ReactElement => {
  // load all important data from api
  useEffect(() => {
    useAppStore.getState().appGetSelections();
    useUserStore.getState().userGetAccountData();
  }, []);
  return (
    <ProfileMain />
  );
};

export default CompanyProfile;
