/* eslint-disable dot-notation */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import {
  FC, ReactElement, useEffect, useState
} from 'react';

import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  Box, Typography, Pagination, Grid, IconButton, Slider
} from '@mui/material';

import {
  InfoOutlined,
} from '@mui/icons-material';
import {
  useAppStore, useProductStore, usePaginationStore, useUserStore
} from '../../state';

import SkeletonCard from '../ui/SkeletonCard';
import SearchLinkupBookmarkDialog from '../privatsearch/SearchLinkupBookmarkDialog';
import Card from '../ui/Card';

const SearchMain:FC = ():ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {positives : string};
  const defaultSearchItem:GenericObject = { id: -1, name: '...loading' };
  const {
    token
  } = useUserStore((states:GenericObject) => states);
  const { showSkeleton } = useAppStore((states:GenericObject) => states);

  const positives = state && state.positives;
  const [openDialogLinkupBookmark, setOpenDialogLinkupBookmark] = useState(false);
  const [score, setScore] = useState<number | number[]>(30);
  const orderBy = 'score';
  const order = 'desc';
  const categories = '';
  const [searchItem, setSearchItem] = useState(defaultSearchItem);

  const {
    prodPrivatSearch, searchResult
  } = useProductStore((states:GenericObject) => states);

  // Hook for Pagination, start it with init()
  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
    reset,
  } = usePaginationStore((states:GenericObject) => states);

  const sorter = (objectA:GenericObject, objectB:GenericObject) => {
    let compare1:any;
    let compare2:any;

    compare1 = objectB.featureScore;
    compare2 = objectA.featureScore;

    return (compare1 > compare2) ? 1 : ((compare1 < compare2) ? -1 : 0);
  };

  function getAllChildrenIds(features: GenericObject) {
    let childrenIds: number[] = [];
    features.forEach((categorie: GenericObject) => {
      if (categorie.children.length > 0) {
        childrenIds = childrenIds.concat(getAllChildrenIds(categorie.children));
      }
      categorie.children.forEach((child: GenericObject) => {
        childrenIds.push(child.id);
      });
    });
    return childrenIds;
  }

  function addOccurrence(resultlist:GenericObject) {
    if (positives != null) {
      let searchparams = positives.split(',').map(Number);
      resultlist.map((result: GenericObject) => {
        let features: number[] = [];
        let occurences: number[] = [];
        features = getAllChildrenIds(result.categories);

        if (features != null && features.length > 0) {
          features.forEach((element: number) => {
            if (searchparams.includes(element)) {
              occurences.push(element);
            }
          });
        }
        // eslint-disable-next-line no-param-reassign
        result['occurences'] = occurences;
        // eslint-disable-next-line no-param-reassign
        result['featureScore'] = (occurences.length / (searchparams.length - 1)) * 100;
        return result;
      });
    }
    return resultlist.filter((r: { [x: string]: number; }) => r['featureScore'] >= score);
  }

  useEffect(() => {
    if (searchResult) {
      let addedScoreArray = addOccurrence(searchResult);
      // sort the search result locally because there are problems in API
      const sortedArray = addedScoreArray.sort((a:GenericObject, b:GenericObject) => sorter(a, b));
      // build the list und pagination new
      reset();
      init(sortedArray, 6, 1);
    }
  }, [searchResult, orderBy, order, score]);

  // trigger a new search
  useEffect(() => {
    const categoriesFilter = {
      solutionCategories: '999999',
      combiProductCategories: '999999',
      projectCategories: '999999'
    };
    categoriesFilter.solutionCategories = categories || '';
    categoriesFilter.combiProductCategories = categories || '';

    prodPrivatSearch({
      searchTerm: '',
      orderBy,
      order,
      ...categoriesFilter,
    });
  }, [categories]);

  const toggleDialogLinkupBookmark = () => setOpenDialogLinkupBookmark(!openDialogLinkupBookmark);
  const handleLogin = () => navigate('/login');
  const handleRegister = () => navigate('/register');

  const handleLinkupBookmark = (item:GenericObject, mode:string) => {
    // if preview mode than open a modal to motivate user to registrate or login
    if (token) {
      setSearchItem({ ...item, mode });
      setOpenDialogLinkupBookmark(true);
      return true;
    }

    const dialogData = {
      title: { text: t('search.headerPreview') },
      contentItems: [
        {
          text: t('search.searchPreviewText'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.register'),
          color: 'error',
          onClick: handleRegister
        },
        {
          text: t('common.button.login'),
          color: 'error',
          onClick: handleLogin
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: 'Ergebnisliste aus Fragenkatalog' },
      contentItems: [
        {
          text: 'Die Ergebnisliste zeigt Einzel- und Kombiprodukte, welche auf Ihre Anforderungen aus dem Fragenkatalog passen.',
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  return (
    <>
      <SearchLinkupBookmarkDialog
        open={openDialogLinkupBookmark}
        onClose={toggleDialogLinkupBookmark}
        searchItem={searchItem}
      />
      <Box>
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12
        }}
        >
          <Typography variant="h1" fontWeight="bold">
            Ergebnisliste für Ihr Gesuch
          </Typography>
          <IconButton size="large" onClick={handleInfo}><InfoOutlined /></IconButton>
        </div>
        <Box
          component="div"
          sx={{
            mb: 3, display: 'flex', justifyContent: 'start', alignItems: 'center'
          }}
        >
          <Box component="div" width="40%">
            <Typography>
              {`Erfüllungsgrad: ${score}%`}
            </Typography>
            <Slider defaultValue={30} valueLabelDisplay="auto" min={0} max={100} step={10} marks onChangeCommitted={(e, val) => setScore(val)} />
          </Box>
        </Box>
        {showSkeleton ? (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            { [...Array(6)].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }}>
            {paginatedData && paginatedData.map((data: any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <Card
                  key={`card_${data.type}_${data.id}`}
                  item={data}
                  handleLinkup={() => handleLinkupBookmark(data, 'linkup')}
                  handleBookmark={() => handleLinkupBookmark(data, 'bookmark')}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
        )}
        <Box sx={{ pt: 3, clear: 'both' }}>
          {(countItems === 0 && showSkeleton === false) && (
            <Box>
              <Typography variant="h6" sx={{ whiteSpace: 'pre-line' }}>Wir konnten keine passenden Produkte finden.</Typography>
            </Box>
          )}
          {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
        </Box>
      </Box>
    </>
  );
};

export default SearchMain;
