/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import {
  TextField, InputAdornment, IconButton, InputProps
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

const FormInputText = ({
  name,
  control,
  ref,
  label,
  defaultValue,
  type = 'text',
  variant = 'outlined',
  rules = {},
  sx = {},
  multiline,
  fullWidth = false,
  rows,
  autoComplete,
  required,
  helperText,
}: FormInputProps) => {
  // https://stackoverflow.com/questions/60391113/how-to-view-password-from-material-ui-textfield
  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(type === 'text');

  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  // define the eye-icon at the end of the input field if it is a password field
  const endAdornment = (type === 'text') ? null : (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleToggleShowPassword}
        sx={{ color: 'text.primary' }}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const inputProps: InputProps = { endAdornment };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          id={name}
          style={{ whiteSpace: 'pre-line' }}
          ref={ref}
          label={label}
          multiline={multiline}
          rows={rows}
          required={required}
          fullWidth={fullWidth}
          variant={variant}
          value={value}
          color="primary"
          onChange={onChange}
          error={!!error}
          // eslint-disable-next-line no-nested-ternary
          helperText={error ? error.message : helperText}
          type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
          sx={sx}
          autoComplete={autoComplete}
          InputProps={inputProps}
        />
      )}
      rules={rules}
    />

  );
};

export default FormInputText;
