/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Button, Grid, Card, CardActionArea, CardActions, CardContent, Divider, Avatar
} from '@mui/material';

import {
  Add as AddIcon,
} from '@mui/icons-material';

// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PlaceholderImage from '../../assets/images/placeholder_company.png';

const AddNewCard = (props: GenericObject): ReactElement => {
  const { t } = useTranslation();

  const {
    handleNew,
  } = props;

  return (
    <Grid item xs={18} lg={6} xl={6}>
      <Card>
        <CardActionArea onClick={handleNew}>
          <CardContent>
            <Box
              paddingY={{ xs: 2, lg: 2.5 }}
              marginY={{ md: 1 }}
              sx={{
                display: 'flex', alignItems: 'center', flexGrow: '1', cursor: 'pointer'
              }}
            >
              <Avatar src={PlaceholderImage} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
              <div style={{ flexGrow: 1, marginLeft: 20 }}>
                <Typography fontSize={8} color="black" fontWeight="600" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}>{t('card.type.singleproduct')}</Typography>
                <Typography variant="h5" color="black" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}>Legen Sie Ihr erstes Einzelprodukt an</Typography>
              </div>
            </Box>
          </CardContent>
        </CardActionArea>
        <Divider />
        <CardActions sx={{ py: { xs: 1.2, sm: 2 }, px: { xs: 2, sm: 3 } }}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{
              mr: 2, px: 2.5, py: 0.5
            }}
            onClick={handleNew}
            startIcon={<AddIcon />}
          >
            {t('common.button.addSingleProduct')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};
export default AddNewCard;
