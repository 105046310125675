import { ReactElement } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useAppStore } from '../../state';

const Logos = (props:GenericObject):ReactElement => {
  const { item, handleToggleElement } = props;
  const { id, icon, checked } = item;

  const onClick = () => handleToggleElement(id);

  const app = useAppStore((state:GenericObject) => state);
  const { darkMode } = app;

  return (
    <Button
      color="secondary"
      variant="contained"
      disableRipple
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.primary',
        border: checked ? '2px solid #B50900' : '2px solid transparent',
        boxShadow: '0 2px 5px #00000029',
        px: 1.5,
        py: 1,
        mb: 2,
        mr: 3,
        fontWeight: 'bold',
        fontSize: '12px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        overflow: 'hidden',
        minWidth: '195px',
        width: 'auto'
      }}
      // eslint-disable-next-line no-nested-ternary
      startIcon={<Box component="img" width={40} height={40} src={icon} sx={{ filter: checked ? 'invert(15%) sepia(54%) saturate(5717%) hue-rotate(357deg) brightness(82%) contrast(113%)' : (darkMode ? 'invert(100%)' : null) }} alt={item.name} />}
      onClick={onClick}
    >
      {item.name}
    </Button>

  );
};

export default Logos;
