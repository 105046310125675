/* eslint-disable dot-notation */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import {
  FC, ReactElement, useEffect, useState
} from 'react';

import { useTranslation } from 'react-i18next';
import {
  useNavigate, useSearchParams
} from 'react-router-dom';

import {
  Box, Typography, Button, Pagination, Grid, IconButton, Slider
} from '@mui/material';

import {
  InfoOutlined,
  ShareOutlined as ShareIcon,
  SaveOutlined as SaveIcon,
} from '@mui/icons-material';
import {
  useAppStore, useProductStore, usePaginationStore, useUserStore
} from '../../state';

import { getCategoriesFromParams, getCurrentDate } from '../../utils';
import SearchLinkupBookmarkDialog from '../privatsearch/SearchLinkupBookmarkDialog';
import Card from '../ui/Card';
import SkeletonCard from '../ui/SkeletonCard';

const SearchMain:FC = ():ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSearchItem:GenericObject = { id: -1, name: '...loading' };
  const {
    token
  } = useUserStore((state:GenericObject) => state);
  const { showSkeleton } = useAppStore((state:GenericObject) => state);

  const [searchTerm, setSearchTerm] = useState('');
  const [positives, setPositives] = useState<string | null>(searchParams.get('positives') != null ? searchParams.get('positives') : '');
  const [negatives, setNegatives] = useState<string | null>(searchParams.get('negatives') != null ? searchParams.get('negatives') : '');
  const [openDialogLinkupBookmark, setOpenDialogLinkupBookmark] = useState(false);
  const [score, setScore] = useState<number | number[]>(30);
  const [orderBy, setOrderBy] = useState('score');
  const [order, setOrder] = useState('desc');
  const [categories, setCategories] = useState('');
  const [searchItem, setSearchItem] = useState(defaultSearchItem);

  const mailto = `mailto:?subject=Ergebnisliste%20aus%20Fragenkatalog%20von%20Digitalmeistern&body=Hallo,%0A%0Ahier ist der Link zur Ergebnisliste: ${window.location.href}`;

  const {
    prodSearch, searchResult
  } = useProductStore((state:GenericObject) => state);

  // Hook for Pagination, start it with init()
  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
    reset,
  } = usePaginationStore((state:GenericObject) => state);

  const sorter = (objectA:GenericObject, objectB:GenericObject) => {
    let compare1:any;
    let compare2:any;

    if (orderBy === 'alphabet') {
      if (order === 'asc') {
        compare1 = objectA.name.toLowerCase();
        compare2 = objectB.name.toLowerCase();
      } else {
        compare1 = objectB.name.toLowerCase();
        compare2 = objectA.name.toLowerCase();
      }
    } else if (orderBy === 'time') {
      if (order === 'asc') {
        compare1 = objectA.created;
        compare2 = objectB.created;
      } else {
        compare1 = objectB.created;
        compare2 = objectA.created;
      }
    } else if (orderBy === 'score') {
      compare1 = objectB.featureScore;
      compare2 = objectA.featureScore;
    } else {
      compare1 = objectB.created;
      compare2 = objectA.created;
    }
    return (compare1 > compare2) ? 1 : ((compare1 < compare2) ? -1 : 0);
  };

  // function getAreaCategories(resultlist: GenericObject) {
  //   let features: {id: number, label: string, [key: string]: any;}[] = [];
  //   resultlist.forEach((result: GenericObject) => {
  //     let subcats: GenericObject = result.categories?.find((item: GenericObject) => item.id === 275);
  //     subcats?.children?.forEach((subcat: GenericObject) => {
  //       features.push({ id: subcat.id, label: subcat.name });
  //     });
  //   });
  //   const uniqueFeatures = features.reduce((acc, current) => {
  //     if (!acc[current.id]) {
  //       acc[current.id] = current;
  //     }
  //     return acc;
  //   });
  //   console.log('sanitizedArray', Object.values(uniqueFeatures));
  //   return uniqueFeatures;
  // }

  function addOccurrence(resultlist:GenericObject) {
    if (positives != null) {
      let searchparams = positives.split(',').map(Number);
      resultlist.map((result: GenericObject) => {
        let features: number[] = [];
        let occurences: number[] = [];
        result.categories[4]?.children.forEach((items: { children: any[]; }) => {
          if (items.children.length > 0) {
            items.children.forEach((i: any) => {
              if (i.children.length > 0) {
                i.children.forEach((c: any) => {
                  features.push(c.id);
                });
              }
            });
          }
        });

        if (features != null && features.length > 0) {
          features.forEach((element: number) => {
            if (searchparams.includes(element)) {
              occurences.push(element);
            }
          });
        }
        // eslint-disable-next-line no-param-reassign
        result['occurences'] = occurences;
        // eslint-disable-next-line no-param-reassign
        result['featureScore'] = (occurences.length / (searchparams.length - 1)) * 100;
        return result;
      });
    }
    return resultlist.filter((r: { [x: string]: number; }) => r['featureScore'] >= score);
  }

  useEffect(() => {
  // Setzt die Übergebenen URL Parameter
    if (searchParams.get('positives') !== null) {
      setPositives(searchParams.get('positives'));
      useProductStore.setState({ searchCategories: getCategoriesFromParams(positives) });
      setCategories(getCategoriesFromParams(positives));
      if (positives !== null) {
        localStorage.setItem('positives', positives);
        localStorage.setItem('timeStamp', getCurrentDate());
      }
    }
    if (searchParams.get('negatives') != null) {
      setNegatives(searchParams.get('negatives'));
      useProductStore.setState({ searchCategories: getCategoriesFromParams(negatives) });
      setCategories(getCategoriesFromParams(negatives));
      if (negatives !== null) {
        localStorage.setItem('negatives', negatives);
      }
    }
  }, []);

  useEffect(() => {
    if (searchResult) {
      let addedScoreArray = addOccurrence(searchResult);
      // sort the search result locally because there are problems in API
      const sortedArray = addedScoreArray.sort((a:GenericObject, b:GenericObject) => sorter(a, b));
      // build the list und pagination new
      reset();
      init(sortedArray, 6, 1);
    }
  }, [searchResult, orderBy, order, score]);

  // trigger a new search
  useEffect(() => {
    const categoriesFilter = {
      solutionCategories: '999999',
      combiProductCategories: '999999',
      projectCategories: '999999',
    };
    categoriesFilter.solutionCategories = categories || '';
    categoriesFilter.combiProductCategories = categories || '';

    prodSearch({
      searchTerm,
      orderBy,
      order,
      ...categoriesFilter,
    });
  }, [searchTerm, categories]);

  const toggleDialogLinkupBookmark = () => setOpenDialogLinkupBookmark(!openDialogLinkupBookmark);
  const handleLogin = () => navigate('/login');
  const handleRegister = () => navigate('/register');
  const handleSave = () => {
    if (positives !== null && positives !== '') {
      localStorage.setItem('positives', positives);
      localStorage.setItem('timeStamp', getCurrentDate());
      if (negatives !== null && negatives !== '') {
        localStorage.setItem('negatives', negatives);
      }
      useAppStore.setState({ alert: { severity: 'success', text: 'Deine Suche wurde im lokalen Speicher des Browsers gesichert.' } });
    } else {
      useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Keine Parameter zum Speichern gefunden.' } });
    }
  };
  const handleShare = (e:any) => {
    window.location.href = mailto;
    e.preventDefault();
  };

  const handleLinkupBookmark = (item:GenericObject, mode:string) => {
    // if preview mode than open a modal to motivate user to registrate or login
    if (token) {
      setSearchItem({ ...item, mode });
      setOpenDialogLinkupBookmark(true);
      return true;
    }

    const dialogData = {
      title: { text: t('search.headerPreview') },
      contentItems: [
        {
          text: t('search.searchPreviewText'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.register'),
          color: 'error',
          onClick: handleRegister
        },
        {
          text: t('common.button.login'),
          color: 'error',
          onClick: handleLogin
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: 'Ergebnisliste aus Fragenkatalog' },
      contentItems: [
        {
          text: 'Die Ergebnisliste zeigt Einzel- und Kombiprodukte, welche auf Ihre Anforderungen aus dem Fragenkatalog passen.',
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  return (
    <>
      <SearchLinkupBookmarkDialog
        open={openDialogLinkupBookmark}
        onClose={toggleDialogLinkupBookmark}
        searchItem={searchItem}
      />
      <Box>
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12
        }}
        >
          <Typography variant="h1" fontWeight="bold">
            Persönliche Ergebnisliste aus digitalmeistern
          </Typography>
          <IconButton size="large" onClick={handleInfo}><InfoOutlined /></IconButton>
        </div>
        <Box
          component="div"
          sx={{
            mb: 6, display: 'flex', justifyContent: 'start', alignItems: 'center'
          }}
        >
          <Box sx={{ mr: 4 }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<SaveIcon />}
              sx={{
                my: 0.5, px: 2.5, py: 0.5, mr: 2
              }}
              onClick={() => handleSave}
            >
              Suche speichern
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                my: 0.5, px: 2.5, py: 0.5
              }}
              startIcon={<ShareIcon />}
              onClick={handleShare}
            >
              Suche teilen
            </Button>
          </Box>

          <Box component="div" width="40%">
            <Typography>
              {`Erfüllungsgrad: ${score}%`}
            </Typography>
            <Slider defaultValue={30} valueLabelDisplay="auto" min={0} max={100} step={10} marks onChangeCommitted={(e, val) => setScore(val)} />
          </Box>
        </Box>
        {showSkeleton ? (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }}>
            { [...Array(6)].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }}>
            {paginatedData && paginatedData.map((data: any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <Card
                  key={`card_${data.type}_${data.id}`}
                  item={data}
                  handleLinkupBookmark={handleLinkupBookmark}
                  {...data}
                />
              </Grid>
            ))}
          </Grid>
        ) }

        <Box sx={{ pt: 3, clear: 'both' }}>
          {countItems === 0 && (
            <Box>
              <Typography variant="h6" sx={{ whiteSpace: 'pre-line' }}>{t('search.searchExternalNoData')}</Typography>
            </Box>
          )}
          {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
        </Box>
      </Box>
    </>
  );
};

export default SearchMain;
