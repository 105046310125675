import { Navigate } from 'react-router-dom';

import SimpleLayout from './components/layout/simple/Layout';
import MainLayout from './components/layout/main/Layout';

import Login from './pages/Login';
import LandingPage from './pages/Landingpage';
import Register from './pages/Register';
import Preview from './pages/Preview';
import Bookmarks from './pages/Bookmarks';
import Comparison from './pages/Comparison';
import Portfolio from './pages/Portfolio';
import Search from './pages/Search';
import Requests from './pages/Requests';
import Projects from './pages/Projects';
import Matches from './pages/Matches';
import Matching from './pages/Matching';
import Blacklist from './pages/Blacklist';
import Offers from './pages/Offers';
import UserProfile from './components/profile/UserProfile';
import Profile from './pages/Profile';
import ExternalSearch from './pages/ExternalSearch';
import SearchForProject from './pages/SearchForProject';
import CompanyDetails from './pages/CompanyDetails';
import EditSingleProduct from './pages/EditSingleProduct';
import EditCombiProduct from './pages/EditCombiProduct';
import EditProject from './pages/EditProject';
import StartScreen from './pages/StartScreen';
import Imprint from './pages/Imprint';
import DataProtection from './pages/DataProtection';
import LayoutOverview from './pages/LayoutOverview';

const routes = [
  {
    path: 'app',
    element: <MainLayout />,
    children: [
      { path: '', element: <Navigate to="/app/startscreen" /> },
      { path: 'startscreen', element: <StartScreen /> },
      { path: 'user-profile', element: <UserProfile /> },
      { path: 'profile', element: <Profile /> },
      { path: 'bookmarks', element: <Bookmarks /> },
      { path: 'comparison', element: <Comparison /> },
      { path: 'companydetails', element: <CompanyDetails /> },
      { path: 'portfolio', element: <Portfolio /> },
      { path: 'layout', element: <LayoutOverview /> },
      { path: 'search', element: <Search /> },
      { path: 'searchforproject', element: <SearchForProject /> },
      { path: 'matches', element: <Matches /> },
      { path: 'requests', element: <Requests />, children: [{ path: 'incoming', element: <Requests /> }, { path: 'outgoing', element: <Requests /> }] },
      { path: 'projects', element: <Projects /> },
      { path: 'matching', element: <Matching /> },
      { path: 'blacklist', element: <Blacklist /> },
      { path: 'offers', element: <Offers /> },
      { path: 'edit-singleproduct', element: <EditSingleProduct /> },
      { path: 'edit-combiproduct', element: <EditCombiProduct /> },
      { path: 'edit-project', element: <EditProject /> },
      { path: 'imprint', element: <Imprint /> },
      { path: 'dataprotection', element: <DataProtection /> },
      { path: '*', element: <Navigate to="/app/startscreen" /> }
    ]
  },
  {
    path: '',
    element: <SimpleLayout />,
    children: [
      { path: '/', exact: true, element: <LandingPage /> },
      { path: 'login', element: <Login /> },
      { path: 'preview', element: <Preview /> },
      { path: 'externalsearch', element: <ExternalSearch /> },
      { path: 'register', element: <Register /> },
      { path: 'welcome/:token', element: <Register /> },
      { path: 'imprint', element: <Imprint /> },
      { path: 'dataprotection', element: <DataProtection /> },
      { path: '*', element: <Navigate to="/" /> },
    ]
  }
];

export default routes;
