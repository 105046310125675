/* eslint-disable react/require-default-props */
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Tooltip, Typography
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Check as CheckIcon,
  DoneAll as ReadIcon,
} from '@mui/icons-material';
import React from 'react';
import { getDateFromUnixTimestamp } from '../../utils';

export type ICardMessageAreaProps = {
    status: number;
    offerCreated: number;
    offerMessage: string;
    direction: string;
    handleRead?: () => void;
    handleOpenDetailCard: (tabValue: string) => void;
}

const CardMessageArea: React.FC<ICardMessageAreaProps> = (props: ICardMessageAreaProps) => {
  const {
    status,
    offerCreated,
    offerMessage,
    direction,
    handleRead,
    handleOpenDetailCard
  } = props;

  const getStatusIcon = () => {
    switch (status) {
      case 10:
        return <CheckIcon fontSize="small" />;
      case 20:
        return <ReadIcon fontSize="small" />;
      default:
        return <CheckIcon fontSize="small" />;
    }
  };

  const getStatusLabel = () => {
    switch (status) {
      case 10:
        return 'ungelesen';
      case 20:
        return 'gelesen';
      case 30:
        return 'in Progress';
      case 40:
        return 'Pending';
      default:
        return '';
    }
  };

  return (
    <Box
      onClick={() => handleOpenDetailCard('Nachricht')}
      sx={{
        display: 'flex', alignItems: 'center', p: 2, cursor: 'pointer'
      }}
    >
      <Typography variant="body2" fontWeight={status === 10 && direction === 'incoming' ? 'bold' : 'inherit'}>
        {`Nachricht vom: ${getDateFromUnixTimestamp(offerCreated)}`}
      </Typography>
      <Tooltip title={getStatusLabel()}>
        {getStatusIcon()}
      </Tooltip>
    </Box>

  );
};

export default CardMessageArea;
