/* eslint-disable react/jsx-props-no-spreading */
import {
  FC, ReactElement, useState
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Tabs, Tab, IconButton,
} from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';

import { useAppStore } from '../../state';
import UserProfile from './UserProfile';
import CompanyProfileMain from '../companyprofile/CompanyProfile';

interface TabPanelProps {
  value?: number,
  index?: number
}

const ProfileTabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    role="tabpanel"
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    /* eslint-disable react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && (
      <Box sx={{ pt: 3 }}>
        {children}
      </Box>
    )}
  </div>
);

ProfileTabPanel.defaultProps = {
  index: 0,
  value: 0,
};

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const ProfileMain: FC = (): ReactElement => {
  const { t } = useTranslation();

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('company.info.header') },
      contentItems: [
        {
          text: t('company.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
      }}
      >
        <Typography
          variant="h1"
          fontWeight="bold"
        >
          { t('profile.header') }
        </Typography>
        <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfo}><InfoOutlined /></IconButton>
      </div>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={t('company.info.header')} {...a11yProps(0)} />
          <Tab label="Account" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <ProfileTabPanel value={value} index={0}>
        <CompanyProfileMain />
      </ProfileTabPanel>
      <ProfileTabPanel value={value} index={1}>
        <UserProfile />
      </ProfileTabPanel>
    </Box>
  );
};
export default ProfileMain;
