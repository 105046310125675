/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useEffect, useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { useTranslation } from 'react-i18next';

import {
  Button, Box, Typography
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { useAppStore } from '../../state';

const UploadAndDisplayImage = (props: GenericObject):ReactElement => {
  const [images, setImages] = useState<ImageListType>([]);
  const [image, setImage] = useState<string | undefined>('');
  const [activateUpload, setActivateUpload] = useState(false);

  const { t } = useTranslation();

  let sxImageUploadBox: GenericObject = {
    width: '100%',
    maxWidth: '400px',
    marginX: 'auto',
    paddingX: {
      xs: '15px',
      lg: '25px'
    },
    // paddingTop: '25px',
    paddingBottom: '15px',
    border: '1px dashed transparent',
    borderRadius: '4px',
  };

  if (activateUpload === true) {
    sxImageUploadBox = {
      ...sxImageUploadBox, backgroundColor: '#D0D0D020', borderColor: '#00000020'
    };
  }

  const sxUploadText: GenericObject = {
    color: 'common.white',
    textAlign: 'center',
    fontWeight: 'bold',
    width: '100%',
    marginBottom: '10px',
    fontSize: '14px'
  };

  const sxUploadCaptionText: GenericObject = {
    color: 'common.white',
    textAlign: 'center',
    width: '100%',
    fontSize: '12px'
  };

  const {
    onSaveImage, onChangeImage, onDeleteImage, currentImage, defaultImage
  } = props;

  const onSave = () => {
    if (!image) return;

    const strImage = image.replace(/^data:image\/[a-z]+;base64,/, '');
    onSaveImage(strImage);
  };

  const onDelete = () => {
    const dialogData = {
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.confirmDeleteImage'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.delete'),
          color: 'error',
          onClick: () => {
            onDeleteImage();
            setImage(defaultImage);
            setActivateUpload(false);
          }
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const onChange = (
    imageList: ImageListType,
  ) => {
    setImages(imageList);
    if (!imageList[0]) return;
    setImage(imageList[0].dataURL);

    if (onChangeImage) {
      if (!imageList[0].dataURL) return;
      const strImage = imageList[0].dataURL.replace(/^data:image\/[a-z]+;base64,/, '');
      onChangeImage(strImage);
    }
  };

  useEffect(() => {
    setImage(currentImage || defaultImage);
  }, []);

  return (
    <div className="App">

      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={1}
        acceptType={['jpg']}
      >
        {({
          imageList,
          onImageUpdate,
          onImageUpload,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          <Box
            sx={sxImageUploadBox}
          >
            <Box
              sx={{
                position: 'relative',
                width: {
                  xs: '150px',
                  lg: '200px'
                },
                height: {
                  xs: '150px',
                  lg: '200px'
                },
                marginX: 'auto',
                marginBottom: '30px'
              }}
            >
              <img
                src={image}
                alt=""
                height="100%"
                width="100%"
                style={{
                  objectFit: 'cover',
                  borderRadius: '100%',
                  overflow: 'hidden',
                  border: '1px solid #000'
                }}
              />
              {activateUpload && (
                <Box
                  sx={{
                    position: 'absolute',
                    backgroundColor: 'rgba(0,0,0,.5)',
                    borderRadius: '50%',
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <Typography sx={sxUploadText}>
                    {t('common.input.uploadImage')}
                  </Typography>
                  <Typography sx={sxUploadCaptionText}>
                    {t('common.input.imageTypeInfo')}
                  </Typography>
                </Box>
              )}
              {!activateUpload && (
                <Button
                  onClick={() => setActivateUpload(true)}
                  style={{
                    padding: '10px',
                    width: '45px',
                    minWidth: '45px',
                    height: '45px',
                    borderRadius: '100px',
                    backgroundColor: '#B50900',
                    position: 'absolute',
                    right: '10px',
                    bottom: '10px',
                    zIndex: 9
                  }}
                >
                  <EditIcon sx={{ color: 'white' }} />
                </Button>
              )}
            </Box>
            {activateUpload && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                sx={{
                  ml: 'auto', mr: 'auto', mb: 1, mt: 2, px: 2.5, py: 0.5, display: 'block'
                }}
                onClick={() => {
                  onDelete();
                }}
              >
                {t('common.button.deleteImage')}
              </Button>
            )}

            {activateUpload && (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                <Box sx={{ display: 'inline-block', pr: 2 }}>
                  <Button
                    onClick={() => {
                      setActivateUpload(false);
                    }}
                    sx={{ px: 2.5, py: 0.5 }}
                    type="button"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    style={{ marginBottom: 10 }}
                  >
                    {t('common.button.close')}

                  </Button>
                </Box>
                <Box sx={{ display: 'inline-block' }}>
                  <Button
                    sx={{ px: 2.5, py: 0.5 }}
                    onClick={() => {
                      onSave();
                      setActivateUpload(false);
                    }}
                    type="button"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    style={{ marginBottom: 10 }}
                  >
                    {t('common.button.saveImage')}
                  </Button>
                </Box>
              </Box>
            )}

          </Box>
        )}
      </ImageUploading>
    </div>
  );
};

export default UploadAndDisplayImage;
