import { FC, ReactElement, useEffect } from 'react';

import { useProductStore, useUserStore } from '../state';

import ProjectsMain from '../components/projects/ProjectsMain';

const Projects: FC = (): ReactElement => {
  // load all important data from api to display it on the startpage
  useEffect(() => {
    useProductStore.getState().prodGetCategories();
    useUserStore.getState().userGetAccountData();
  }, []);

  return (
    <ProjectsMain />
  );
};

export default Projects;
