import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';

import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';

const BackToStart: FC = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToPreviousPath = () => {
    navigate(-1);
  };

  return (

    <Button sx={{ ml: '-15px', mb: 2 }} onClick={goToPreviousPath} startIcon={<ChevronLeftIcon sx={{ color: '#B50900' }} />}>
      <Typography sx={{ color: '#B50900' }}>{t('common.button.backToStart')}</Typography>
    </Button>

  );
};

export default BackToStart;
