/* eslint-disable no-nested-ternary */
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Box, Button, Card, CardActionArea, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Typography
} from '@mui/material';

import {
  Close as CloseIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import placeholderCompany from '../../assets/images/placeholder_company.png';

import { useAppStore, useProductStore } from '../../state';

const CompareDialog = ():ReactElement => {
  const app = useAppStore((state:GenericObject) => state);
  const { appSetShowCompareDialog, showCompareDialog } = app;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [uniqueBookmarks, setUniqueBookmarks] = useState<any>([]);

  const {
    bookmarks,
    compareList,
    addIdToCompareList,
    removeIdFromCompareList,
  } = useProductStore((state: GenericObject) => state);

  function removeDuplicates(arr: []) {
    const seenIds = new Set();
    return arr.filter((item: GenericObject) => {
      if (seenIds.has(item.itemId)) {
        return false;
      }
      seenIds.add(item.itemId);
      return item;
    });
  }

  useEffect(() => {
    setUniqueBookmarks(removeDuplicates(bookmarks));
  }, [bookmarks.length]);

  const isinCompareList = (id:number) => compareList.includes(id);

  const handleClose = () => {
    appSetShowCompareDialog(false);
  };

  const handleCheckForCompare = (id:number) => {
    if (isinCompareList(id)) {
      removeIdFromCompareList(id);
    } else {
      addIdToCompareList(id);
    }
  };

  const handleCompare = () => {
    appSetShowCompareDialog(false);
    navigate('app/comparison');
  };

  const sxSelected = {
    width: '100%',
    mb: 2,
    border: 2,
    borderColor: 'primary.main'
  };
  const sxNotSelected = {
    width: '100%',
    mb: 2,
  };

  return (
    <>
      <Dialog
        open={showCompareDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          Vergleich
          <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 20, top: 20 }}>
            <CloseIcon style={{ fontSize: 20 }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box paddingLeft={{ xs: 3, md: 5 }} paddingRight={{ xs: 3, md: 5 }}>
            <Typography>Produkte in der Merkliste</Typography>
            <Paper
              variant="outlined"
              sx={{
                maxHeight: 400, overflow: 'auto', padding: 3, bgcolor: 'secondary.main'
              }}
              square
            >
              {uniqueBookmarks !== undefined ? uniqueBookmarks.map((el:any) => (
                <Card sx={isinCompareList(el.itemId) ? sxSelected : sxNotSelected}>
                  <CardActionArea onClick={() => handleCheckForCompare(el.itemId)}>
                    <CardContent>
                      <Box
                        paddingY={{ xs: 2, lg: 2.5 }}
                        marginY={{ md: 1 }}
                        sx={{
                          display: 'flex', alignItems: 'center', flexGrow: '1', cursor: 'pointer'
                        }}
                      >
                        <Avatar src={el.company?.logo ?? placeholderCompany} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
                        <div style={{ flexGrow: 1, marginLeft: 20 }}>
                          <Typography fontSize={8} color="black" fontWeight="600" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}>
                            {el.type === 'solution'
                              ? t('card.type.singleproduct')
                              : (el.type === 'combiProduct' ? t('card.type.combiproduct') : t('card.type.request'))}
                          </Typography>
                          <Typography variant="h5" color="black" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}>{el.name}</Typography>
                          {(el.type === 'solution' || el.type === 'combiProduct') && <Typography fontSize={10} color="black" fontWeight="600" letterSpacing="0.5px">{el.company?.name}</Typography>}
                        </div>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>

              )) : 'Merkliste leer'}
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 5, pb: 2 }}>
          <Button type="submit" onClick={handleClose} style={{ marginBottom: '10px' }} variant="contained" color="secondary">
            {t('common.button.close')}
          </Button>
          {compareList.length > 1 ? (
            <Button type="submit" onClick={handleCompare} style={{ marginBottom: '10px' }} variant="contained" color="primary">
              Vergleichen (
              {compareList.length}
              )
            </Button>
          ) : (
            <Button type="submit" style={{ marginBottom: '10px' }} variant="contained" color="primary" disabled>
              Vergleichen
            </Button>
          )}

        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompareDialog;
