import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Typography, Link, Grid, Box, Button, SvgIcon, Card, CardContent, CardHeader, CardActions, Divider
} from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const KIWCTATile = (props: GenericObject):ReactElement => {
  const { item } = props;
  const {
    title, subtitle, src, src2, image
  } = item;

  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Card>
        <CardHeader title={title} />
        <CardContent>
          <Box
            paddingY={{ xs: 1.5, lg: 3 }}
            paddingX={{ xs: 1, lg: 3 }}
            sx={{
              display: 'flex', alignItems: 'center', flexGrow: '1'
            }}
          >
            <SvgIcon component={image} sx={{ pr: 1, color: 'primary.main', fontSize: 70 }} />
            <div style={{ flexGrow: 1, marginLeft: 20 }}>
              <Typography fontSize={13} color="black" fontWeight="400" letterSpacing="0.5px">{subtitle}</Typography>
            </div>
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Link sx={{ textDecoration: 'none' }} href={src} target="_blank" rel="noreferrer">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 2.5, py: 0.5
              }}
            >
              {t('productRange.button')}
              <OpenInNewIcon sx={{ fontSize: 16, ml: 1 }} />
            </Button>
          </Link>
          {title === 'IT-Recht & Datenschutz' && (
          <Link sx={{ textDecoration: 'none' }} href={src2} target="_blank" rel="noreferrer">
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 2.5, py: 0.5
              }}
            >
              Zum Vertragsgenerator
              <OpenInNewIcon sx={{ fontSize: 16, ml: 1 }} />
            </Button>
          </Link>
          )}
          {title === 'Schnittstellen' && (
            <Link sx={{ textDecoration: 'none' }} href={src2} target="_blank" rel="noreferrer">
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mr: 2, px: 2.5, py: 0.5
                }}
              >
                Zum Schnittstellenkonfigurator
                <OpenInNewIcon sx={{ fontSize: 16, ml: 1 }} />
              </Button>
            </Link>
          )}

        </CardActions>
      </Card>
    </Grid>
  );
};
export default KIWCTATile;
