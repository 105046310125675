import { ReactElement, useEffect } from 'react';

import CategoryBlock from './CategoryBlock';

const Categories = (props:GenericObject):ReactElement => {
  const {
    tree,
    filterTree,
    toggleAllVisibleCategories,
    countMainCategories,
  } = props;

  let nameMainCategory = '';
  if (filterTree === 1) nameMainCategory = 'industries';
  if (filterTree === 121) nameMainCategory = 'functionalities';
  if (filterTree === 183) nameMainCategory = 'moreFeatures';
  if (filterTree === 275) nameMainCategory = 'characteristics';

  const arrayMainCategories = filterTree ? [nameMainCategory] : ['industries', 'functionalities', 'characteristics', 'moreFeatures'];

  useEffect(() => {
    countMainCategories();
  }, []);

  return (
    <>
      {arrayMainCategories.map((type):ReactElement => (
        <CategoryBlock
          key={type}
          type={type}
          treeElement={tree[type]}
          toggleAllVisibleCategories={toggleAllVisibleCategories}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />

      ))}
    </>
  );
};

export default Categories;
