/* eslint-disable max-len */
import { FC, ReactElement } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Avatar, Box, Typography, List, ListItemText, ListSubheader, Tooltip, Grid, IconButton, Divider, Badge, ListItemButton
} from '@mui/material';

import {
  Home as HomeIcon,
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  Security as SecurityIcon,
  YouTube as YouTubeIcon,
  ChevronRight as ChevronRightIcon,
  BusinessCenter as BusinessCenterIcon,
  Inbox as InboxIcon,
  Bookmark as BookmarkIcon,
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  Inventory as InventoryIcon,
  ManageSearch as ManageSearchIcon,
  Extension as MatchingIcon,
  AccountBalance as ImprintIcon,
} from '@mui/icons-material';

import XIcon from '@mui/icons-material/X';

import { useAppStore, useProductStore, useUserStore } from '../../../state';
import PlaceholderLogoCompany from '../../../assets/images/placeholder_company.png';

const footerColumnTitle: GenericObject = {
  fontSize: '10px',
  mb: 1.5,
  fontWeight: '600',
  textTransform: 'uppercase'
};

const Navigation: FC = (): ReactElement => {
  const { t } = useTranslation();
  const user = useUserStore((state:GenericObject) => state);
  const drawerIsOpened = useAppStore((state:GenericObject) => state.drawerIsOpened);
  const { offersIncoming } = useProductStore((state:GenericObject) => state);

  let nameCompany = '...loading';

  // get the name of the page to highlight menu items and icons
  const page = useLocation().pathname.split('/')[2];

  const name = user?.company?.name;
  const logoCompany = user?.company?.logo;

  if (name) {
    nameCompany = `${name}`;
  }

  const footerSocialIconSx: GenericObject = {
    borderWidth: '1px',
    borderStyle: 'solid ',
    borderRadius: '50%',
    mx: 0.2,
    width: '40px',
    height: '40px',
    color: 'text.primary'
  };

  const footerSocialIconSvgSx: GenericObject = {
    width: '20px',
    height: '20px'
  };

  const sxButtom: GenericObject = {
    display: 'flex',
    mt: 1,
    textAlign: 'center',
  };

  let sxProfile: GenericObject = {
    ml: 1, opacity: 1, height: '50px', whiteSpace: 'nowrap'
  };

  let sxText: GenericObject = {
    ml: 3, fontWeight: '700', fontSize: '14px'
  };

  let sxSubHeader: GenericObject = {
    ...sxProfile, mt: 1, ml: 0, height: 'auto', whiteSpace: 'nowrap', mb: '4px', px: 2
  };

  let sxProfileLink: GenericObject = {
    display: 'block',
    px: 2,
    py: 1,
    mt: 1,
    borderRadius: 1,
  };

  let sxProfileAvtar: GenericObject = {
    display: 'flex',
  };

  let sxLink: GenericObject = {
    display: 'flex',
    px: 2,
    py: 0.3,
    mb: 1,
    borderRadius: 1,
  };

  if (!drawerIsOpened) {
    sxProfile = {
      ...sxProfile, opacity: 0, height: 0, overflow: 'hidden'
    };
    sxSubHeader = {
      ...sxSubHeader, opacity: 0, height: 0, overflow: 'hidden', marginBottom: '0'
    };
    sxText = {
      ...sxText, opacity: 0
    };
    sxProfileLink = {
      ...sxProfileLink,
    };
    sxLink = {
      ...sxLink, justifyContent: 'center'
    };
    sxProfileAvtar = {
      ...sxProfileAvtar, justifyContent: 'center'
    };
  }

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', fontSize: '14px', minHeight: '95vh', justifyContent: 'space-between', mx: 1
    }}
    >
      <Box>
        <ListItemButton
          id="nav_profile"
          sx={sxProfileLink}
          selected={page === 'profile'}
          component={RouterLink}
          to="/app/profile"
        >
          <Box sx={sxProfileAvtar}>
            <Tooltip title={`${nameCompany}`}>
              <Avatar alt="Company Logo" sx={{ border: '1px solid #000' }} src={logoCompany || PlaceholderLogoCompany} />
            </Tooltip>
            {drawerIsOpened && (
            <Box sx={sxProfile}>
              <Typography sx={{
                fontWeight: 'bold', fontSize: 'inherit', overflow: 'hidden', textOverflow: 'ellipsis', width: '13rem',
              }}
              >
                {nameCompany}
              </Typography>
              <Typography sx={{ fontSize: 'inherit' }}>{t('navigationDrawer.button.showProfile')}</Typography>
            </Box>
            )}
          </Box>
        </ListItemButton>

        <List component="nav">
          <Divider />
          <ListSubheader component="div" sx={sxSubHeader} color="primary" disableSticky>
            IT2match
          </ListSubheader>
          <ListItemButton
            id="nav_start"
            sx={sxLink}
            selected={page === 'startscreen'}
            component={RouterLink}
            to="/app/startscreen"
            key="0820"
          >
            <Tooltip title="Startseite">
              <HomeIcon color={page === 'startscreen' ? 'primary' : undefined} />
            </Tooltip>
            {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>Start</Typography>
            </ListItemText>
            )}
            {drawerIsOpened && <ChevronRightIcon color="primary" />}
          </ListItemButton>
          <ListItemButton
            id="nav_matching"
            sx={sxLink}
            selected={page === 'matching'}
            component={RouterLink}
            to="/app/matching"
            key="0815"
          >
            <Tooltip title={`${t('bottomNavigation.matching')}`}>
              <MatchingIcon color={page === 'matching' ? 'primary' : undefined} />
            </Tooltip>
            {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('bottomNavigation.matching')}</Typography>
            </ListItemText>
            )}
            {drawerIsOpened && <ChevronRightIcon color="primary" />}
          </ListItemButton>
          <ListItemButton
            id="nav_search"
            sx={sxLink}
            selected={page === 'search'}
            component={RouterLink}
            to="/app/search"
            key="0816"
          >
            <Tooltip title={`${t('bottomNavigation.search')}`}>
              <SearchIcon
                color={page === 'search' ? 'primary' : undefined}
              />
            </Tooltip>
            {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('bottomNavigation.search')}</Typography>
            </ListItemText>
            )}
            {drawerIsOpened && <ChevronRightIcon color="primary" />}
          </ListItemButton>

        </List>

        <List component="nav">
          <Divider />
          <ListSubheader component="div" sx={sxSubHeader} color="primary" disableSticky>
            {t('navigationDrawer.header.section1')}
          </ListSubheader>

          <ListItemButton
            id="nav_portfolio"
            sx={sxLink}
            selected={page === 'portfolio' || page === 'edit-singleproduct' || page === 'edit-combiproduct'}
            component={RouterLink}
            to="/app/portfolio"
            key="0817"
          >
            <Tooltip title={`${t('navigationDrawer.button.portfolio')}`}>
              <BusinessCenterIcon color={page === 'portfolio' ? 'primary' : undefined} />
            </Tooltip>
            {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('navigationDrawer.button.portfolio')}</Typography>
            </ListItemText>
            )}
            {drawerIsOpened && <ChevronRightIcon color="primary" />}
          </ListItemButton>

          <ListItemButton
            id="nav_projects"
            sx={sxLink}
            selected={page === 'projects'}
            component={RouterLink}
            to="/app/projects"
            key="0816"
          >
            <Tooltip title={`${t('navigationDrawer.button.request')}`}>
              <ManageSearchIcon color={page === 'projects' ? 'primary' : undefined} />
            </Tooltip>
            {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('navigationDrawer.button.request')}</Typography>
            </ListItemText>
            )}
            {drawerIsOpened && <ChevronRightIcon color="primary" />}
          </ListItemButton>
        </List>

        <List component="nav">
          <Divider />
          <ListSubheader component="div" sx={sxSubHeader} color="primary" disableSticky>
            {t('navigationDrawer.header.section2')}
          </ListSubheader>

          <ListItemButton
            id="nav_requests"
            sx={sxLink}
            selected={page === 'requests'}
            component={RouterLink}
            to="/app/requests/incoming"
            key="07007"
          >
            <Tooltip title={`${t('navigationDrawer.button.offers')}`}>
              <Badge badgeContent={offersIncoming.filter((offer: any) => offer.status === 10).length} color="primary">
                <InboxIcon color={page === 'requests' ? 'primary' : undefined} />
              </Badge>
            </Tooltip>
            {drawerIsOpened && (
              <ListItemText>
                <Typography sx={sxText}>{t('navigationDrawer.button.offers')}</Typography>
              </ListItemText>
            )}
            {drawerIsOpened && (
              <ChevronRightIcon color="primary" />
            )}
          </ListItemButton>

          <ListItemButton
            id="nav_bookmarks"
            sx={sxLink}
            selected={page === 'bookmarks'}
            component={RouterLink}
            to="/app/bookmarks"
            key="0811"
          >
            <Tooltip title={`${t('navigationDrawer.button.bookmarks')}`}>
              <BookmarkIcon color={page === 'bookmarks' ? 'primary' : undefined} />
            </Tooltip>
            {drawerIsOpened && (
              <ListItemText>
                <Typography sx={sxText}>{t('navigationDrawer.button.bookmarks')}</Typography>
              </ListItemText>
            )}
            {drawerIsOpened && <ChevronRightIcon color="primary" />}
          </ListItemButton>

          <ListItemButton
            id="nav_matches"
            sx={sxLink}
            selected={page === 'matches'}
            component={RouterLink}
            to="/app/matches"
            key="0819"
          >
            <Tooltip title={`${t('navigationDrawer.button.matches')}`}>
              <CheckCircleIcon color={page === 'matches' ? 'primary' : undefined} />
            </Tooltip>
            {drawerIsOpened && (
              <ListItemText>
                <Typography sx={sxText}>{t('navigationDrawer.button.matches')}</Typography>
              </ListItemText>
            )}
            {drawerIsOpened && <ChevronRightIcon color="primary" />}
          </ListItemButton>

          <ListItemButton
            id="nav_blacklist"
            sx={sxLink}
            selected={page === 'blacklist'}
            component={RouterLink}
            to="/app/blacklist"
            key="08007"
          >
            <Tooltip title={`${t('navigationDrawer.button.blacklist')}`}>
              <InventoryIcon color={page === 'blacklist' ? 'primary' : undefined} />
            </Tooltip>
            {drawerIsOpened && (
              <ListItemText>
                <Typography sx={sxText}>{t('navigationDrawer.button.blacklist')}</Typography>
              </ListItemText>
            )}
            {drawerIsOpened && <ChevronRightIcon color="primary" />}
          </ListItemButton>
        </List>
      </Box>
      <Box>
        <Divider />
        <List component="nav">
          <ListItemButton
            sx={sxLink}
            selected={page === 'imprint'}
            component={RouterLink}
            to="/app/imprint"
            key="0890"
          >
            <Tooltip title={`${t('footer.text.imprint')}`}>
              <ImprintIcon color={page === 'imprint' ? 'primary' : undefined} />
            </Tooltip>
            {drawerIsOpened && (
            <ListItemText>
              <Typography sx={sxText}>{t('footer.text.imprint')}</Typography>
            </ListItemText>
            )}
          </ListItemButton>
          <ListItemButton
            sx={sxLink}
            selected={page === 'dataprotection'}
            component={RouterLink}
            to="/app/dataprotection"
            key="0891"
          >
            <Tooltip title={`${t('footer.text.privacy')}`}>
              <SecurityIcon color={page === 'dataprotection' ? 'primary' : undefined} />
            </Tooltip>
            {drawerIsOpened && (
              <ListItemText>
                <Typography sx={sxText}>{t('footer.text.privacy')}</Typography>
              </ListItemText>
            )}
          </ListItemButton>
        </List>
        <Divider />
        <Grid container sx={sxButtom} justifyContent="center">
          <Grid item xs={12} md={12}>
            {drawerIsOpened && (
            <Typography sx={footerColumnTitle}>{t('footer.text.follow')}</Typography>
            )}
            <Box>
              <IconButton href="https://twitter.com/itwirtschaft?lang=de" sx={footerSocialIconSx} target="_blank" aria-label="IT2match auf X">
                <XIcon sx={footerSocialIconSvgSx} />
              </IconButton>
              <IconButton href="https://www.facebook.com/KIWirtschaft/" sx={footerSocialIconSx} target="_blank" aria-label="IT2match auf Facebook">
                <FacebookIcon sx={footerSocialIconSvgSx} />
              </IconButton>
              <IconButton href="https://de.linkedin.com/company/kompetenzzentrum-it-wirtschaft" sx={footerSocialIconSx} target="_blank" aria-label="IT2match auf LinkedIn">
                <LinkedInIcon sx={footerSocialIconSvgSx} />
              </IconButton>
              <IconButton href="https://www.youtube.com/channel/UCLdGM-ICYj_-iiMotyO4tJg" sx={footerSocialIconSx} target="_blank" aria-label="IT2match auf YouTube">
                <YouTubeIcon sx={footerSocialIconSvgSx} />
              </IconButton>
              <IconButton href="https://podcasts.apple.com/de/podcast/kooperationen-in-der-it-wirtschaft/id1489448932" sx={footerSocialIconSx} target="_blank" aria-label="IT2match auf Apple Podcast">
                <svg width="16" height="16" aria-hidden="true" data-prefix="fas" data-icon="podcast" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M267.429 488.563C262.286 507.573 242.858 512 224 512c-18.857 0-38.286-4.427-43.428-23.437C172.927 460.134 160 388.898 160 355.75c0-35.156 31.142-43.75 64-43.75s64 8.594 64 43.75c0 32.949-12.871 104.179-20.571 132.813zM156.867 288.554c-18.693-18.308-29.958-44.173-28.784-72.599 2.054-49.724 42.395-89.956 92.124-91.881C274.862 121.958 320 165.807 320 220c0 26.827-11.064 51.116-28.866 68.552-2.675 2.62-2.401 6.986.628 9.187 9.312 6.765 16.46 15.343 21.234 25.363 1.741 3.654 6.497 4.66 9.449 1.891 28.826-27.043 46.553-65.783 45.511-108.565-1.855-76.206-63.595-138.208-139.793-140.369C146.869 73.753 80 139.215 80 220c0 41.361 17.532 78.7 45.55 104.989 2.953 2.771 7.711 1.77 9.453-1.887 4.774-10.021 11.923-18.598 21.235-25.363 3.029-2.2 3.304-6.566.629-9.185zM224 0C100.204 0 0 100.185 0 224c0 89.992 52.602 165.647 125.739 201.408 4.333 2.118 9.267-1.544 8.535-6.31-2.382-15.512-4.342-30.946-5.406-44.339-.146-1.836-1.149-3.486-2.678-4.512-47.4-31.806-78.564-86.016-78.187-147.347.592-96.237 79.29-174.648 175.529-174.899C320.793 47.747 400 126.797 400 224c0 61.932-32.158 116.49-80.65 147.867-.999 14.037-3.069 30.588-5.624 47.23-.732 4.767 4.203 8.429 8.535 6.31C395.227 389.727 448 314.187 448 224 448 100.205 347.815 0 224 0zm0 160c-35.346 0-64 28.654-64 64s28.654 64 64 64 64-28.654 64-64-28.654-64-64-64z" />
                </svg>
              </IconButton>
            </Box>
          </Grid>
          <Typography
            noWrap
            sx={{
              mt: 2, color: 'text.primary', fontSize: '12px', textAlign: 'center',
            }}
          >
            {`© ${new Date().getFullYear()} ${t('footer.text.companyName')}`}
          </Typography>
        </Grid>
      </Box>
    </Box>
  );
};

export default Navigation;
