import {
  FC, ReactElement, useEffect, useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Button, Grid, Divider, Card, CardContent, CardActions, CardActionArea, CardHeader, Link, CardMedia, List, ListItem, Paper, useTheme
} from '@mui/material';

import { useNavigate } from 'react-router';
import {
  DeleteOutline as DeleteIcon,
  AddOutlined as AddIcon,
  ManageSearchOutlined as SearchIcon,
} from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import dmlogo from '../../assets/images/digitalmeistern_logo.svg';
import dmlogowhite from '../../assets/images/digitalmeistern_logo_white.svg';
import { useAppStore, useProductStore, useUserStore } from '../../state';
import { getCategoriesFromParams } from '../../utils';

const StartScreenMain: FC = (): ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [positives, setPositives] = useState<string | null>(localStorage.getItem('positives') !== null ? localStorage.getItem('positives') : '');
  const [negatives, setNegatives] = useState<string | null>(localStorage.getItem('negatives') !== null ? localStorage.getItem('negatives') : '');

  const [incomingOffers, setIncomingOffers] = useState([]);
  const [outgoingOffers, setOutgoingOffers] = useState([]);

  const timeStamp = localStorage.getItem('timeStamp');

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDeleteLocalStorage = () => {
    localStorage.removeItem('positives');
    localStorage.removeItem('negatives');
    localStorage.removeItem('timeStamp');
    navigate('/');
  };

  const {
    offers,
    combiProducts,
    offersCombiProducts,
  } = useProductStore((state: GenericObject) => state);

  const { darkMode, posts } = useAppStore((state: GenericObject) => state);

  const handleNew = (type: string) => {
    useProductStore.setState({ currentProductProject: null });

    if (type === 'project') {
      navigate('/app/edit-project');
    } else if (type === 'singleproduct') {
      navigate('/app/edit-singleproduct');
    } else if (type === 'combiproduct') {
      navigate('/app/edit-combiproduct');
    }
  };

  const handleShowResultList = () => {
    window.location.href = `${window.location.origin}/app/searchforproject?positives=${positives}`;
  };

  const handleAddAplicationFromLocalStorage = () => {
    useProductStore.setState({ currentProductProject: null });
    useProductStore.setState({ searchCategories: getCategoriesFromParams(positives) });
    navigate('/app/edit-project');
  };

  const user = useUserStore((state:GenericObject) => state);

  useEffect(() => {
  // setComplitionValueCompany(getCompletionValueForCompany(company));
    const offersCombiOutgoing = combiProducts
      .filter((item: any) => item.status === 10)
      .map((item: any) => {
        const obj = {
          ...item,
          direction: 'outgoing'
        };
        return obj;
      });

    const offersCombiIncoming = offersCombiProducts
    // .filter((item: any) => item.status === 10)
      .map((item: any) => {
        const obj = {
          ...item,
          direction: 'incoming'
        };
        return obj;
      });

    const outgoing:any = [...offers.filter((item: any) => item.direction === 'outgoing'), ...offersCombiOutgoing];
    const inOffers:any = [...offers.filter((item: any) => item.direction === 'incoming'), ...offersCombiIncoming];
    const outOffers = outgoing;

    setIncomingOffers(inOffers);
    setOutgoingOffers(outOffers);
  }, []);

  return (
    <Box>
      {(user.logincount >= 3 || process.env.REACT_APP_ENV === 'DEV') && (
      <>
        <Typography variant="h1" fontWeight="bold" marginBottom={{ lg: 1 }}>Willkommen auf IT2match</Typography>
        <Typography variant="subtitle1" color="primary" marginBottom={{ lg: 3 }}>
          IT works better together!
          {' '}
        </Typography>
        <Divider />
      </>
      )}
      <Box>
        <Grid container spacing={{ xs: 2, sm: 4 }} paddingTop={{ sm: 3, xs: 3 }}>
          {positives && positives?.length > 0 && (
          <Grid item xs={12} lg={12}>
            <Card style={{ flexGrow: 1 }}>
              <CardHeader
                title="Suche aus digitalmeistern"
                titleTypographyProps={{
                  variant: 'h5', color: 'text', gutterBottom: true, fontWeight: 'bold'
                }}
              />
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center' }}>
                  { darkMode ? (<img src={dmlogowhite} width="250px" alt="logo-digitalmeistern_white" style={{ marginRight: 20 }} />) : (<img src={dmlogo} width="250px" alt="logo-digitalmeistern" style={{ marginRight: 20 }} />) }
                  <Typography component="div" variant="body1" color="text" gutterBottom>
                    Wir haben in Ihrem Browser Informationen zu einer Suche vom
                    <b>
                      {' '}
                      {timeStamp}
                      {' '}
                    </b>
                    gefunden.
                    <br />
                    Sie können auf Basis dieser Suche ein Gesuch auf IT2match anlegen.
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions sx={{
                py: { xs: 1.2, sm: 2 }, px: { xs: 2, sm: 3 }, display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row'
              }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    mb: isSmallScreen ? 1 : 0,
                    mr: isSmallScreen ? 0 : 2,
                    px: 1.5,
                    py: 0.5,
                  }}
                  onClick={handleAddAplicationFromLocalStorage}
                  startIcon={<AddIcon />}
                >
                  Gesuch erstellen
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    mb: isSmallScreen ? 1 : 0,
                    mr: isSmallScreen ? 0 : 2,
                    px: 1.5,
                    py: 0.5,
                  }}
                  onClick={handleShowResultList}
                  startIcon={<SearchIcon />}
                >
                  Ergebnisliste anzeigen
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    mb: isSmallScreen ? 1 : 0,
                    mr: isSmallScreen ? 0 : 2,
                    px: 1.5,
                    py: 0.5
                  }}
                  onClick={handleDeleteLocalStorage}
                  startIcon={<DeleteIcon />}
                >
                  Suche vergessen
                </Button>
              </CardActions>
            </Card>
          </Grid>
          )}
          <Grid item xs={12} lg={6} style={{ flexGrow: 1 }}>
            <Card>
              <CardActionArea onClick={() => navigate('/app/portfolio')}>
                <CardContent>
                  <Typography variant="h5" color="text" fontWeight="bold" gutterBottom>
                    Softwareanbieter
                  </Typography>
                  <Typography variant="body1" color="text" gutterBottom>
                    Verwalten Sie Ihre IT-Produkte und Kooperationen im Portfolio.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Divider />
              <CardActions sx={{ py: { xs: 1.2, sm: 2 }, px: { xs: 2, sm: 3 } }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    mr: 2, px: 1.5, py: 0.5
                  }}
                  onClick={() => handleNew('singleproduct')}
                >
                  {t('common.button.addSingleProduct')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    mr: 2, px: 1.5, py: 0.5
                  }}
                  onClick={() => handleNew('combiproduct')}
                >
                  {t('common.button.addCombiProduct')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card style={{ flexGrow: 1 }}>
              <CardActionArea>
                <CardContent onClick={() => navigate('/app/projects')}>
                  <Typography variant="h5" color="text" fontWeight="bold" gutterBottom>
                    Softwareanwender
                  </Typography>
                  <Typography variant="body1" color="text" gutterBottom>
                    Verwalten Sie Ihre Softwaregesuche bei den Gesuchen.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Divider />
              <CardActions sx={{ py: { xs: 1.2, sm: 2 }, px: { xs: 2, sm: 3 } }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    mr: 2, px: 1.5, py: 0.5
                  }}
                  onClick={() => handleNew('project')}
                >
                  {t('common.button.addApplication')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card style={{ flexGrow: 1 }}>
              <CardActionArea onClick={() => navigate('/app/requests')}>
                <CardContent>
                  <Typography variant="h5" color="text" fontWeight="bold" gutterBottom>
                    Anfragen
                  </Typography>
                  <Typography variant="body1" color="text" gutterBottom>
                    Verwalten Sie Ihre eingehenden und ausgehenden Anfragen von anderen Unternehmen.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <Divider />
              <CardActions sx={{ py: { xs: 1.2, sm: 2 }, px: { xs: 2, sm: 3 } }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    mr: 2, px: 1.5, py: 0.5
                  }}
                  onClick={() => navigate('/app/requests/incoming')}
                >
                  {t('common.button.incomingRequests')}
                  {': '}
                  {incomingOffers.length}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{
                    mr: 2, px: 1.5, py: 0.5
                  }}
                  onClick={() => navigate('/app/requests/outgoing')}
                >
                  {t('common.button.outgoingRequests')}
                  {': '}
                  {outgoingOffers.length}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {posts && (
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" color="text" fontWeight="bold" gutterBottom>
                  BITMi News:
                </Typography>
                <List>
                  {posts.slice(0, 5).map((post: GenericObject, index: number) => (
                    <ListItem key={post.id}>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6">
                            <Link
                              href={post.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'none', color: 'inherit' }}
                              dangerouslySetInnerHTML={{ __html: post.title.rendered }}
                            />
                          </Typography>
                          <Typography variant="subtitle2" color="textSecondary" align="right">
                            {new Date(post.date).toLocaleDateString()}
                          </Typography>
                        </Box>
                        <Typography variant="body2" color="textSecondary" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                        <Link variant="subtitle2" href={post.link} target="_blank" rel="noopener noreferrer">
                          Auf Bitmi.de weiterlesen
                        </Link>
                      </Box>
                      {index !== 4 && <Divider />}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
export default StartScreenMain;
