import {
  FC, ReactElement, useState, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Button, Grid, Pagination, ButtonGroup, Paper
} from '@mui/material';

import { FactCheck as FactCheckIcon } from '@mui/icons-material';
import BookmarkCard from '../ui/Card';
import { useAppStore, usePaginationStore, useProductStore } from '../../state';
import SkeletonCard from '../ui/SkeletonCard';
import BookmarkLinkupDialog from './BookmarkLinkupDialog';

const BookmarksMain: FC = (): ReactElement => {
  const { t } = useTranslation();
  const defaultBookmarkItem:GenericObject = { id: -1, name: '...loading' };
  const {
    bookmarks,
    prodRemoveBookmark,
    prodBlacklistBookmark,
  } = useProductStore((state: GenericObject) => state);
  /* eslint-disable react/jsx-props-no-spreading */

  const {
    appSetShowCompareDialog, showSkeleton
  } = useAppStore((state: GenericObject) => state);

  const [open, setOpen] = useState(false);
  const toogleOpen = () => setOpen(!open);
  const [bookmarkItem, setBookmarkItem] = useState(defaultBookmarkItem);
  const [prodname, setProdname] = useState('');

  const filterAll = 'all';
  const filterSolution = 'solution';
  const filterProject = 'project';
  const filterCombiProduct = 'combiProduct';

  const [filter, setFilter] = useState('all');
  const [filteredLists, setFilteredLists] = useState<FilteredLists>({
    solutions: [],
    projects: [],
    combiProducts: [],
  });

  const handleLinkupBookmark = (item:GenericObject, name: string) => {
    setBookmarkItem(item);
    setProdname(name);
    setOpen(true);
    return true;
  };

  const handleToggle = () => appSetShowCompareDialog(true);

  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
  } = usePaginationStore();

  const getFilterValue = () => {
    if (bookmarks?.length > 0) {
      const solutionData: any = bookmarks.filter((item : any) => item.type === filterSolution && item.isCombiProduct === false);
      const projectData: any = bookmarks.filter((item : any) => item.type === filterProject);
      const combiProductData: any = bookmarks.filter((item : any) => item.type === filterCombiProduct || item.isCombiProduct === true);
      setFilteredLists(({
        solutions: solutionData,
        projects: projectData,
        combiProducts: combiProductData,
      }));
    } else {
      setFilteredLists(({
        solutions: [],
        projects: [],
        combiProducts: [],
      }));
    }
  };

  useEffect(() => {
    getFilterValue();
  }, []);

  useEffect(() => {
    getFilterValue();
    switch (filter) {
      case filterSolution:
        init(bookmarks.filter((item : any) => item.type === filterSolution && item.isCombiProduct === false), 6, 1);
        break;
      case filterCombiProduct:
        init(bookmarks.filter((item : any) => item.type === filterCombiProduct || item.isCombiProduct === true), 6, 1);
        break;
      case filterProject:
        init(bookmarks.filter((item : any) => item.type === filterProject), 6, 1);
        break;
      default:
        init(bookmarks, 6, 1);
        break;
    }
  }, [bookmarks.length]);

  const filterChange = (newValue: any) => {
    setFilter(newValue);

    switch (newValue) {
      case filterSolution:
        init(filteredLists.solutions, 6, 1);
        break;
      case filterCombiProduct:
        init(filteredLists.combiProducts, 6, 1);
        break;
      case filterProject:
        init(filteredLists.projects, 6, 1);
        break;
      default:
        init(bookmarks, 6, 1);
        break;
    }
    getFilterValue();
  };

  const handleRemoveBookmark = async (id: number) => {
    const succes : boolean = await prodRemoveBookmark(id);

    if (succes) {
      await useProductStore.setState((prevState: any) => ({
        ...prevState,
        bookmarks: prevState.bookmarks.filter((item: any) => item.id !== id)
      }));
    }
  };

  return (
    <>
      <BookmarkLinkupDialog
        open={open}
        onClose={toogleOpen}
        item={bookmarkItem}
        productname={prodname}
      />
      <Box>
        <Typography variant="h1" fontWeight="bold" sx={{ mb: 1 }}>{t('bookmark.header')}</Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{
              mb: 1, mr: 2, px: 2, py: 0.7
            }}
            onClick={handleToggle}
            startIcon={<FactCheckIcon />}
          >
            Vergleich
          </Button>
        </Box>
        <Paper sx={{
          display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'start', py: 1, pl: 1, alignContent: 'center', flexDirection: 'row'
        }}
        >
          <Box>
            <Typography>Kategorie</Typography>
            <ButtonGroup>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 2, py: 0.7
                }}
                style={{ opacity: filter === filterAll ? '1' : '0.6' }}
                onClick={() => filterChange(filterAll)}
              >
                {`${t('matching.filter.all')} `}
                (
                {bookmarks?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 2, py: 0.7
                }}
                style={{ opacity: filter === filterSolution ? '1' : '0.6' }}
                onClick={() => filterChange(filterSolution)}
              >
                {`${t('matching.filter.singleProducts')} `}
                (
                {filteredLists.solutions?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, px: 2, py: 0.7
                }}
                style={{ opacity: filter === filterCombiProduct ? '1' : '0.6' }}
                onClick={() => filterChange(filterCombiProduct)}
              >
                {`${t('matching.filter.combiProducts')} `}
                (
                {filteredLists.combiProducts?.length}
                )
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                sx={{
                  mb: 1, mr: 2, px: 2, py: 0.7
                }}
                style={{ opacity: filter === filterProject ? '1' : '0.6' }}
                onClick={() => filterChange(filterProject)}
              >
                {`${t('matching.filter.requests')} `}
                (
                {filteredLists.projects?.length}
                )
              </Button>
            </ButtonGroup>
          </Box>
        </Paper>
        {showSkeleton ? (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            { [...Array(Math.min(6, bookmarks.length))].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            {paginatedData && paginatedData.map((data: any) => (
              <Grid item xs={12} lg={6} xl={4}>
                <BookmarkCard
                  key={`card_${data.type}_${data.id}`}
                  {...data}
                  handleRemoveBookmark={async () => handleRemoveBookmark(data.id)}
                  handleBlacklistBookmark={() => prodBlacklistBookmark(data.id)}
                  handleLinkupBookmark={() => handleLinkupBookmark(data, data.item.name)}
                />
              </Grid>
            ))}
          </Grid>
        )}

        <Box sx={{ pt: 3, clear: 'both' }}>
          {countItems === 0 && <Typography variant="h6">{t('common.text.noData')}</Typography>}
          {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
        </Box>
      </Box>
    </>
  );
};
export default BookmarksMain;
