/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';

export const FormInputCheckbox = ({
  name, control, label, checked, defaultValue, sx = {}
}: FormInputProps) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <FormControlLabel
        control={<Checkbox onChange={onChange} value={value} />}
        label={label}
        sx={sx}
        checked={checked}
      />
    )}
  />
);

export default FormInputCheckbox;
