import {
  FC, ReactElement, useEffect, useState
} from 'react';

import { useAppStore, useProductStore, useUserStore } from '../state';

import PortfolioMain from '../components/portfolio/PortfolioMain';

const Portfolio: FC = (): ReactElement => {
  const app = useAppStore((state:GenericObject) => state);
  const { categoriesLoaded, accountDataLoaded } = app;
  // load all important data from api to display it on the startpage

  useEffect(() => {
    if (!categoriesLoaded) {
      useProductStore.getState().prodGetCategories();
      useAppStore.setState({ categoriesLoaded: true });
    }

    if (!accountDataLoaded) {
      useUserStore.getState().userGetAccountData()
        .then(() => useProductStore.getState().prodGetNetworks());
      useAppStore.setState({ accountDataLoaded: true });
    }
  }, []);

  return (
    <PortfolioMain />
  );
};

export default Portfolio;
