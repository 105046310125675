import {
  FC, ReactElement, useEffect
} from 'react';
import { useProductStore, useAppStore, useUserStore } from '../state';

import MatchingMain from '../components/matching/MatchingMain';

const Matching: FC = (): ReactElement => {
  // load all important data from api to display it on the startpage
  useEffect(() => {
    useAppStore.getState().appGetEventNotification();
    useProductStore.getState().prodGetCategories();

    // for Matching page we need all solutions, combiProducts and projects from this user. After fetching,
    // we need to loop through this array to get the corresponding suggestions and offers
    // sadly we have to call the API again for every suggeestion and offer to get the company logo and contact person data
    // first we have to wait until the Promise from userGetAccountData() is resolve since we need data from there
    // then all the magic happens in prodGetSuggestionsAndOffers() :-)
    useUserStore.getState().userGetAccountData()
      .then(() => useProductStore.getState().prodGetSuggestionsAndOffers());
  }, []);

  return (
    <MatchingMain />
  );
};

export default Matching;
