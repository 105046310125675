/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import {
  FC, ReactElement, useEffect, useState
} from 'react';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Box, Typography, Button, Pagination, Grid, Badge, IconButton, ButtonGroup, Paper
} from '@mui/material';

import { InfoOutlined, TuneOutlined as TuneIcon, ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import {
  useAppStore, useProductStore, useUserStore, usePaginationStore
} from '../../state';

import FormInputSearch from '../ui/FormInputSearch';

import SearchCard from '../ui/Card';
import SearchFilterDialog from '../ui/SearchFilterDialog';
import SearchLinkupBookmarkDialog from './SearchLinkupBookmarkDialog';
import SkeletonCard from '../ui/SkeletonCard';

function getCategoriesFromParams(params:any) {
  const res = params.split(',').map(Number);
  return res;
}

const SearchMain:FC = ():ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSearchItem:GenericObject = { id: -1, name: '...loading' };

  const [openDialogFilter, setOpenDialogFilter] = useState(false);
  const [openDialogLinkupBookmark, setOpenDialogLinkupBookmark] = useState(false);
  const [filterType, setFilterType] = useState('all');
  const [searchTerm, setSearchTerm] = useState<string | null>(searchParams.get('search') != null ? searchParams.get('search') : '');
  const [positives, setPositives] = useState<string | null>(searchParams.get('positives') != null ? searchParams.get('positives') : '');
  const [orderBy, setOrderBy] = useState('time');
  const [order, setOrder] = useState('desc');
  const [categories, setCategories] = useState('');
  const [searchItem, setSearchItem] = useState(defaultSearchItem);

  const {
    prodPrivatSearch, searchResult, searchParameter, prodSearchAddLinkupBookmark
  } = useProductStore((state:GenericObject) => state);

  const {
    token
  } = useUserStore((state:GenericObject) => state);

  const { showSkeleton } = useAppStore((state:GenericObject) => state);

  const {
    handleSubmit, control, register, getValues
  } = useForm();

  // Hook for Pagination, start it with init()
  const {
    init,
    jump,
    countItems,
    currentPage,
    maxPage,
    paginatedData,
  } = usePaginationStore((state:GenericObject) => state);

  const sorter = (objectA:GenericObject, objectB:GenericObject) => {
    let compare1:any;
    let compare2:any;

    if (orderBy === 'alphabet') {
      if (order === 'asc') {
        compare1 = objectA.name.toLowerCase();
        compare2 = objectB.name.toLowerCase();
      } else {
        compare1 = objectB.name.toLowerCase();
        compare2 = objectA.name.toLowerCase();
      }
    } else if (orderBy === 'time') {
      if (order === 'asc') {
        compare1 = objectA.created;
        compare2 = objectB.created;
      } else {
        compare1 = objectB.created;
        compare2 = objectA.created;
      }
    }

    return (compare1 > compare2) ? 1 : ((compare1 < compare2) ? -1 : 0);
  };

  useEffect(() => {
  // Setzt die Übergebenen URL Parameter
    if (searchParams.get('search') != null) {
      setSearchTerm(searchParams.get('search'));
      searchParameter.searchTerm = searchTerm;
    }
    if (searchParams.get('positives') != null) {
      setPositives(searchParams.get('positives'));
      useProductStore.setState({ searchCategories: getCategoriesFromParams(positives) });
      setCategories(getCategoriesFromParams(positives));
    }
    useProductStore.setState({ searchCategories: [] });
    setCategories('');
  }, []);

  useEffect(() => {
    if (searchResult) {
      // sort the search result locally because there are problems in API
      const sortedArray = searchResult.sort((a:GenericObject, b:GenericObject) => sorter(a, b));

      // build the list und pagination new
      init(sortedArray, 6, 1);
    }
  }, [searchResult, orderBy, order]);

  // trigger a new search
  useEffect(() => {
    const categoriesFilter = {
      solutionCategories: '999999',
      combiProductCategories: '999999',
      projectCategories: '999999',
    };

    if (filterType === 'all') {
      categoriesFilter.solutionCategories = categories || '';
      categoriesFilter.combiProductCategories = categories || '';
      // categoriesFilter.projectCategories = categories || '';
    } else if (filterType === 'solution') {
      categoriesFilter.solutionCategories = categories || '';
    } else if (filterType === 'combiProduct') {
      categoriesFilter.combiProductCategories = categories || '';
    } else if (filterType === 'project') {
      categoriesFilter.projectCategories = categories || '';
    }

    prodPrivatSearch({
      searchTerm,
      orderBy,
      order,
      ...categoriesFilter,
    });
  }, [searchTerm, filterType, categories]);

  const handleFilterType = (type:string) => setFilterType(type);

  const resetFilter = () => {
    useProductStore.setState({ searchCategories: [] });
    setSearchParams('');
    setCategories('');
    setOrderBy('time');
    setOrder('desc');
  };

  const handleGoToLandingpage = () => navigate('/');
  const handleLogin = () => navigate('/login');
  const handleRegister = () => navigate('/register');

  const handleLinkupBookmark = (item:GenericObject, mode:string) => {
    // if preview mode than open a modal to motivate user to registrate or login
    if (token) {
      setSearchItem({ ...item, mode });
      setOpenDialogLinkupBookmark(true);
      return true;
    }

    const dialogData = {
      title: { text: t('search.headerPreview') },
      contentItems: [
        {
          text: t('search.searchPreviewText'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.register'),
          color: 'error',
          onClick: handleRegister
        },
        {
          text: t('common.button.login'),
          color: 'error',
          onClick: handleLogin
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const toggleDialogFilter = () => setOpenDialogFilter(!openDialogFilter);
  const toggleDialogLinkupBookmark = () => setOpenDialogLinkupBookmark(!openDialogLinkupBookmark);

  const onSubmit = (data:GenericObject) => setSearchTerm(data.searchTerm);
  const handleSearch = () => setSearchTerm(getValues('searchTerm'));

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('search.info.header') },
      contentItems: [
        {
          text: t('search.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  return (
    <>
      <SearchFilterDialog
        open={openDialogFilter}
        onClose={toggleDialogFilter}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        setCategories={setCategories}
        sharedProductStore={useProductStore}
      />
      <SearchLinkupBookmarkDialog
        open={openDialogLinkupBookmark}
        onClose={toggleDialogLinkupBookmark}
        searchItem={searchItem}
      />
      {!token && (
      <Button sx={{ mb: 2 }} onClick={handleGoToLandingpage} startIcon={<ChevronLeftIcon sx={{ color: 'primary' }} />}>
        {t('common.button.backToStart')}
      </Button>
      )}
      <Box>
        <div style={{
          display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12
        }}
        >
          <Typography variant="h1" fontWeight="bold">
            { token ? t('search.header') : t('search.headerPreview')}
          </Typography>
          <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfo}><InfoOutlined /></IconButton>
        </div>
        <Paper sx={{
          display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'start', py: 1, pl: 1, alignContent: 'center', flexDirection: 'row'
        }}
        >
          <Box sx={{ width: '100%', mr: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInputSearch
                name="searchTerm"
                control={control}
                ref={register}
                defaultValue={searchParams.get('search') != null ? searchParams.get('search') : searchParameter.searchTerm}
                onIconClick={handleSearch}
                sx={{ bgcolor: 'secondary.light' }}
                label=""
              />
            </form>
          </Box>
          <Box>
            <Typography>Kategorie</Typography>
            <ButtonGroup sx={{ mr: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleFilterType('all')}
                style={{ opacity: filterType === 'all' ? '1' : '0.6' }}
                sx={{
                  my: 0.5, px: 2.5, py: 0.5
                }}
              >
                { t('search.filter.all') }
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleFilterType('solution')}
                style={{ opacity: filterType === 'solution' ? '1' : '0.6' }}
                sx={{
                  my: 0.5, px: 2.5, py: 0.5
                }}
              >
                { t('search.filter.singleProducts') }
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleFilterType('combiProduct')}
                style={{ opacity: filterType === 'combiProduct' ? '1' : '0.6' }}
                sx={{
                  my: 0.5, px: 2.5, py: 0.5
                }}
              >
                { t('search.filter.combiProducts') }
              </Button>
              {/* <Button
              variant="contained"
              color="secondary"
              onClick={() => handleFilterType('project')}
              style={{ opacity: filterType === 'project' ? '1' : '0.6' }}
              sx={{
                my: 0.5, mr: 2, px: 2.5, py: 0.5
              }}
            >
              { t('search.filter.requests') }
            </Button> */}
            </ButtonGroup>
          </Box>
          <Box>
            <Typography>{t('search.nameFilterSort') }</Typography>
            <Badge color="primary" variant="dot" overlap="circular" badgeContent={categories.length}>
              <Button
                sx={{
                  my: 0.5, mr: 2, px: 2.5, py: 0.5
                }}
                variant="contained"
                color="secondary"
                onClick={toggleDialogFilter}
              >
                <TuneIcon />
              </Button>
            </Badge>
            {categories.length > 0 && <Button sx={{ mr: 2, py: 0.5 }} color="primary" variant="text" size="small" onClick={resetFilter}>{t('search.button.resetFilter')}</Button>}
          </Box>
        </Paper>
        {!showSkeleton ? (
          <>
            <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
              {paginatedData && paginatedData.map((data: any) => (
                <Grid item xs={12} lg={6} xl={4}>
                  <SearchCard
                    key={`card_${data.type}_${data.id}`}
                    item={data}
                    handleLinkup={() => handleLinkupBookmark(data, 'linkup')}
                    handleBookmark={() => handleLinkupBookmark(data, 'bookmark')}
                    {...data}
                  />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ pt: 3, clear: 'both' }}>
              {countItems === 0 && <Typography variant="h6">{t('common.text.noData')}</Typography>}
              {countItems > 0 && <Pagination count={maxPage} page={currentPage} onChange={(event, value1) => jump(value1)} />}
            </Box>
          </>
        ) : (
          <Grid container spacing={{ xs: 3, sm: 4, md: 5 }} paddingTop={{ sm: 3 }}>
            { [...Array(6)].map((e, i) => (
              <Grid item xs={12} lg={6} xl={4}>
                <SkeletonCard key={`skel_${i * 2}`} />
              </Grid>
            ))}
          </Grid>
        )}

      </Box>
    </>
  );
};

export default SearchMain;
