/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import {
  FC, ReactElement, useEffect, useState
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Grid, Tabs, Tab, Chip, Tooltip, Card, CardContent, Divider, Avatar
} from '@mui/material';

import PlaceholderImage from '../../assets/images/placeholder_company.png';
import ExpandableChips from '../app/ExpandableChips';

interface TestProps {
  value?: number,
  index?: number
}

const TabPanel: FC<TestProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  /* eslint-disable react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const PreviewCard = (props: GenericObject): ReactElement => {
  const {
    name,
    type,
    summary,
    tree,
  } = props;
  const [filterValue, setFilterValue] = useState(0);
  const filterChange = (event: any, newValue: any) => {
    setFilterValue(newValue);
  };

  const [catArray, setCatArray] = useState([]);

  const newCatArray:any = [];
  const children:any = [];

  useEffect(() => {
    ['industries', 'functionalities', 'characteristics', 'moreFeatures'].forEach((category:string) => {
      children.splice(0);
      tree[category].forEach((elem:GenericObject) => {
        if (elem.id === 120) {
          if (elem.checked) children.push({ id: elem.id, name: elem.name });
        }
        elem.children.forEach((subelem:GenericObject) => {
          if (subelem.checked) {
            children.push({ id: subelem.id, name: subelem.name, parentname: elem.name });
          }
        });
      });

      const id = category === 'industries' ? 1 : (category === 'functionalities' ? 121 : (category === 'moreFeatures' ? 183 : 275));
      const catName = category === 'industries' ? 'Branche' : (category === 'functionalities' ? 'Funktion' : (category === 'moreFeatures' ? 'Weitere' : 'Funktionsmerkmale'));
      const obj = {
        catName,
        id,
        children: [...children]
      };

      newCatArray.push(obj);
    });
    setCatArray(newCatArray);
  }, [tree]);

  const { t } = useTranslation();

  let nameType = 'Gesuch';
  if (type === 'solution') nameType = 'Einzelprodukt';
  if (type === 'combiProduct') nameType = 'Kombiprodukt';

  return (
    <Card>
      <CardContent>
        <Box
          paddingY={{ xs: 2, lg: 2.5 }}
          marginY={{ md: 1 }}
          sx={{
            display: 'flex', alignItems: 'center', flexGrow: '1', cursor: 'pointer'
          }}
        >
          <Avatar src={PlaceholderImage} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
          <div style={{ flexGrow: 1, marginLeft: 20 }}>
            <Typography fontSize={8} color="black" fontWeight="600" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}>{nameType}</Typography>
            <Typography variant="h5" color="black" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}>{name}</Typography>
          </div>
        </Box>
        <Typography color="black" letterSpacing="0.5px" marginBottom={5} style={{ fontSize: 14 }}>
          {summary}
        </Typography>
        <Grid container spacing={{ sm: 3, md: 4 }}>
          <Grid item xs={12} lg={12}>
            <div style={{ display: 'flex' }}>
              <Tabs value={filterValue} onChange={filterChange} variant="scrollable" scrollButtons="auto">
                {catArray && catArray.map(
                  (data: any, index: number) => (
                    <Tab
                      disabled={(data.children.length === 0)}
                      label={data.catName}
                      key={`cat_${data.id}`}
                      {...a11yProps(index)}
                      sx={{
                        visibility: 'visible', paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px'
                      }}
                    />
                  )
                )}
              </Tabs>
            </div>
            <div style={{ marginLeft: '-24px' }}>
              {catArray && catArray.map((data: any, index: number) => (
                <TabPanel value={filterValue} index={index} key={`Tab_${data.id}`}>
                  {data.children
                    .reduce((acc: any[], curr: any) => {
                      const { parentname } = curr;
                      if (!acc.some((item: any) => item.parentname === parentname)) {
                        acc.push({ parentname, children: [curr] });
                      } else {
                        const parent = acc.find((item: any) => item.parentname === parentname);
                        parent.children.push(curr);
                      }
                      return acc;
                    }, [])
                    .map((group: any, ind: number) => (
                      <>
                        <Typography sx={{ mb: 1 }} variant="caption" fontSize={14} fontWeight="bold">{group.parentname}</Typography>
                        <ExpandableChips group={group} />
                        {ind !== Object.keys(data.children).length - 1 ? <Divider sx={{ mb: 1 }} /> : null}
                      </>
                    ))}
                </TabPanel>
              ))}
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
};
export default PreviewCard;
