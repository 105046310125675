/* eslint-disable no-nested-ternary */
import {
  FC, Fragment, ReactElement, useState
} from 'react';

import {
  Box, Typography, Tabs, Tab, Divider
} from '@mui/material';

import { useCategories } from '../../hooks';
import ExpandableChips from './ExpandableChips';

interface TestProps {
  value?: number,
  index?: number
}

const TabPanel: FC<TestProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  /* eslint-disable react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    )}
  </div>
);

TabPanel.defaultProps = {
  index: 0,
  value: 0,
};

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const CategoriesTabs = (props: GenericObject): ReactElement => {
  const [filterValue, setFilterValue] = useState(0);
  const filterChange = (event: any, newValue: any) => {
    setFilterValue(newValue);
  };

  const {
    tree,
  } = useCategories({ currentItem: props });

  const newCatArray:any = [];
  const children:any = [];

  ['industries', 'functionalities', 'characteristics', 'moreFeatures'].forEach((category:string) => {
    children.splice(0);
    tree[category].forEach((elem:GenericObject) => {
      if (elem.id === 120) {
        if (elem.checked) children.push({ id: elem.id, name: elem.name });
      }
      elem.children.forEach((subelem:GenericObject) => {
        if (subelem.checked) children.push({ id: subelem.id, name: subelem.name, parentname: elem.name });
      });
    });

    const id = category === 'industries' ? 1 : (category === 'functionalities' ? 121 : (category === 'moreFeatures' ? 183 : 275));
    const name = category === 'industries' ? 'Branche' : (category === 'functionalities' ? 'Funktion' : (category === 'moreFeatures' ? 'Weitere' : 'Funktionsmerkmale'));
    const obj = {
      name,
      id,
      children: [...children]
    };

    newCatArray.push(obj);
  });

  if (newCatArray.length === 0) return <></>;

  return (
    <>
      <Tabs value={filterValue} onChange={filterChange} variant="scrollable" scrollButtons="auto">
        {newCatArray && newCatArray.map(
          (data: any, index: number) => (
            <Tab
              disabled={data.children.length === 0}
              label={data.name}
              key={`cat_${data.id}`}
              {...a11yProps(index)}
              sx={{
                paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px', fontWeight: 'bold',
              }}
            />

          )
        )}
      </Tabs>
      <Box style={{ marginLeft: '-24px' }}>
        {
  newCatArray && newCatArray.map((data: any, index: number) => {
    const groupedFragments = data.children.reduce((acc: any[], curr: any) => {
      const { parentname } = curr;
      if (!acc.some((item: any) => item.parentname === parentname)) {
        acc.push({ parentname, children: [curr] });
      } else {
        const parent = acc.find((item: any) => item.parentname === parentname);
        parent.children.push(curr);
      }
      return acc;
    }, []);

    return (
      <TabPanel value={filterValue} index={index} key={`Tab_${data.id}`}>
        {groupedFragments.map((group: any, ind: number) => (
          <Fragment key={`${group.parentname}_${group.children.some((item: any) => item.id) ? group.children.find((item: any) => item.id).id : ind}`}>
            <Typography sx={{ mb: 1 }} variant="caption" fontSize={14} fontWeight="bold">{group.parentname}</Typography>
            <ExpandableChips group={group} />
            {ind !== groupedFragments.length - 1 && (
              <Divider sx={{ mb: 1 }} />
            )}
          </Fragment>
        ))}
      </TabPanel>
    );
  })
}
      </Box>
    </>
  );
};
export default CategoriesTabs;
