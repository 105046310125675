/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Controller } from 'react-hook-form';
import {
  FormHelperText, FormControl, Autocomplete, TextField
} from '@mui/material';

export const FormAutocompleteSelect = ({
  name,
  control,
  label,
  data,
  rules = {},
  fullWidth = false,
  disabled,
}: FormInputProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <FormControl fullWidth={fullWidth}>
        <Autocomplete
          id="autocomplete"
          options={data}
          getOptionLabel={(item) => item.name ?? item}
          value={value || null}
          onChange={(e, selection) => onChange(selection)}
          renderInput={(params) => <TextField error={!!error} {...params} label={label} variant="outlined" inputProps={{ ...params.inputProps, autoComplete: 'disabled' }} />}
          isOptionEqualToValue={(option, values) => values === undefined || values === '' || option.id === values.id}
          disabled={disabled}
        />
        <FormHelperText error>{error ? error.message : null}</FormHelperText>
      </FormControl>
    )}
    rules={rules}
  />

);
export default FormAutocompleteSelect;
