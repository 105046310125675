import { FC, ReactElement, useEffect } from 'react';

import { useProductStore, useUserStore } from '../state';

import ComparisonMain from '../components/comparison/ComparisonMain';

const Comparison: FC = (): ReactElement => {
  // load all important data from api to display it on the startpage
  useEffect(() => {
    // for Bookmarks page we need all solutions, combiProducts and projects from this user. After fetching,
    // we need to loop through this array to get the corresponding bookmarks
    // sadly we have to call the API again for every bookmark to get the company logo and contact person data
    // first we have to wait until the Promise from userGetAccountData() is resolve since we need data from there
    // then all the magic happens in prodGetBookmarks() :-)
    useUserStore.getState().userGetAccountData()
      .then(() => useProductStore.getState().prodGetBookmarks());
  }, []);

  return (
    <ComparisonMain />
  );
};

export default Comparison;
