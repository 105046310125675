import { FC, ReactElement, useEffect } from 'react';

import { useProductStore } from '../state';

import SearchMain from '../components/search/SearchMain';

const Preview: FC = (): ReactElement => {
  // load all important data from api to display it on the search main page
  useEffect(() => {
    useProductStore.getState().prodGetCategories();
  }, []);

  return (
    <SearchMain />
  );
};

export default Preview;
