import {
  FC, ReactElement
} from 'react';

import {
  Box, Card as MuiCard, CardActions, CardContent, Divider, Skeleton, CardHeader, Typography
} from '@mui/material';

const SkeletonCard: FC = (): ReactElement => (
  <MuiCard sx={{
    height: '100%', display: 'flex', flexDirection: 'column', borderLeft: 'solid', borderColor: 'secondary.light', borderWidth: 4, justifyContent: 'space-between'
  }}
  >
    <CardHeader title={(
      <Skeleton variant="text" width={80} />
    )}
    />
    <Divider />
    <CardContent sx={{ display: 'flex' }}>
      <Box
        sx={{
          display: 'flex', flexDirection: 'column'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
          <Skeleton variant="circular" width="70px" height="70px" />
          <Box style={{ flexGrow: 1, marginLeft: 20 }}>
            <Skeleton variant="text" width={80} />
            <Skeleton variant="text" width={80} />
            <Skeleton variant="text" width={80} />
          </Box>
        </Box>
        <Typography variant="h5" marginTop="auto">
          <Skeleton width={200} />
        </Typography>
        <Typography fontSize={12} fontWeight="bold" marginBottom={1}>
          <Skeleton width={80} />
        </Typography>
        <Typography marginBottom={5} style={{ fontSize: 14 }}>
          <Skeleton width={300} />
        </Typography>
        <Box sx={{ alignSelf: 'flex-start' }}>
          <Skeleton width={50} />
        </Box>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Skeleton
        variant="text"
        width={80}
      />
      <Skeleton
        variant="text"
        width={80}
      />
    </CardActions>
  </MuiCard>
);
export default SkeletonCard;
