import {
  FC, ReactElement, useState
} from 'react';

import { useTranslation } from 'react-i18next';

import {
  Box, Typography, Button, Grid, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails, Chip, Link, Card, CardActions, CardContent, CardHeader, Divider, Avatar
} from '@mui/material';

import {
  Check as CheckIcon,
  BookmarkBorderOutlined as BookmarkIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router';
import PlaceholderImage from '../../assets/images/placeholder_company.png';
import placeholderCombiCompany from '../../assets/images/placeholder_combi_company.png';
import OwnerImg from '../../assets/images/placeholder_person.png';

import MatchingLinkupDialog from './MatchingLinkupDialog';
import CategoriesTabs from '../app/CategoriesTabs';
import { goToCompanyProfile } from '../../utils';

interface TestProps {
  value?: number,
  index?: number
}

const TabPanel: FC<TestProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    /* eslint-disable react/jsx-props-no-spreading */
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

TabPanel.defaultProps = {
  index: 0,
  value: 0,
};
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const MatchingCard = (props: GenericObject): ReactElement => {
  const navigate = useNavigate();
  const {
    id,
    item,
    name,
    company,
    combiproductCompanies,
    type,
    summary,
    logo,
    offerMessage,
    referenceModels,
    screenType,
    onBookmark,
    onBlacklist,
    onLinkup,
    onConfirmOffer,
    onDeclineOffer,
    onConfirmCombiProduct,
    onDeclineCombiProduct,
    onClose
  } = props;

  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [openDialogLinkup, setOpenDialogLinkup] = useState(false);
  const [linkupItem, setLinkupItem] = useState({});

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  if (!item) return <></>;

  let prefix = screenType === 'suggestion'
    ? t('card.informationBar.suggestionInfo')
    : t('card.informationBar.offerIncomingInfo');

  if (screenType === 'modal') prefix = '';
  const newRefModels = referenceModels || [];

  const toggleDialogLinkup = () => {
    setLinkupItem(props);
    setOpenDialogLinkup(!openDialogLinkup);
  };

  const handleLinkup = (message:any) => {
    onLinkup(id, message);
    toggleDialogLinkup();
  };

  // eslint-disable-next-line no-nested-ternary
  const nameType = type === 'solution'
    ? t('card.type.singleproduct')
    : (type === 'combiProduct' ? t('card.type.combiproduct') : t('card.type.request'));

  return (
    <>
      <MatchingLinkupDialog
        open={openDialogLinkup}
        onClose={toggleDialogLinkup}
        item={linkupItem}
        onLinkup={handleLinkup}
        productname={item?.name}
      />
      <Card style={{ display: 'flex', flexDirection: 'column' }}>
        {screenType !== 'modal' && (
        <CardHeader
          titleTypographyProps={{ variant: 'body1', color: 'black' }}
          title={`${prefix} ${item?.name}`}
          subheader={newRefModels.length > 0 && (
            <Accordion sx={{ boxShadow: 'none', flexShrink: 0, position: 'static' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ px: 0, marginY: 0, minHeight: 'auto !important' }}
              >
                <Typography py={1.5} color="black" fontSize="15px" sx={{ justifyContent: 'flex-end' }}>
                  {t('card.informationBar.referenceModellInfo')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{
                backgroundColor: 'white', borderBottom: 1, borderColor: 'secondary.main'
              }}
              >
                {newRefModels && newRefModels.map((data: any) => (
                  <Chip
                    sx={{
                      ml: 1,
                      mb: 1,
                      fontSize: 12
                    }}
                    label={data.name}
                    variant="outlined"
                    key={`reference_${data.id}`}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}
        />
        )}
        <CardContent style={{ flexGrow: 1 }}>
          <Box
            paddingY={{ xs: 2, lg: 2.5 }}
            marginY={{ md: 1 }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box
                sx={{
                  mt: 0.5, mb: 3, display: 'flex', alignItems: 'center'
                }}
              >
                <Avatar src={logo ?? company?.logo ?? (type === 'solution' ? PlaceholderImage : placeholderCombiCompany)} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
                <div style={{ flexGrow: 1, marginLeft: 20 }}>
                  <Typography fontSize={8} color="black" fontWeight="600" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}>{nameType}</Typography>
                  <Typography variant="h5" color="black" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}>{name}</Typography>
                  {type === 'solution' && (
                  <Typography sx={{ cursor: 'pointer', textDecoration: 'underline' }} component="a" onClick={() => goToCompanyProfile(company.id, navigate)} fontSize={12} color="primary" fontWeight="600" letterSpacing="0.5px">{company.name}</Typography>
                  )}
                </div>
              </Box>
              <Box
                sx={{
                  mt: 0.5, mb: 3, ml: 10, display: 'flex', alignItems: 'center', alignSelf: 'flex-end'
                }}
              >
                {(type === 'solution' || type === 'project')
              && (
                <>
                  <Box component="img" src={company.contactPerson.contactPersonImage ?? OwnerImg} alt="contact person image" borderRadius="100px" flexShrink="0" alignSelf="center" sx={{ width: 70, height: 70, objectFit: 'cover' }} />
                  <div style={{ flexGrow: 1, marginLeft: 20, maxWidth: '50%' }}>
                    <Typography fontSize={12} color="black" letterSpacing="0.5px" marginBottom={1}>
                      {company.contactPerson.title || ''}
                      {company.contactPerson.title ? ' ' : ''}
                      {company.contactPerson.firstname || ''}
                      {' '}
                      {company.contactPerson.lastname || ''}
                    </Typography>
                    <Typography fontSize={12} color="black" letterSpacing="0.5px" marginBottom={1}>{company.contactPerson.jobTitle || ''}</Typography>
                    <Typography fontSize={12} color="black" letterSpacing="0.5px" marginBottom={1}>{company.contactPerson.phoneNumber || ''}</Typography>
                    <Typography fontSize={12} color="black" letterSpacing="0.5px" marginBottom={1}>
                      <Link href={`mailto:${company.contactPerson.email}`}>
                        {company.contactPerson.email || ''}
                      </Link>
                    </Typography>
                  </div>
                </>
              )}
              </Box>
            </Box>
            {screenType === 'offers' ? (
              <div>
                {/* Start Summary Tab */}
                <div style={{ display: 'flex' }}>
                  <Tabs value={value} onChange={handleChange}>
                    <Tab
                      label={t('matching.description')}
                      {...a11yProps(0)}
                      sx={{
                        paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px'
                      }}
                    />
                    <Tab
                      label={t('matching.personalMessage')}
                      {...a11yProps(1)}
                      sx={{
                        paddingX: 0, mr: 2, textTransform: 'capitalize', alignItems: 'flex-start', minWidth: '70px'
                      }}
                    />
                  </Tabs>
                </div>
                <div style={{ marginLeft: '-24px' }}>
                  <TabPanel value={value} index={0}>{summary}</TabPanel>
                  <TabPanel value={value} index={1}>{offerMessage}</TabPanel>
                </div>
                {/* End Summary Tab */}
              </div>
            ) : (
              <Typography color="black" letterSpacing="0.5px" marginBottom={5} style={{ fontSize: 14 }}>
                {summary}
              </Typography>
            )}
            <Grid container spacing={{ sm: 3, md: 4 }}>
              <Grid item xs={12} lg={12}>
                <CategoriesTabs {...props} />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ py: { xs: 1.2, sm: 2 }, px: { xs: 2, sm: 3 } }} style={{ alignContent: 'flex-end', marginTop: 'auto' }}>
          {(screenType === 'offers' && type === 'solution') && (
          <>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 1.5, py: 0.5
              }}
              onClick={() => onConfirmOffer(id)}
              startIcon={<CheckIcon />}
            >
              {t('common.button.confirm')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 2.5, py: 0.5
              }}
              onClick={() => onDeclineOffer(id)}
              startIcon={<CloseIcon />}
            >
              {t('common.button.decline')}
            </Button>
          </>
          )}

          {(screenType === 'offers' && type === 'combiProduct') && (
          <>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 1.5, py: 0.5
              }}
              onClick={() => onConfirmCombiProduct(id)}
              startIcon={<CheckIcon />}
            >
              {t('common.button.confirm')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 2.5, py: 0.5
              }}
              onClick={() => onDeclineCombiProduct(id)}
              startIcon={<CloseIcon />}
            >
              {t('common.button.decline')}
            </Button>
          </>
          )}

          {screenType === 'suggestion' && (
          <>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 1.5, py: 0.5
              }}
              onClick={toggleDialogLinkup}
              startIcon={<CheckIcon />}
            >
              {t('common.button.linkup')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 2.5, py: 0.5
              }}
              onClick={() => onBookmark(id)}
              startIcon={<BookmarkIcon />}
            >
              {t('common.button.bookmark')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 2.5, py: 0.5
              }}
              onClick={() => onBlacklist(id)}
              startIcon={<CloseIcon />}
            >
              {t('common.button.blacklist')}
            </Button>
          </>
          )}
          {screenType === 'modal' && (
          <>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              sx={{
                mr: 2, px: 1.5, py: 0.5
              }}
              onClick={onClose}
              startIcon={<CloseIcon />}
            >
              {t('common.button.close')}
            </Button>
          </>
          )}
        </CardActions>
      </Card>
    </>
  );
};
export default MatchingCard;
