/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import create from 'zustand';

import { nanoid } from 'nanoid';
import { logger } from './middleware';

import { useAppStore, useUserStore } from './index';
import { processData } from '../utils';

let promises:Array<any> = [];
let promisesCompany:Array<any> = [];

const formData = new FormData();
formData.append('type', '');
formData.append('id', '');

const initialState = {
  searchParameter: {
    searchTerm: '',
    solutionCategories: '',
    projectCategories: '',
    orderBy: 'alphabet',
    order: 'asc',
  },
  currentProductProject: null,
  newCombiProduct: {},
  searchResult: [],
  searchCategories: [],
  categories: [],
  companys: [],
  company: [],
  bookmarks: [],
  compareList: [],
  portfolio: [],
  singleProducts: [],
  combiProducts: [],
  projects: [],
  networks: [],
  offers: [],
  offersIncoming: [],
  offersOutgoing: [],
  offersCombiProducts: [],
  suggestions: [],
  blacklists: [],
};

const store = (set:any, get:any) => ({
  ...initialState,

  addIdToCompareList: (id:number) => set((state:any) => ({ compareList: [...state.compareList, id] })),

  removeIdFromCompareList: (id:number) => set((state:any) => ({ compareList: state.compareList.filter((el:any) => el !== id) })),

  prodResetAll: () => set({ ...initialState }),

  getDataFromAPI: (arrayAPIs:Array<any>, type:string, id:string) => {
    formData.set('type', type);
    formData.set('id', id);
    arrayAPIs.forEach((api) => promises.push(processData({ data: formData, url: `/private/${api}` })));
  },

  getAssociatedData: (arrayAPIs:Array<any>):void => {
    promises = [];
    get().portfolio.forEach((item:GenericObject) => get().getDataFromAPI(arrayAPIs, item.type, item.id));
    get().projects.forEach((item:GenericObject) => get().getDataFromAPI(arrayAPIs, item.type, item.id));
  },

  getCompanyData: (id:string): void => {
    useAppStore.setState({ showSkeleton: true });
    processData({ url: `/private/get-company-data/${id}` }).then((response) => {
      const { error, data } = response;
      if (error) return false;

      if (data.code === 200) {
        set({ company: data.company });
        useAppStore.setState({ showSkeleton: false });
        return true;
      }

      useAppStore.setState({
        alert: {
          error: true, severity: 'error', text: 'Fehler beim Laden des Unternehmens.'
        }
      });
      useAppStore.setState({ showSkeleton: false });
      return false;
    });
  },

  getCompanysData: (arrayCompanies:Array<any>):void => {
    promisesCompany = [];
    arrayCompanies.forEach((id) => promisesCompany.push(processData({ url: `/private/get-company-data/${id}` })));
    const companyData:Array<any> = [];

    Promise.all(promisesCompany)
      .then((values) => {
        values.forEach((value) => {
          const { data } = value;
          const {
            code, company
          } = data;
          if (code === 200) {
            companyData.push(company);
          }
        });
      })
      .catch((error) => {
        useAppStore.setState({
          showSkeleton: false,
          alert: {
            error: true, severity: 'error', text: `Fehler beim Abfragen des Unternehmens ${error}`
          }
        });
        return false;
      });
    return set({ companys: companyData });
  },

  prodGetCategories: () => {
    let categories:Array<any> = [];
    if (localStorage.getItem('categories') !== null) {
      categories = JSON.parse(localStorage.getItem('categories') || '[]');
      console.log('Kategorien Lokal geladen');
      return set({ categories });
    }

    processData({ url: '/get-categories' }).then((response) => {
      const { error, data } = response;
      if (error) return false;

      if (data.code === 200) {
        // remove the second layer in the categories, as this is redudant
        categories = data.categories.map((item:any) => {
          const children = item.children.map((subitem:any) => {
            // if we cant find a children element then return the element itself
            if (subitem.children[0]) {
            // 14, 16, 17, 18, 19, 20, 22, 24 im FrontEnd Ausblenden
              if (subitem.children[0].id === 3) {
                let filterdSubitem = subitem.children[0].children.map((el:any) => {
                  if (el.id !== 14 && el.id !== 16 && el.id !== 17 && el.id !== 18 && el.id !== 19 && el.id !== 20 && el.id !== 22 && el.id !== 24) {
                    return el;
                  }
                  return undefined;
                });

                filterdSubitem = filterdSubitem.filter((e:any) => e !== undefined);
                const tempObj = {
                  ...subitem.children[0],
                  children: filterdSubitem
                };

                return tempObj;
              }
              return subitem.children[0];
            }
            return subitem;
          });

          return { ...item, children };
        });

        // conversion of elements to "Weitere Merkmale" (other features)
        // main elements: Branchen, Funktionen, Weitere Merkmale (grouped from Umsetzung ID 183 and Ertragsmodell ID 210)
        let tempArray = categories.filter((item:any) => item.id === 183);

        tempArray = tempArray.map((item:GenericObject) => {
          const { children } = item;
          const object210 = categories.find((subitem:any) => subitem.id === 210);

          // push Ertragsmodell to children of Weitere Merkmale
          children.push(object210);

          const tempObj = {
            id: 183,
            name: 'Weitere Merkmale',
            children
          };

          return tempObj;
        });

        // merge Branchen ID 1, Funktionen ID 121 and new array Weitere Merkmale
        categories = [...categories.filter((item) => item.id === 1 || item.id === 121 || item.id === 275), ...tempArray];
        localStorage.setItem('categories', JSON.stringify(categories));

        // update state
        return set({ categories });
      }

      useAppStore.setState({
        alert: {
          error: true, severity: 'error', text: 'Fehler beim Laden der Kategorien.'
        }
      });
      return false;
    });
  },

  prodGetSuggestionsAndOffers: () => {
    // we have to loop through solutions, combiProducts and projects and call the API for all offers and suggestions of the items
    const newOffers:Array<any> = [];
    const newSuggestions:Array<any> = [];

    useAppStore.setState({ showSkeleton: true });

    get().getAssociatedData(['get-offers', 'get-suggestions']);

    // no portfolio existing
    if (promises.length === 0) {
      set({ offers: newOffers, suggestions: newSuggestions });
      useAppStore.setState({ showSkeleton: false });
    }

    Promise.all(promises)
      .then((values) => {
        values.forEach((value) => {
          const { data } = value;
          const {
            item, code, offers, suggestions
          } = data;
          if (code === 200) {
            const array = offers || suggestions;

            array.forEach((element:GenericObject) => {
              // sanitize contact person
              const { company } = element;
              const [contactPerson] = company.contactPersons;
              // delete company.contactPersons;
              const newCompany = { ...company, contactPerson };
              const newElement = { ...element, company: newCompany };

              if (offers) newOffers.push({ ...newElement, item });
              if (suggestions) newSuggestions.push({ ...newElement, item });
            });
          }

          // set state
          const outgoing:any = newOffers.filter((offer: any) => offer.direction === 'outgoing');
          const incoming:any = newOffers.filter((offer: any) => offer.direction === 'incoming');
          set({
            suggestions: newSuggestions, offers: newOffers, offersIncoming: incoming, offerOutgoing: outgoing
          });
          useAppStore.setState({ showSkeleton: false });
        });
      })
      .catch((error) => {
        useAppStore.setState({
          showSkeleton: false,
          alert: {
            error: true, severity: 'error', text: `Fehler beim Abfragen der Vorschläge und Anfragen. ${error}`
          }
        });
        return false;
      });
  },

  prodGetOffers: () => {
    // we have to loop through solutions, combiProducts and projects and call the API for all offers and suggestions of the items
    const newOffers:Array<any> = [];

    useAppStore.setState({ showSkeleton: true });

    get().getAssociatedData(['get-offers']);

    // no portfolio existing
    if (promises.length === 0) {
      set({ offers: newOffers });
      useAppStore.setState({ showSkeleton: false });
    }

    Promise.all(promises)
      .then((values) => {
        values.forEach((value) => {
          const { data } = value;
          const { item, code, offers } = data;
          if (code === 200) {
            offers.forEach((element:GenericObject) => {
              // sanitize contact person
              const { company } = element;
              const [contactPerson] = company.contactPersons;
              // delete company.contactPersons;
              const newCompany = { ...company, contactPerson };
              const newElement = { ...element, company: newCompany };

              newOffers.push({ ...newElement, item });
            });
          }

          // set state
          set({ offers: newOffers });
          useAppStore.setState({ showSkeleton: false });
        });
      })
      .catch((error) => {
        useAppStore.setState({
          showSkeleton: false,
          alert: {
            error: true, severity: 'error', text: `Fehler beim Abfragen der Anfragen. ${error}`
          }
        });
        return false;
      });
  },

  prodGetBookmarks: () => {
    // we have to loop through solutions, combiProducts and projects and call the API for all offers and suggestions of the items
    const newBookmarks:Array<any> = [];

    useAppStore.setState({ showSkeleton: true });

    get().getAssociatedData(['get-bookmarks']);

    // no portfolio existing
    if (promises.length === 0) {
      set({ bookmarks: newBookmarks });
      useAppStore.setState({ showSkeleton: false });
    }

    Promise.all(promises)
      .then((values) => {
        values.forEach((value) => {
          const { data } = value;
          const { item, code, bookmarks } = data;
          if (code === 200) {
            bookmarks.forEach((element:GenericObject) => {
              // sanitize contact person
              const { company } = element;
              const [contactPerson] = company.contactPersons;
              // delete company.contactPersons;
              const newCompany = { ...company, contactPerson };
              const newElement = { ...element, company: newCompany };

              newBookmarks.push({ ...newElement, item });
            });
          }

          // set state
          set({ bookmarks: newBookmarks });
          useAppStore.setState({ showSkeleton: false });
        });
      })
      .catch((error) => {
        useAppStore.setState({
          showSkeleton: false,
          alert: {
            error: true, severity: 'error', text: `Fehler beim Abfragen der Merkliste. ${error}`
          }
        });
        return false;
      });
  },

  prodGetNetworks: () => {
    // we have to loop through solutions, combiProducts and projects and call the API for all offers and suggestions of the items
    const newNetworks:Array<any> = [];

    useAppStore.setState({ showSkeleton: true });

    get().getAssociatedData(['get-networks']);

    // no portfolio existing
    if (promises.length === 0) {
      set({ networks: newNetworks });
      useAppStore.setState({ showSkeleton: false });
    }

    Promise.all(promises)
      .then((values) => {
        values.forEach((value) => {
          const { data } = value;
          const { item, code, networks } = data;
          if (code === 200) {
            networks.forEach((element:GenericObject) => {
              // sanitize contact person
              const { company } = element;
              const [contactPerson] = company.contactPersons;
              // delete company.contactPersons;
              const newCompany = { ...company, contactPerson };
              const newElement = { ...element, company: newCompany };

              newNetworks.push({ ...newElement, item });
            });
          }

          // set state
          set({ networks: newNetworks });
          useAppStore.setState({ showSkeleton: false });
        });
      })
      .catch((error) => {
        useAppStore.setState({
          showSkeleton: false,
          alert: {
            error: true, severity: 'error', text: `Fehler beim Abfragen der Matches. ${error}`
          }
        });
        return false;
      });
  },

  prodGetBlacklists: () => {
    const newBlacklists:Array<any> = [];
    useAppStore.setState({ showSkeleton: true });

    processData({ url: '/private/get-blacklist' })
      .then((response) => {
        const { error, data } = response;
        if (error) {
          useAppStore.setState({ showSkeleton: false });
          return false;
        }

        data.blacklist.forEach((element:GenericObject) => {
          // sanitize referer = item, main = [root], contact person
          const { company } = element.main;
          const [contactPerson] = company.contactPersons;
          // delete company.contactPersons;
          const newCompany = { ...company, contactPerson };

          // const newElement = { ...element.main, company: newCompany };
          const newElement = {
            ...element.main, id: element.id, company: newCompany, item: element.referrer
          };

          newBlacklists.push({ ...newElement });
        });

        // set state
        set({ blacklists: newBlacklists });
        useAppStore.setState({ showSkeleton: false });

        return true;
      })
      .catch((error) => {
        useAppStore.setState({
          alert: { error: true, severity: 'error', text: `Fehler beim Laden der Ablage. ${error}` }
        });

        useAppStore.setState({ showSkeleton: false });
        return false;
      });
  },

  prodBookmarkBlacklisted: (id:number) => {
    const url = `/private/bookmark-blacklisted/${id}`;
    processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Merken aus der Ablage.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Merken aus der Ablage.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Erfolgreich der Merkliste hinzugefügt!' } });
        get().prodGetSuggestionsAndOffers();
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Merken aus der Ablage.' } });
        return false;
      });
  },

  prodLinkUpBlacklisted: (id: number, message: string) => {
    const form = new FormData();
    form.append('message', message);

    const url = `/private/link-up-blacklisted/${id}`;
    processData({ data: form, url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Vernetzen aus der Ablage' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Vernetzen aus der Ablage.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Eine Vernetzungsanfrage wurde gesendet.' } });
        get().prodGetSuggestionsAndOffers();
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Vernetzen aus der Ablage.' } });
        return false;
      });
  },

  prodPrivatSearch: (searchData:GenericObject) => {
    const newSearchResult:Array<any> = [];

    useAppStore.setState({ showSkeleton: true });

    const {
      searchTerm, solutionCategories, combiProductCategories, projectCategories, orderBy, order
    } = searchData;

    const formSearch = new FormData();
    formSearch.append('searchTerm', searchTerm);
    formSearch.append('solutionCategories', solutionCategories);
    formSearch.append('combiProductCategories', combiProductCategories);
    formSearch.append('projectCategories', projectCategories);
    formSearch.append('orderBy', orderBy);
    formSearch.append('order', order);

    processData({ data: formSearch, url: 'private/search' })
      .then((response) => {
        const { error, data } = response;
        if (error) {
          useAppStore.setState({ showSkeleton: false });
          return false;
        }

        data.results.forEach((element:GenericObject) => {
          // sanitize contact person
          const { company } = element;
          const [contactPerson] = company.contactPersons;
          // delete company.contactPersons;
          const newCompany = { ...company, contactPerson };
          const newElement = { ...element, newCompany };

          newSearchResult.push({ ...newElement });
        });

        // set state
        set({ searchResult: newSearchResult, searchParameter: searchData });
        useAppStore.setState({ showSkeleton: false });

        return true;
      })
      .catch((error) => {
        useAppStore.setState({
          alert: {
            error: true, severity: 'error', text: `Fehler beim Suchen. ${error}`
          }
        });

        useAppStore.setState({ showSkeleton: false });
        return false;
      });
  },

  prodSearch: (searchData:GenericObject) => {
    const newSearchResult:Array<any> = [];

    useAppStore.setState({ showSkeleton: true });

    const {
      searchTerm, solutionCategories, combiProductCategories, projectCategories, orderBy, order
    } = searchData;

    const formSearch = new FormData();
    formSearch.append('searchTerm', searchTerm);
    formSearch.append('solutionCategories', solutionCategories);
    formSearch.append('combiProductCategories', combiProductCategories);
    formSearch.append('projectCategories', projectCategories);
    formSearch.append('orderBy', orderBy);
    formSearch.append('order', order);

    processData({ data: formSearch, url: '/search' })
      .then((response) => {
        const { error, data } = response;
        if (error) {
          useAppStore.setState({ showSkeleton: false });
          return false;
        }

        data.results.forEach((element:GenericObject) => {
          // sanitize contact person
          const { company } = element;
          // const [contactPerson] = company.contactPersons;
          // delete company.contactPersons;
          // const newCompany = { ...company, contactPerson };
          const newElement = { ...element, company };

          newSearchResult.push({ ...newElement });
        });

        // set state
        set({ searchResult: newSearchResult, searchParameter: searchData });
        useAppStore.setState({ showSkeleton: false });

        return true;
      })
      .catch((error) => {
        useAppStore.setState({
          alert: {
            error: true, severity: 'error', text: `Fehler beim Suchen. ${error}`
          }
        });

        useAppStore.setState({ showSkeleton: false });
        return false;
      });
  },
  /**
 * Function to add a product.
 * @param dataItem - An object containing product data.
 * @returns A Promise that resolves with the response data if successful, or false if an error occurs.
 */
  prodAddProduct: (dataItem: GenericObject) => {
    const {
      type, name, summary, productURL, categories, networks, offerMessage
    } = dataItem;

    const form = new FormData();
    form.append('name', name);
    form.append('summary', summary);
    form.append('url', productURL);
    form.append('categories', categories);
    form.append('networkIds', networks || '');
    form.append('offerMessage', offerMessage || '');

    const url = type === 'singleProduct' ? '/private/add-solution' : '/private/add-combiproduct';

    return processData({ data: form, url })
      .then((response:GenericObject) => {
        const { data } = response;
        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Speichern des neuen Produktes.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Speichern des neuen Produktes.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Neues Produkt wurde erfolgreich angelegt!' } });
        useUserStore.getState().userGetAccountData();
        return data;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Speichern des neuen Produktes.' } });
        return false;
      });
  },

  prodEditProduct: (dataItem: GenericObject) => {
    const {
      id, name, summary, categories, productURL
    } = dataItem;
    const form = new FormData();
    form.append('name', name);
    form.append('url', productURL);
    form.append('summary', summary);
    form.append('categories', categories);

    const url = `/private/edit-solution/${id}`;

    return processData({ data: form, url })
      .then((response:GenericObject) => {
        const { data } = response;
        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ändern des Produktes.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ändern des Produktes.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Produkt wurde erfolgreich geändert!' } });
        useUserStore.getState().userGetAccountData();
        return data;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Speichern des neuen Produktes.' } });
        return false;
      });
  },

  prodEditProductLogo: async (solutionid:string, imageData:string) => {
    const fdata = new FormData();
    fdata.append('file', imageData);
    fdata.append('filename', `${nanoid(20)}.jpg`);

    const { error, data } = await processData({ data: fdata, url: `/private/edit-solution-logo/${solutionid}` });
    if (error) return false;

    if (data.code !== 200) {
      useAppStore.setState({
        alert: {
          error: true, severity: 'error', text: 'Das Ändern des Produktlogos ist leider fehlgeschlagen.'
        }
      });
      return false;
    }

    const { code } = data;
    if (code === 200) {
      useAppStore.setState({ alert: { severity: 'success', text: 'Ihr Produktlogo wurde erfolgreich geändert!' } });
      useUserStore.getState().userGetAccountData();
      return true;
    }

    return false;
  },

  prodDeactivateProject: (id: number) => {
    const url = `/private/deactivate-project/${id}`;
    return processData({ url })

      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Deaktivieren des Gesuchs.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Deaktivieren des Gesuchs.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Das Gesuch wurde erfolgreich deaktiviert!' } });
        useUserStore.getState().userGetAccountData();
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Deaktivieren des Gesuchs.' } });
        return false;
      });
  },

  prodActivateProject: (id: number) => {
    const url = `/private/activate-project/${id}`;
    return processData({ url })

      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Aktivieren des Gesuchs.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Aktivieren des Gesuchs.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Das Gesuch wurde erfolgreich aktiviert!' } });
        useUserStore.getState().userGetAccountData();
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Aktivieren des Gesuchs.' } });
        return false;
      });
  },

  prodRemoveProduct: (id: number, type = 'solution') => {
    const url = type === 'solution' ? `/private/remove-solution/${id}` : `/private/remove-combiproduct/${id}`;
    return processData({ url })

      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Löschen des Produktes.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Löschen des Produktes.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Das Produkt wurde erfolgreich gelöscht!' } });
        useUserStore.getState().userGetAccountData();
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Löschen des Produktes.' } });
        return false;
      });
  },

  prodSearchAddLinkupBookmark: (arrayDataItems: Array<any>, mode:string) => {
    const formDataItems = new FormData();

    let errorCounter = 0;

    // empty promises array
    promises.splice(0);

    const url = mode === 'bookmark' ? '/private/bookmark-search-item' : '/private/link-up-search-item';

    // loop data array to generate promises
    arrayDataItems.forEach((dataItem, index) => {
      const {
        searchItemType, searchItemId, referrerType, referrerId, message
      } = dataItem;

      if (index === 0) {
        formDataItems.append('searchItemType', searchItemType);
        formDataItems.append('searchItemId', searchItemId);
        formDataItems.append('referrerType', referrerType);
        formDataItems.append('referrerId', referrerId);
        formDataItems.append('message', message);
      } else {
        formDataItems.set('searchItemType', searchItemType);
        formDataItems.set('searchItemId', searchItemId);
        formDataItems.set('referrerType', referrerType);
        formDataItems.set('referrerId', referrerId);
        formDataItems.set('message', message);
      }

      return promises.push(processData({ data: formDataItems, url }));
    });

    Promise.all(promises)
      .then((values) => {
        values.forEach((value) => {
          const { data } = value;
          if (data.code !== 200) errorCounter = +1;
        });
      })
      .catch(() => {
        errorCounter = 1;
      });

    if (errorCounter > 0) {
      useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Bookmarken / Vernetzen' } });
      return false;
    }

    useAppStore.setState({ alert: { severity: 'success', text: 'Einträge wurden erfolgreich angelegt' } });
    return true;
  },

  prodAddProject: (dataItem: GenericObject) => {
    const { name, summary, categories } = dataItem;

    const form = new FormData();
    form.append('name', name);
    form.append('summary', summary);
    form.append('categories', categories);

    return processData({ data: form, url: '/private/add-project' })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Speichern des neuen Gesuches.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Speichern des neuen Gesuches.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Neues Gesuch wurde erfolgreich angelegt!' } });
        useUserStore.getState().userGetAccountData();
        return data;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Speichern des neuen Gesuches.' } });
        return false;
      });
  },

  prodEditProject: (dataItem: GenericObject) => {
    const {
      id, name, summary, categories
    } = dataItem;

    const form = new FormData();
    form.append('name', name);
    form.append('summary', summary);
    form.append('categories', categories);

    const url = `/private/edit-project/${id}`;

    return processData({ data: form, url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ändern des Gesuches.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ändern des Gesuches.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Gesuch wurde erfolgreich geändert!' } });
        useUserStore.getState().userGetAccountData();
        return data;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Speichern des neuen Produktes.' } });
        return false;
      });
  },

  prodEditProjectLogo: async (projectid:string, imageData:string) => {
    const fdata = new FormData();
    fdata.append('file', imageData);
    fdata.append('filename', `${nanoid(20)}.jpg`);

    const { error, data } = await processData({ data: fdata, url: `/private/edit-project-logo/${projectid}` });
    if (error) return false;

    if (data.code !== 200) {
      useAppStore.setState({
        alert: {
          error: true, severity: 'error', text: 'Das Ändern des Logo ist leider fehlgeschlagen.'
        }
      });
      return false;
    }

    const { code } = data;
    if (code === 200) {
      useAppStore.setState({ alert: { severity: 'success', text: 'Ihr Logo wurde erfolgreich geändert!' } });
      useUserStore.getState().userGetAccountData();
      return true;
    }

    return false;
  },

  prodRemoveProject: (id: number) => {
    const url = `/private/remove-project/${id}`;
    return processData({ url })

      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Löschen des Gesuches.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Löschen des Gesuches.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Das Gesuch wurde erfolgreich gelöscht!' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Löschen des Gesuches.' } });
        return false;
      });
  },

  prodBookmarkSuggestion: (id: number) => {
    const url = `/private/bookmark-suggestion/${id}`;
    return processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Merken des Vorschlages.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Merken des Vorschlages.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Der Vorschlag wurde erfolgreich der Merkliste hinzugefügt!' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Merken des Vorschlages.' } });
        return false;
      });
  },

  prodBlacklistSuggestion: (id: number) => {
    const url = `/private/blacklist-suggestion/${id}`;
    return processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen des Vorschlages.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen des Vorschlages.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Der Vorschlag wurde abgelehnt!' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen des Vorschlages.' } });
        return false;
      });
  },

  prodLinkupSuggestion: (id: number, message:string) => {
    const form = new FormData();
    form.append('message', message);

    const url = `/private/link-up-suggestion/${id}`;
    return processData({ data: form, url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Vernetzen des Vorschlages.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Vernetzen des Vorschlages.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Eine Vernetzungsanfrage für diesen Vorschlag wurde gesendet.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Vernetzen des Vorschlages.' } });
        return false;
      });
  },

  prodConfirmOffer: (id: number) => {
    const url = `/private/confirm-offer/${id}`;
    return processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Annehmen der Anfrage.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Annehmen der Anfrage.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Die Anfrage wurde erfolgreich angenommen.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Annehmen der Anfrage.' } });
        return false;
      });
  },

  prodDeclineOffer: (id: number) => {
    const url = `/private/decline-offer/${id}`;
    return processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen der Anfrage.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen der Anfrage.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Die Anfrage wurde abgelehnt.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen der Anfrage.' } });
        return false;
      });
  },

  prodWithdrawnOffer: (id: number) => {
    const url = `/private/withdrawn-offer/${id}`;
    return processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Zurückziehen der Anfrage.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Zurückziehen der Anfrage.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Die Anfrage wurde zurückgezogen.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Zurückziehen der Anfrage.' } });
        return false;
      });
  },

  prodSetOfferStatus: (dataItem : GenericObject) => {
    const {
      id,
      status
    } = dataItem;
    const url = `/private/set-offer-status/${id}`;

    const fdata = new FormData();
    fdata.append('status', status);

    return processData({ data: fdata, url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ändern des Status.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ändern des Status.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Erfolgreiche Änderung des Status.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ändern des Status' } });
        return false;
      });
  },

  prodRemoveBookmark: (id: number) => {
    const url = `/private/remove-bookmark/${id}`;
    return processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Entfernen von der Merkliste.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Entfernen von der Merkliste.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Erfolgreiche Entfernung von der Merkliste.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Entfernen von der Merkliste.' } });
        return false;
      });
  },

  prodBlacklistBookmark: (id: number) => {
    const url = `/private/blacklist-bookmark/${id}`;
    return processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen dieses Merklisten-Elements.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen dieses Merklisten-Elements.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Erfolgreiche Ablage und Entfernung von der Merkliste.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen dieses Merklisten-Elements.' } });
        return false;
      });
  },

  prodLinkupBookmark: (id: number, message:string) => {
    const form = new FormData();
    form.append('message', message);

    const url = `/private/link-up-bookmark/${id}`;
    return processData({ data: form, url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Vernetzen aus der Merkliste.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Vernetzen aus der Merkliste.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Eine Vernetzungsanfrage für diesen Merklisteneintrag wurde gesendet.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Vernetzen aus der Merkliste.' } });
        return false;
      });
  },

  prodDeclineCombiProduct: (id: number) => {
    const url = `/private/decline-combiproduct-request/${id}`;
    return processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen der Anfrage.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen der Anfrage.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Die Anfrage wurde abgelehnt.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Ablehnen der Anfrage.' } });
        return false;
      });
  },

  prodConfirmCombiProduct: (id: number) => {
    const url = `/private/confirm-combiproduct-request/${id}`;
    return processData({ url })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Annehmen der Anfrage.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Annehmen der Anfrage.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Die Anfrage wurde erfolgreich angenommen.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Annehmen der Anfrage.' } });
        return false;
      });
  },

  prodActivateCombiProduct: (id: number) => {
    const url = `/private/activate-combiproduct/${id}`;
    return processData({ url, data: null })
      .then((response:GenericObject) => {
        const { data } = response;

        if (!data) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Aktivieren des Kombiprodukts.' } });
          return false;
        }

        if (data.code !== 200) {
          useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Ein abgelehntes Kombiprodukt kann nicht aktiviert werden.' } });
          return false;
        }

        useAppStore.setState({ alert: { severity: 'success', text: 'Das Kombiprodukt wurde erfolgreich aktiviert.' } });
        return true;
      })
      .catch(() => {
        useAppStore.setState({ alert: { error: true, severity: 'error', text: 'Fehler beim Aktivieren des Kombiprodukts.' } });
        return false;
      });
  },

});

const useProductStore = create(logger(store));
export default useProductStore;
