import { FC, ReactElement, useEffect } from 'react';

import { useMatomo } from '@datapunt/matomo-tracker-react';
import StartScreenMain from '../components/startscreen/StartScreenMain';
import { useAppStore, useProductStore, useUserStore } from '../state';

const StartScreen: FC = (): ReactElement => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({
      documentTitle: 'StartScreen',
      href: `${process.env.REACT_APP_MATOMO_TARGE_BASE_URL}app/startscreen`,
    });
    useAppStore.getState().appGetEventNotification();
    useAppStore.getState().appGetPosts();
    useProductStore.getState().prodGetCategories();
    useUserStore.getState().userGetAccountData()
      .then(() => useProductStore.getState().prodGetSuggestionsAndOffers());
  }, []);

  return (
    <StartScreenMain />
  );
};

export default StartScreen;
