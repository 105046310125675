/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  TextField,
  IconButton,
  InputProps,
  InputAdornment,
  Box,
  Button
} from '@mui/material';

import { Search } from '@mui/icons-material';

import Chips from './Chips';
import Checkboxes from './Checkboxes';

const CategoryBlock = (props:GenericObject):ReactElement => {
  const { t } = useTranslation();
  const [isSelect, SetIsSelect] = useState<boolean>(false);

  const {
    treeElement,
    type,
    filterTree,
    countCategories,
    toggleFilter,
    toggleCheckbox,
    processSearchTerm,
    isCrossIndustries,
    toggleAllVisibleCategories,
  } = props;
  // define the search-icon at the beginning of the input field
  const startAdornment = (
    <InputAdornment position="start">
      <IconButton
        aria-label="toggle password visibility"
      >
        <Search />
      </IconButton>
    </InputAdornment>
  );

  const inputProps: InputProps = { startAdornment };

  const onClick = (select: boolean) => {
    SetIsSelect(!isSelect);
    toggleAllVisibleCategories(type, select);
  };

  const isAnythingChecked = (items:GenericObject) => items?.some((item:GenericObject) => {
    if (item.children) {
      return isAnythingChecked(item.children); // Rekursiver Aufruf für Kinder
    }
    return item.checked === true;
  });

  useEffect(() => {
    if (treeElement) {
      SetIsSelect(isAnythingChecked(treeElement));
    }
  }, [treeElement]);

  let header = '';
  let placeholder = '';
  if (type === 'industries') {
    header = t('editProducts.input.industries');
    placeholder = 'Branche';
  }
  if (type === 'functionalities') {
    header = t('editProducts.input.functions');
    placeholder = 'Funktion';
  }
  if (type === 'characteristics') {
    header = t('editProducts.input.characteristics');
    placeholder = 'Funktionsmerkmale';
  }
  if (type === 'moreFeatures') {
    header = t('editProducts.input.moreFeatures');
    placeholder = 'Weitere Merkmale';
  }
  return (
    <>
      {(((type === 'industries' && !isCrossIndustries) || (type === 'functionalities' || type === 'characteristics' || type === 'moreFeatures')) && !filterTree)
      && <Typography variant="h6" marginTop={5} marginBottom={3} fontWeight="bold">{header}</Typography>}
      {((type === 'industries' && !isCrossIndustries) || type === 'functionalities' || type === 'characteristics' || type === 'moreFeatures') && (
        <>
          {treeElement.filter((element: GenericObject) => element.id !== 120)
            .map((item:GenericObject) => (
              <Chips
                key={item.id}
                type={type}
                item={item}
                count={countCategories[item.id]}
                handleToggleElement={toggleFilter}
              />
            ))}
          <br />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              onChange={processSearchTerm}
              name={type}
              type="text"
              size="medium"
              InputProps={inputProps}
              placeholder={placeholder}
              style={{ marginTop: '10px', marginBottom: '10px', maxWidth: '500px' }}
              sx={{
                bgcolor: 'secondary.light',
                color: 'secondary',
                '&::placeholder': {
                  color: 'secondary'
                }
              }}
            />
            <Box sx={{ marginLeft: 'auto' }}>
              { isSelect === true
            && (
            <Button
              variant="text"
              onClick={() => onClick(false)}
              color="primary"
              sx={{
                mr: 2, px: 3, py: 0.5, ml: 2,
              }}
            >
              Zurücksetzen
            </Button>
            )}
              <Button
                variant="contained"
                onClick={() => onClick(true)}
                color="secondary"
                sx={{
                  mr: 2, px: 3, py: 0.5, ml: 2
                }}
              >
                Alle auswählen
              </Button>
            </Box>

          </Box>

          <Box sx={{ py: 2 }}>
            <Checkboxes
              type={type}
              data={treeElement}
              handleCheckbox={toggleCheckbox}
              sharedSetIsSelected={(isSelected:boolean) => SetIsSelect(isSelected)}
            />
          </Box>
        </>
      )}

    </>

  );
};

export default CategoryBlock;
