/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import {
  FC, ReactElement, useEffect, useState
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import {
  Box, Typography, Button, Pagination, Grid, IconButton, ButtonGroup, Paper
} from '@mui/material';

import {
  InfoOutlined,
  Add as AddIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material';

import PortfoliolistCard from '../ui/Card';

const Overview:FC = ():ReactElement => {
  const { t } = useTranslation();
  const countZero = 0;
  const countSingle = 5;
  const countDouble = 17;
  const navigate = useNavigate();

  return (
    <Box>
      <div style={{
        display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
      }}
      >
        <Typography variant="h1" fontWeight="bold">Layout Overview</Typography>
      </div>
      <Typography variant="h6" marginBottom={{ xs: 2, lg: 3 }}>Buttons</Typography>

      <Box>
        <>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
              >
                {/* {t('common.button.addSingleProduct')} */}
                Primary Small Start Icon
              </Button>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                startIcon={<AddIcon />}
              >
                {/* {t('common.button.addCombiProduct')} */}
                Secondary Small Start Icon
              </Button>

            </Stack>
            <Stack direction="row" spacing={2}>
              <ButtonGroup variant="contained" color="accentTwo" size="small">
                <Button>
                  Group AccentOne Small
                </Button>
                <Button>
                  with two Buttons
                </Button>
              </ButtonGroup>
              <ButtonGroup variant="contained" size="small" color="accentOne">
                <Button
                  startIcon={<AddIcon />}
                >
                  {/* {t('common.button.addCombiProduct')} */}
                  Group AccentOne Small with Start / End Icon
                </Button>
                <Button>
                  <HelpOutlineIcon />
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        </>
      </Box>
      <Paper sx={{
        marginTop: '20px', display: 'inline-flex', flexWrap: 'wrap', justifyContent: 'start', py: 1, pl: 1, alignContent: 'center', flexDirection: 'row'
      }}
      >
        <Box>
          <Typography>Kategorie</Typography>
          <ButtonGroup>
            <Button
              sx={{
                mb: 1, px: 2, py: 0.7
              }}
              variant="contained"
              color="secondary"
              size="small"
            >
              {`${t('matching.filter.all')}`}
            </Button>
            <Button
              sx={{
                mb: 1, px: 2, py: 0.7
              }}
              variant="contained"
              color="secondary"
              size="small"
            >
              {`${t('matching.filter.singleProducts')} (${countSingle})`}
            </Button>
            <Button
              sx={{
                mb: 1, mr: 5, px: 2, py: 0.7
              }}
              variant="contained"
              color="secondary"
              size="small"
            >
              {`${t('matching.filter.combiProducts')} (${countDouble})`}
            </Button>
          </ButtonGroup>
        </Box>
      </Paper>
      <div>
        <Grid
          container
          spacing={{ xs: 3, sm: 4, md: 5 }}
          paddingTop={{ sm: 3, xs: 1 }}
        >
          <Grid item xs={12} lg={6} xl={4}>
            {/* <PortfoliolistCard
              key={`card_${data.type}_${data.id}`}
              company={company}
              {...data}
            /> */}
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
export default Overview;
