/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */
const showLog = false;

const logger = (config:any) => (set:any, get:any, api:any) => config((args:any) => {
  if (showLog && process.env.NODE_ENV !== 'production') console.log('%c applying  ', 'background: #4C2929; color: yellow', args);
  set(args);
  if (showLog && process.env.NODE_ENV !== 'production') console.log('%c new state ', 'background: #4C2929; color: lightgreen', get());
}, get, api);

export { logger };
