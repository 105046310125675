/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import {
  FC, ReactElement, useEffect, useState
} from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
// import { useTranslation } from 'react-i18next';

import {
  Box,
  Paper,
  Button,
  Typography,
  Pagination,
  Grid,
  IconButton,
  Avatar
} from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';
import PreviewCard from '../components/portfolio/PreviewCard';
import FormInputText from '../components/ui/FormInputText';

import { useCategories } from '../hooks';

import Categories from '../components/edit-product/Categories';
import Logos from '../components/edit-product/Logos';
import PlaceholderImageCompany from '../assets/images/placeholder_company.png';
import PlaceholderImagePerson from '../assets/images/placeholder_person.png';

import MatchesCard from '../components/matches/MatchesCard';

import {
  ruleProductName, ruleProductSummary, ruleOfferMessage
} from '../rules';

import {
  useProductStore, usePaginationStore, useAppStore, useUserStore
} from '../state';
import UploadAndDisplayImage from '../components/ui/UploadAndDisplayImage';

const EditCombiProduct:FC = ():ReactElement => {
  const { t } = useTranslation();

  // Hook for Pagination, start with init()
  const {
    init,
    jump,
    currentPage,
    maxPage,
    paginatedData,
  } = usePaginationStore((state:GenericObject) => state);

  // user-defined hook to manage the categories
  const {
    step,
    touched,
    name,
    summary,
    tree,
    countCategories,
    currentProductProject,
    countMainCategories,
    toggleIndustry,
    toggleFilter,
    toggleCheckbox,
    processSearchTerm,
    getListCategories,
    validateCheckedIndustriesFunctionalities,
    setCombiProductAsCurrentProduct,
    setStep,
    setTouched,
    setName,
    setSummary,
    toggleAllVisibleCategories
  } = useCategories();

  const initArrays:any = [];

  const [matches, setMatches] = useState(initArrays);
  const [combiProducts, setCombiProducts] = useState(initArrays);

  const {
    prodAddProduct, newCombiProduct, networks, portfolio, prodEditProductLogo
  } = useProductStore.getState();

  const { company } = useUserStore.getState();

  const [previewItem, setPreviewItem] = useState({
    id: -1,
    type: 'combiProduct',
    company,
    logo: '',
    name,
    summary,
    tree
  });

  const navigate = useNavigate();

  const logoCompany = company.logo;

  const [logoTouched, setLogoTouched] = useState(false);
  const [logoProductString, setLogoProductString] = useState('');
  // eslint-disable-next-line no-nested-ternary
  const [currentLogo, setCurrentLogo] = useState(currentProductProject ? (currentProductProject.logo !== null ? currentProductProject.logo : logoCompany || PlaceholderImageCompany) : logoCompany || PlaceholderImageCompany);

  useEffect(() => {
    // make a copy of networks do not pollute the origin
    let copyNetworks = [...networks.filter((net: GenericObject) => net.type !== 'project')];

    // do we have a prepard request from matches page?
    if (newCombiProduct.id) {
      const tempArray:any = [newCombiProduct];
      // search for a corresponding solution and assign it as well.
      const product = portfolio.find((prod:GenericObject) => newCombiProduct.item.id === prod.id);
      if (product) tempArray.push({ ...product, company });

      setCombiProducts(tempArray);
      copyNetworks = copyNetworks.filter((copy:GenericObject) => copy.id !== newCombiProduct.id);

      // remove prepared combi product
      useProductStore.setState({ newCombiProduct: {} });
    } else if (currentProductProject) {
      console.log(currentProductProject);
    }

    // init pagination / set locale working copy
    init(copyNetworks, 6, 1);
    setMatches(copyNetworks);
  }, [networks.length]);

  const {
    handleSubmit, control, formState, register
  } = useForm();
  const { dirtyFields } = formState;

  const onSubmitNameSummary = (data: GenericObject) => {
    setTouched(true);

    setStep((currentStep) => currentStep + 1);
    setName(data.name);
    setSummary(data.summary);

    return true;
  };

  const onSaveProductLogo = (imageData:string) => {
    setLogoTouched(true);
    setLogoProductString(imageData);
  };
  const onDeleteImage = () => {
    const dialogData = {
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.confirmDeleteImage'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.cancel'),
          color: 'secondary',
        },
        {
          text: t('common.button.delete'),
          color: 'error',
          onClick: () => {
            setCurrentLogo(PlaceholderImageCompany);
            setLogoTouched(true);
            setLogoProductString('');
          }
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  // Saving the new combi product
  const onSubmitOfferMessage = () => {
    const allCategories = getListCategories();
    if (allCategories.length === 0) return false;

    // Genrate a list of all network ids
    const allNetworks:any = [];
    combiProducts
      .forEach((element:GenericObject) => {
        if (element.itemId) {
          allNetworks.push(element.id);
        }
      });

    const dataItem = {
      id: currentProductProject?.id,
      type: 'combiProduct',
      name,
      summary,
      categories: allCategories,
      networks: allNetworks.join(),
    };

    // call method in store to add new combi product
    prodAddProduct(dataItem)
      .then((result: GenericObject) => {
        const {
          success, id
        } = result;
        if (success) {
          if (logoTouched) {
            const res = prodEditProductLogo(id === undefined ? currentProductProject.id : id, logoProductString);
            res
            // eslint-disable-next-line @typescript-eslint/no-shadow
              .then((success: boolean) => {
                if (success) {
                  useProductStore.getState().prodResetAll();
                  return navigate('/app/portfolio');
                }
                useProductStore.getState().prodResetAll();
                return navigate('/app/portfolio');
              });
            useProductStore.getState().prodResetAll();
            return navigate('/app/portfolio');
          }
          return navigate('/app/portfolio');
        }
        return false;
      });

    return true;
  };

  const handleGoToList = () => navigate('/app/portfolio');
  const handleGotoToFirstPage = () => setStep(1);
  const handleBack = () => setStep((currentStep) => currentStep - 1);

  const handleCancel = () => {
    // if some fields are touched so ask for quitting
    if (Object.keys(dirtyFields).length === 0 && !touched) return handleGoToList();

    setTouched(true);

    const dialogData = {
      title: { text: t('dialog.pleaseConfirm') },
      contentItems: [
        {
          text: t('dialog.confirmCancelEditProduct'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.noGoOn'),
          color: 'secondary',
        },
        {
          text: t('common.button.yesLeave'),
          color: 'error',
          onClick: handleGoToList
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleGoToLastPage = () => {
    // validation of selected categories
    if (!validateCheckedIndustriesFunctionalities()) {
      const dialogData = {
        title: { text: t('common.error.problem') },
        contentItems: [{ text: t('common.error.selectIndustriesFunctions') }],
        actionItems: [{ text: t('common.button.okUnderstood'), color: 'secondary' }],
      };
      useAppStore.setState({ dialogData });

      return false;
    }

    setPreviewItem({
      id: -1,
      type: 'combiProduct',
      company,
      logo: currentLogo,
      name,
      summary,
      tree
    });

    return setStep(4);
  };

  const handleNextToStep2 = () => {
    // validation of combiproducts
    if (combiProducts.length === 0) {
      const dialogData = {
        title: { text: t('common.error.problem') },
        contentItems: [{ text: t('common.error.selectMatch') }],
        actionItems: [{ text: t('common.button.okUnderstood'), color: 'secondary' }],
      };
      useAppStore.setState({ dialogData });

      return false;
    }

    setCombiProductAsCurrentProduct(combiProducts);

    return setStep(2);
  };

  const handleAdd = (item:GenericObject) => {
    setTouched(true);
    const newCombiProducts = [...combiProducts];
    // add item to combiProducts
    const match: any = [...matches].find(
      (elem: GenericObject) => elem.id === item.id,
    );
    if (match) {
      newCombiProducts.push(match);

      // search for a corresponding solution and assign it as well.
      const product = company.solutions.find(
        (prod: GenericObject) => match.item.id === prod.id,
      );
      if (product) {
        if (
          !newCombiProducts.find(
            (prod: GenericObject) => product.id === prod.id,
          )
        ) {
          newCombiProducts.unshift({ ...product, company });
        }
      }
    }
    useProductStore.setState({ currentProductProject: newCombiProducts });

    setCombiProducts(newCombiProducts);

    const filterMatches = [...matches].filter((copy:GenericObject) => copy.id !== item.id);
    init(filterMatches, 6, 1);
    setMatches(filterMatches);
  };

  const handleReset = () => {
    init([...networks.filter((net: GenericObject) => net.type !== 'project')], 6, 1);
    setTouched(true);
    setMatches([...networks.filter((net: GenericObject) => net.type !== 'project')]);
    setCombiProducts([]);
  };

  const customStep : GenericObject = {
    backgroundColor: '#B50900',
    height: '5px',
    flexGrow: '1',
    opacity: '0.3',
    '&[data-filled=true]': {
      opacity: '1',
    }
  };

  const handleInfo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('editProducts.steps.combiProduct.step1.header') },
      contentItems: [
        {
          text: t('editProducts.steps.combiProduct.step1.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleInfoStep3 = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('editProducts.steps.singleProduct.step2.info.header') },
      contentItems: [
        {
          text: t('editProducts.steps.singleProduct.step2.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleInfoLogo = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: 'Produktlogo' },
      contentItems: [
        {
          text: 'Laden Sie Ihr Produktlogo hoch. Sollten Sie kein Produktlogo haben wird Ihr Unternehmenslogo verwendet.',
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  const handleInfoStep4 = () => {
    const dialogData = {
      sx: { p: 10 },
      title: { text: t('editProducts.whichIndustries.info.header') },
      contentItems: [
        {
          text: t('editProducts.whichIndustries.info.text.paragraph1'),
          variant: 'h6'
        },
      ],
      actionItems: [
        {
          text: t('common.button.ok'),
          color: 'primary',
        },
      ],
    };
    return useAppStore.setState({ dialogData });
  };

  return (
    <Box width="100%">
      <Box sx={{ width: '100%' }}>
        <Typography variant="h1" fontWeight="bold" marginBottom={3}>
          {`${t('editProducts.combiProduct')} ${currentProductProject ? t('editProducts.edit') : t('editProducts.add')}`}
        </Typography>
        <Box
          sx={{
            display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gridColumnGap: '15px', width: '60%'
          }}
          marginBottom={3}
        >
          <Box sx={customStep} data-filled={step === 1 || step > 1} />
          <Box sx={customStep} data-filled={step === 2 || step > 2} />
          <Box sx={customStep} data-filled={step === 3 || step > 3} />
          <Box sx={customStep} data-filled={step === 4} />
        </Box>
        {step === 1 && (
          <>
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 12
            }}
            >
              <Typography variant="h5" fontWeight="bold">{`${t('editProducts.step')} 1 ${t('editProducts.of')} 4: ${t('editProducts.steps.combiProduct.step1.header')}`}</Typography>
              <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfo}><InfoOutlined /></IconButton>
            </div>
            <Box sx={{ mt: 3 }}>
              <Typography width="60%" gutterBottom variant="h6" fontSize="16px" marginTop={2} marginBottom={2} whiteSpace="pre-line">
                {t('editProducts.steps.combiProduct.step1.paragraph1')}
              </Typography>
            </Box>
          </>
        )}
        {step === 2 && (
          <Typography gutterBottom variant="h5" fontWeight="bold" marginBottom={4}>{`${t('editProducts.step')} 2 ${t('editProducts.of')} 4: ${t('editProducts.steps.combiProduct.step2.header')}`}</Typography>
        )}
        {step === 3 && (
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
          }}
          >
            <Typography variant="h5" fontWeight="bold">{`${t('editProducts.step')} 3 ${t('editProducts.of')} 4: ${t('editProducts.steps.combiProduct.step3.header')}`}</Typography>
            <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfoStep3}><InfoOutlined /></IconButton>
          </div>
        )}
        {step === 4 && (
        <>
          <Typography gutterBottom variant="h5" fontWeight="bold">{`${t('editProducts.step')} 4 ${t('editProducts.of')} 4: ${t('editProducts.steps.combiProduct.step4.header')}`}</Typography>
          <Box sx={{ mt: 3 }}>
            <Typography width="60%" variant="body1" marginBottom={3}>
              {t('editProducts.steps.combiProduct.step4.paragraph1')}
            </Typography>
          </Box>
        </>
        )}
      </Box>
      {step === 1 && (
        <Paper sx={{
          p: 2, mb: 2, maxHeight: '70vh', overflow: 'auto', width: '100%'
        }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Box sx={{ py: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                <Typography variant="h6" fontWeight="bold">{t('editProducts.combiProduct')}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{
                    ml: 3, px: 2.5, py: 0.5
                  }}
                  onClick={() => handleReset()}
                >
                  {t('common.button.reset')}
                </Button>
              </div>
              <Box
                sx={{
                  width: {
                    md: '100%',
                    lg: 400
                  },
                  minHeight: '30%',
                  flexShrink: '0',
                  px: 2,
                  py: 3,
                  bgcolor: 'secondary.light',
                  border: 2,
                  borderColor: 'primary.main',
                  borderRadius: 1,
                }}
              >
                {combiProducts.map((elem:GenericObject) => (
                  <Paper
                    sx={{
                      boxShadow: 2, borderRadius: 1, width: '100%', maxWidth: '500px', mb: 2
                    }}
                    key={elem.id}
                    variant="outlined"
                  >
                    <Box
                      padding={{ sm: 2, lg: 2.5 }}
                      marginY={{ sm: 1 }}
                      sx={{
                        display: 'flex', alignItems: 'center', flexGrow: '1'
                      }}
                    >
                      <Box component="img" src={elem.logo ?? elem.company?.logo ?? PlaceholderImageCompany} alt="logo" borderRadius="100px" sx={{ width: 70, height: 70, objectFit: 'cover' }} />
                      <div style={{ flexGrow: 1, marginLeft: 20 }}>
                        <Typography fontSize={8} color="black" fontWeight="600" letterSpacing="1px" sx={{ textTransform: 'uppercase' }}>{elem.type === 'solution' ? t('editProducts.singleProduct') : t('editProducts.combiProduct')}</Typography>
                        <Typography variant="h5" color="black" fontWeight="bold" lineHeight={1} marginTop={1.5} marginBottom={1.5}>{elem.name}</Typography>
                        <Typography fontSize={10} color="black" fontWeight="600" letterSpacing="0.5px">{elem?.company?.name}</Typography>
                      </div>
                    </Box>
                    {/* <Typography variant="h6">{`${elem.name} (${elem.type})`}</Typography> */}
                  </Paper>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  md: '40%',
                  lg: '40%'
                },
                flexGrow: '1',
                pr: {
                  lg: 5
                },
                py: 1,
                ml: 6,
              }}
            >
              <Typography gutterBottom variant="h6" fontWeight="bold" marginBottom={2}>{t('editProducts.matches')}</Typography>
              <Grid container spacing={{ xs: 3, sm: 4, md: 5 }}>
                {paginatedData && paginatedData.map((data: any) => (
                  <MatchesCard
                    key={`card_${data.type}_${data.id}`}
                    {...data}
                    screenType="addCombiProduct"
                    onAdd={handleAdd}
                  />
                ))}
              </Grid>
              <Box sx={{ clear: 'both', mt: 2 }}>
                <Pagination count={maxPage} page={currentPage} onChange={(event, value) => jump(value)} />
              </Box>
            </Box>
          </Box>
        </Paper>
      )}
      {step === 2 && (
        <Paper sx={{
          p: 2, mb: 2, maxHeight: '70vh', overflow: 'auto', width: '100%'
        }}
        >
          <form onSubmit={handleSubmit(onSubmitNameSummary)} style={{ marginTop: '20px' }}>
            <FormInputText
              name="name"
              defaultValue={currentProductProject ? currentProductProject.name : ''}
              control={control}
              ref={register}
              label={t('editProducts.input.name')}
              rules={ruleProductName}
              sx={{ width: '50%', maxWidth: '700px', mb: 4 }}
            />
            <br />
            <FormInputText
              name="summary"
              defaultValue={currentProductProject ? currentProductProject.summary : ''}
              control={control}
              ref={register}
              label={t('editProducts.input.description')}
              multiline
              rows={5}
              maxRows={4}
              rules={ruleProductSummary}
              sx={{ width: '50%', maxWidth: '700px', mb: 6 }}
            />
            <Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight="bold">Produktlogo (optional)</Typography>
                <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfoLogo}><InfoOutlined /></IconButton>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
                <Box sx={{
                  pr: {
                    lg: 1
                  },
                  width: {
                    xs: '100%',
                    lg: '40%',
                    xl: '30%'
                  }
                }}
                >
                  <UploadAndDisplayImage
                    onSaveImage={onSaveProductLogo}
                    onDeleteImage={() => onDeleteImage()}
                    defaultImage={currentLogo}
                  />
                </Box>
              </Box>
            </Box>
            <br />
          </form>
        </Paper>
      )}
      {step === 3 && (
        <Paper sx={{
          p: 2, mb: 2, maxHeight: '70vh', overflow: 'auto', width: '100%'
        }}
        >
          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 2
          }}
          >
            <Typography variant="h6" fontWeight="bold">{t('editProducts.whichIndustries.header')}</Typography>
            <IconButton size="large" sx={{ color: 'text.primary' }} onClick={handleInfoStep4}><InfoOutlined /></IconButton>
          </div>

          {tree.industries.map((item:GenericObject) => (
            <Logos
              key={item.id}
              type="industries"
              item={item}
              handleToggleElement={toggleIndustry}
            />
          ))}
          <Categories
            tree={tree}
            countCategories={countCategories}
            countMainCategories={countMainCategories}
            toggleFilter={toggleFilter}
            toggleCheckbox={toggleCheckbox}
            processSearchTerm={processSearchTerm}
            toggleAllVisibleCategories={toggleAllVisibleCategories}
          />
        </Paper>
      )}
      {step === 4 && (
        <Box sx={{
          p: 2, mb: 2, minHeight: '70vh', maxHeight: '70vh', overflow: 'auto', width: '100%'

        }}
        >
          <PreviewCard
            key={previewItem.id}
              // eslint-disable-next-line react/jsx-props-no-spreading
            {...previewItem}
            screenType="previewProduct"
          />
          {/* <Typography gutterBottom variant="h5" fontWeight="bold" marginTop={2}>{t('editProducts.contacts')}</Typography>
          <Box
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill,minmax(360px, 1fr))'
            }}
          >
            {combiProducts.filter((elem:GenericObject) => elem.itemId).map((elem:GenericObject) => (
              <Box
                paddingY={{ sm: 2, lg: 3 }}
                marginBottom={{ sm: 2 }}
                sx={{

                  display: 'flex', alignItems: 'center', flexGrow: '1', color: 'black'
                }}
              >
                <Avatar src={elem?.company?.contactPerson?.contactPersonImage ?? PlaceholderImagePerson} sx={{ width: 70, height: 70, border: '1px solid #000' }} />
                <div
                  style={{
                    flexGrow: 1, marginLeft: 40, color: 'black', fontSize: '14px'
                  }}
                >
                  <Typography fontSize="14px" color="black" marginBottom="3px">{`${elem.company?.contactPerson?.firstname} ${elem.company?.contactPerson?.lastname}`}</Typography>
                  <Typography fontSize="14px" color="black" marginBottom="3px">{elem.company?.contactPerson?.jobTitle}</Typography>
                  <Typography fontSize="14px" color="black" marginBottom="3px">{elem.company?.contactPerson?.phoneNumber}</Typography>
                  <Typography fontSize="14px" color="black" marginBottom="3px">{elem.company?.contactPerson?.email}</Typography>
                </div>
              </Box>
            ))}
          </Box> */}
          {/* <form onSubmit={handleSubmit(onSubmitOfferMessage)} style={{ marginTop: '20px', maxWidth: '700px', fontSize: '14px' }}>
            <FormInputText
              name="offerMessage"
              defaultValue={t('editProducts.steps.combiProduct.step4.offerMessage', { combiproduct: name })}
              control={control}
              ref={register}
              label="Anfrage an Kontakte"
              multiline
              rows={4}
              maxRows={4}
              rules={ruleOfferMessage}
            />
          </form> */}
        </Box>
      )}
      {step === 1 && (
      <Box sx={{ position: 'sticky', bottom: 10 }}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          sx={{ mr: 2 }}
        >
          {t('common.button.cancel')}
        </Button>
        <Button type="button" variant="contained" color="primary" onClick={handleNextToStep2}>
          {t('common.button.next')}
        </Button>
      </Box>
      )}
      {step === 2 && (
        <Box sx={{ position: 'sticky', bottom: 10 }}>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            sx={{ mr: 2 }}
          >
            {t('common.button.cancel')}
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={handleBack}
            sx={{ mr: 2 }}
          >
            {t('common.button.back')}
          </Button>
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmitNameSummary)}>
            {t('common.button.next')}
          </Button>
        </Box>
      )}
      {step === 3 && (
      <Box sx={{ position: 'sticky', bottom: 10 }}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          sx={{ mr: 2 }}
        >
          {t('common.button.cancel')}
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleBack}
          sx={{ mr: 2 }}
        >
          {t('common.button.back')}
        </Button>
        <Button type="submit" variant="contained" color="primary" onClick={handleGoToLastPage}>
          {t('common.button.next')}
        </Button>
      </Box>
      )}
      {step === 4 && (
      <Box sx={{ position: 'sticky', bottom: 10 }}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          sx={{ mr: 2 }}
        >
          {t('common.button.cancel')}
        </Button>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={handleGotoToFirstPage}
          sx={{ mr: 2 }}
        >
          {t('common.button.edit')}
        </Button>
        <Button type="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmitOfferMessage)}>
          {t('common.button.save')}
        </Button>
      </Box>
      )}
    </Box>
  );
};

export default EditCombiProduct;
