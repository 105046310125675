import { FC, ReactElement } from 'react';
import { Typography, Box, Grid } from '@mui/material';

import i18n from '../utils/configI18n';
import { ReactComponent as eventsImage } from '../assets/icons/calendar-text-outline.svg';
import { ReactComponent as newsImage } from '../assets/icons/newspaper-variant-outline.svg';
import { ReactComponent as matchingImage } from '../assets/icons/puzzle-check-outline.svg';
import { ReactComponent as lawAndDataProtectionImage } from '../assets/icons/format-section.svg';
import { ReactComponent as interfacesImage } from '../assets/icons/connection.svg';
import { ReactComponent as itSecurityImage } from '../assets/icons/lock-outline.svg';
import CTATile from '../components/app/KIWCTATile';

// Load data from i18n file prepare for listing
const productRange:any = i18n.getDataByLanguage('de')?.translation.productRange;
const { product: e } = productRange;
const items = [
  {
    title: e.interfaces.header, subtitle: e.interfaces.subheader, src: e.interfaces.url, src2: e.interfaces.url, image: interfacesImage
  },
  {
    title: e.lawAndDataProtection.header, subtitle: e.lawAndDataProtection.subheader, src: e.lawAndDataProtection.url, src2: e.lawAndDataProtection.url2, image: lawAndDataProtectionImage
  },
  {
    title: e.itSecurity.header, subtitle: e.itSecurity.subheader, src: e.itSecurity.url, image: itSecurityImage
  },
  {
    title: e.matching.header, subtitle: e.matching.subheader, src: e.matching.url, image: matchingImage
  },
  {
    title: e.events.header, subtitle: e.events.subheader, src: e.events.url, image: eventsImage
  },
  {
    title: e.news.header, subtitle: e.news.subheader, src: e.news.url, image: newsImage
  },
];

const Offers: FC = (): ReactElement => (
  <Box>
    <Typography variant="h1" color="black" fontWeight="bold" marginBottom={{ xs: 2, lg: 3 }}>{productRange.header}</Typography>
    <Grid container spacing={{ xs: 2, md: 3, lg: 4 }} paddingTop={{ sm: 1 }}>
      {items.map((item) => (<CTATile key={item.title} item={item} />))}
    </Grid>
  </Box>
);
export default Offers;
