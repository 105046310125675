/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import {
  Box, Grid, Paper, Typography
} from '@mui/material';
import { useFieldArray, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInputText from '../ui/FormInputText';
import {
  rulePhoneNumber,
  ruleFirstname,
  ruleLastname,
  ruleEmailAddress
} from '../../rules';

import UploadAndDisplayImage from '../ui/UploadAndDisplayImage';
import PlaceholderLogoPerson from '../../assets/images/placeholder_person.png';
import { useUserStore } from '../../state';

type PersonForm = {
    data: ContactPerson[];
    register: UseFormRegister<any>;
    control: any;
}

const ContactPersonForm = ({
  data, control, register
}: PersonForm) => {
  const { t } = useTranslation();
  const user = useUserStore((state:GenericObject) => state);

  const onSavePersonLogo = (personId:string, imageData:string) => user.userEditPersonLogo(personId, imageData);
  const onDeletePersonLogo = (personId:string) => user.userEditPersonLogo(personId, '');

  const {
    fields
  } = useFieldArray<Company, 'contactPersons', 'contactPersonId'>(
    {
      control,
      name: 'contactPersons',
      keyName: 'contactPersonId'
    }
  );

  return (
    <>
      {/* <Button
        sx={{
          mb: 1, mr: 2, px: 2, py: 0.7
        }}
        variant="contained"
        color="secondary"
        size="small"
        startIcon={<AddIcon />}
        onClick={() => {
          append({ contactPersonImage: PlaceholderLogoPerson });
        }}
      >
        Kontakt hinzufügen
      </Button> */}
      {fields.map((item, index) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 6 }}>
          <Box
            sx={{
              pl: {
                lg: 2
              },
              width: {
                xs: '100%',
                lg: '60%',
                xl: '70%'
              }
            }}
          >
            <Typography variant="h5" fontWeight="bold" marginBottom={3} gutterBottom>
              { t('company.subheader2') }
            </Typography>
            <Grid
              container
              sx={{
                display: 'grid',
                columnGap: 3,
                rowGap: 1,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)'
                },
              }}
            >

              <FormInputText
                label={t('company.input.title')}
                name={`contactPersons.${index}.title`}
                control={control}
                ref={register(`contactPersons.${index}.title`)}
                defaultValue={item.title}
              />

              <FormInputText
                label="Position"
                name={`contactPersons.${index}.jobTitle`}
                control={control}
                ref={register(`contactPersons.${index}.jobTitle`)}
                defaultValue={item.jobTitle}
              />

              <FormInputText
                label={t('common.input.firstName')}
                name={`contactPersons.${index}.firstname`}
                control={control}
                ref={register(`contactPersons.${index}.firstname`)}
                defaultValue={item.firstname}
                rules={ruleFirstname}
                required
              />

              <FormInputText
                label={t('common.input.lastName')}
                name={`contactPersons.${index}.lastname`}
                control={control}
                ref={register(`contactPersons.${index}.lastname`)}
                defaultValue={item.lastname}
                rules={ruleLastname}
                required
              />

              <FormInputText
                label={t('common.input.phoneNumber')}
                name={`contactPersons.${index}.phoneNumber`}
                control={control}
                ref={register(`contactPersons.${index}.phoneNumber`)}
                defaultValue={item.phoneNumber}
                rules={rulePhoneNumber}
                required
              />

              <FormInputText
                label={t('common.input.email')}
                name={`contactPersons.${index}.email`}
                control={control}
                ref={register(`contactPersons.${index}.email`)}
                defaultValue={item.email}
                rules={ruleEmailAddress}
                required
              />
              <Box />
            </Grid>
            {/* { index >= 1 && (
            <Button
              sx={{
                mb: 1, mr: 2, px: 2, py: 0.7
              }}
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<DeleteIcon />}
              onClick={() => {
                remove(index);
              }}
            >
              Kontakt Entfernen
            </Button>
            )} */}
          </Box>
          <Box sx={{
            pr: {
              lg: 1
            },
            pt: {
              lg: 7
            },
            width: {
              xs: '100%',
              lg: '40%',
              xl: '30%'
            },
            mt: 'auto'
          }}
          >
            <UploadAndDisplayImage
              onSaveImage={(imgData: string) => onSavePersonLogo(data[index].id, imgData)}
              onDeleteImage={() => onDeletePersonLogo(data[index].id)}
              currentImage={data[index].contactPersonImage}
              defaultImage={PlaceholderLogoPerson}
            />
          </Box>
        </Box>
      ))}
    </>
  );
};

export default ContactPersonForm;
